import { ReactComponent as Icon } from 'images/calendar-icon-2.svg';

import styled from 'styled-components';

export const CalendarIcon = styled(Icon)`
  width: ${({ size }) => size ?? '16px'};
  height: ${({ size }) => size ?? '16px'};

  path {
    fill: ${({ fill }) => fill};
  }
`;
