import { useState, useEffect, useCallback, useRef } from 'react';
import { debounce } from 'lodash';
import { FormikCustomSelector } from 'components/Controls';
import { searchCustomersWithExternal } from 'shared/AsyncSearchUtils/searchCustomersWithExternal';
import { NAME_MATCHING_SOURCE } from '../consts';

export const CustomerSelectorCell = ({
  cell,
  row,
  fuzzyErrors,
  organization,
  customerIdSourceAndType,
  formRef,
  CustomSingleOption,
}) => {
  const [selectOptions, setSelectOptions] = useState([]);
  const [isFocused, setIsFocused] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const isMountedRef = useRef(true);

  const value = cell?.value;
  const fuzzyError = fuzzyErrors?.[row.original.rowIndex]?.customer_id;

  const isNameMatching = customerIdSourceAndType?.source === NAME_MATCHING_SOURCE;

  // eslint-disable-next-line
  const debouncedSearch = useCallback(
    debounce(async ({ searchQuery }) => {
      try {
        setIsLoading(true);
        const response = await searchCustomersWithExternal({
          customerIdSourceAndType,
          searchQuery,
          orgId: organization?.id,
          paginated: false,
        });
        if (isMountedRef.current) {
          setSelectOptions(response ?? []);
        }
      } finally {
        if (isMountedRef.current) {
          setIsLoading(false);
        }
      }
    }, 500),
    [organization?.id, customerIdSourceAndType],
  );

  useEffect(() => {
    if (isFocused && value) {
      debouncedSearch({ searchQuery: value });
    } else {
      setSelectOptions([]);
    }

    // Cancel any ongoing debounced calls when component unmounts or dependencies change
    return () => debouncedSearch.cancel();
  }, [value, organization, debouncedSearch, isFocused]);

  useEffect(() => {
    return () => {
      isMountedRef.current = false;
    };
  }, []);

  return (
    <>
      <FormikCustomSelector
        height={32}
        isLoading={isLoading}
        useDefaultSelect={true}
        components={{ Option: CustomSingleOption }}
        placeholder="Select id..."
        minWidth={180}
        menuWidth={220}
        errorWithoutTooltip
        value={value ? { value: value, label: value } : null}
        isClearable={true}
        options={selectOptions}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
        name={`[${row.original.rowIndex}].customer_id`}
        hasWarning={!!fuzzyError}
        tooltipInputDisplay={fuzzyError ? `Search for ${fuzzyError?.searchedName}` : null}
        handleChange={(option) => {
          formRef?.current?.setFieldValue(
            `[${row.original.rowIndex}].customer_id`,
            isNameMatching ? option?.name ?? option?.value : option?.value ?? null,
          );
        }}
      />
    </>
  );
};
