import * as Yup from 'yup';

export const SUBSCRIPTION_TABLE_COLUMN_TITLES_BY_ID = {
  customer: 'Customer',
  pricing_plan: 'Pricing Plan',
  start_date: 'Start Date',
  end_date: 'End Date',
  trial_units: 'Trial Units',
  invoice: 'Invoice',
  transaction: 'Transaction',
};

export const subscriptionValidationSchema = Yup.object({
  start_date: Yup.date().typeError('Please, enter a start date').required('Please, enter a start date'),
  customer_id: Yup.number().typeError('Please, select a customer').required('Please, select a customer'),
  pricing_plan_id: Yup.number().typeError('Please, select a pricing plan').required('Please, select a pricing plan'),
  previous_subscription_id: Yup.number().nullable().typeError('Please, select a previous subscription'),

  end_date: Yup.date().nullable().typeError('Please, enter an end date'),
  trial_units: Yup.number().nullable().typeError('Please, enter a number'),

  has_discount: Yup.boolean().nullable(),
  discount_code: Yup.string().nullable(),
  discount_type: Yup.string().when(['has_discount'], {
    is: (hasDiscount) => !!hasDiscount,
    then: Yup.string().required('Please, select a discount type'),
    otherwise: Yup.string().nullable(),
  }),
  discount_value: Yup.number().when(['has_discount'], {
    is: (hasDiscount) => !!hasDiscount,
    then: Yup.number().typeError('Please, enter the discount value').required('Please, enter the discount value'),
    otherwise: Yup.number().nullable(),
  }),
  is_prorated: Yup.boolean().nullable(),
  prorate_by_amount: Yup.boolean().nullable(),
});
