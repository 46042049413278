import { useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { useToasts } from 'components/Toasts';
import { useDelegatedMutationPolling, useQueryPolling } from 'api/jobHooks.helper';
import { CACHE_KEY as TRANSACTIONS_CACHE_KEY } from 'api/transactions/hooks';
import { CACHE_KEY as PRODUCTS_CACHE_KEY } from 'api/products/hooks';
import { CACHE_KEY as IMPORTS_CACHE_KEY } from 'api/imports/hooks';
import {
  getInvoice,
  bulkResetInvoicesMemo,
  bulkResetInvoicesSecondaryMemo,
  bulkUpdateInvoices,
  bulkMarkAsPaid,
  bulkMarkAsUnpaid,
  bulkMarkAsSent,
  updateInvoice,
  deleteInvoice,
  regenerateInvoicePDF as _regenerateInvoicePDF,
  createInvoice as _createInvoice,
  bulkUpdateUnsentInvoices as _bulkUpdateUnsentInvoices,
  bulkDetachInvoices as _bulkDetachInvoices,
  bulkDeleteInvoices as _bulkDeleteInvoices,
  skipTaxPolling as _skipTaxPolling,
  chargeInvoice as _chargeInvoice,
} from '../requests';
import { CACHE_KEY } from '../hooks';

export const useInvoiceAPI = ({
  orgId,
  invoiceId,
  autoFetch = true,
  enableToasts = true,
  scopes = [
    'invoice_items',
    'tracking_categories',
    'customer',
    'external_invoice',
    'reminders',
    'email_interactions',
    'sender',
    'auto_charge_data',
    'shipping_address',
    'invoice_serial_number',
    'invoice_pdf',
    'custom_fields',
    'receipt',
    'credit_notes',
  ],
}) => {
  const dataKey = [CACHE_KEY, orgId, 'invoice', invoiceId, scopes];
  const [wasProcessingInvoiceId, setWasProcessingInvoiceId] = useState(null);

  const { pushToast: wrappedPushToast, pushError: wrappedPushError } = useToasts();
  const pushToast = (...args) => {
    if (enableToasts) wrappedPushToast(...args);
  };
  const pushError = (...args) => {
    if (enableToasts) wrappedPushError(...args);
  };
  const queryClient = useQueryClient();

  const { data, error, isLoading, isFetching, refetch } = useQueryPolling({
    key: dataKey,
    fetchFn: () =>
      getInvoice({
        orgId,
        invoiceId,
        params: { scopes },
      }),
    fetchWithJobIdFn: (jobId) => getInvoice({ orgId, invoiceId, params: { jobId, scopes } }),
    queryOptions: {
      enabled: autoFetch && !!orgId && !!invoiceId,
      onSettled: (data) => {
        if (data?.state === 'completed') {
          if (wasProcessingInvoiceId && wasProcessingInvoiceId === invoiceId) {
            // Invalidates the currently opened invoicing schedule if it was ever in processing
            queryClient.invalidateQueries([CACHE_KEY, orgId, 'invoicingSchedules']);
            setWasProcessingInvoiceId(null);

            if (data?.data?.metadata?.job_status === 'failed') {
              wrappedPushError(
                { response: {} },
                `${
                  data?.data?.metadata?.last_save_invoice_error
                    ? 'Failed to update invoice: ' + data?.data?.metadata?.last_save_invoice_error
                    : 'Failed to update invoice.'
                }`,
                10000,
              );
            } else {
              enableToasts && wrappedPushToast('Successfully updated invoice!', 'success');
            }

            // Invalidates the invoices tables
            queryClient.invalidateQueries([CACHE_KEY, orgId, 'invoices']);

            queryClient.invalidateQueries(IMPORTS_CACHE_KEY);
            queryClient.invalidateQueries(PRODUCTS_CACHE_KEY);
            queryClient.invalidateQueries(TRANSACTIONS_CACHE_KEY);
          }
        } else if (!wasProcessingInvoiceId) {
          // Was ever in processing?
          setWasProcessingInvoiceId(invoiceId);
        }
      },
    },
    alwaysReturnData: true,
  });

  const editInvoice = useMutation(
    ({ id, data, ...rest }) => updateInvoice({ orgId, invoiceId: id, body: data, params: rest }),
    {
      onError: (err) => {
        pushError(err, 'Failed to update invoice.', 10000);
      },

      onSuccess: (_, variables) => {
        pushToast(
          // Sync operations
          variables?.voidInvoice ||
            variables?.markAsSent ||
            variables?.markAsPaid ||
            variables?.resetMemo ||
            variables?.resetEmailSubject ||
            variables?.resetEmailBody ||
            variables?.resetSecondaryMemo
            ? 'Successfully updated invoice!'
            : // Async operations
            !!variables?.sendInvoice
            ? 'Queued to send... Invoice will be sent shortly!'
            : 'Queued to save... Invoice will be saved shortly!',
          'success',
        );
      },

      onSettled: () => {
        queryClient.invalidateQueries(dataKey);
        queryClient.invalidateQueries(CACHE_KEY);
        queryClient.invalidateQueries(IMPORTS_CACHE_KEY);
        queryClient.invalidateQueries(PRODUCTS_CACHE_KEY);
        queryClient.invalidateQueries(TRANSACTIONS_CACHE_KEY);
      },
    },
  );

  const createInvoice = useMutation(({ data, ...rest }) => _createInvoice({ orgId, body: data, params: rest }), {
    onError: (err) => {
      pushError(err, 'Failed to create invoice.', 8000);
    },

    onSuccess: () => {
      pushToast('Successfully created invoice!', 'success');
    },

    onSettled: () => {
      queryClient.invalidateQueries(dataKey);
      queryClient.invalidateQueries(CACHE_KEY);
      queryClient.invalidateQueries(IMPORTS_CACHE_KEY);
      queryClient.invalidateQueries(PRODUCTS_CACHE_KEY);
      queryClient.invalidateQueries(TRANSACTIONS_CACHE_KEY);
    },
  });

  const bulkEditInvoice = useMutation(({ data }) => bulkUpdateInvoices({ orgId, body: data }), {
    onError: (err) => {
      pushError(err, 'Failed to bulk update invoices.', 8000);
    },

    onSuccess: () => {
      pushToast('Successfully bulk updated invoices!', 'success');
    },

    onSettled: () => {
      queryClient.invalidateQueries(dataKey);
      queryClient.invalidateQueries(TRANSACTIONS_CACHE_KEY);
      queryClient.invalidateQueries(CACHE_KEY);
    },
  });

  const bulkMarkInvoicesAsPaid = useDelegatedMutationPolling({
    mutationFn: ({ data }) => bulkMarkAsPaid({ orgId, body: data }),
    mutationOptions: {
      onError: (err) => {
        pushError(err, 'Failed to bulk update invoices.', -1);
      },

      onSuccess: () => {
        pushToast('Successfully bulk updated invoices!', 'success', -1);
      },

      onSettled: () => {
        queryClient.invalidateQueries(dataKey);
        queryClient.invalidateQueries(TRANSACTIONS_CACHE_KEY);
        queryClient.invalidateQueries(CACHE_KEY);
      },
    },
  });

  const bulkMarkInvoicesAsUnpaid = useDelegatedMutationPolling({
    mutationFn: ({ data }) => bulkMarkAsUnpaid({ orgId, body: data }),
    mutationOptions: {
      onError: (err) => {
        pushError(err, 'Failed to bulk update invoices.', -1);
      },

      onSuccess: () => {
        pushToast('Successfully bulk updated invoices!', 'success', -1);
      },

      onSettled: () => {
        queryClient.invalidateQueries(dataKey);
        queryClient.invalidateQueries(TRANSACTIONS_CACHE_KEY);
        queryClient.invalidateQueries(CACHE_KEY);
      },
    },
  });

  const bulkMarkInvoicesAsSent = useDelegatedMutationPolling({
    mutationFn: ({ data }) => bulkMarkAsSent({ orgId, body: data }),
    mutationOptions: {
      onError: (err) => {
        pushError(err, 'Failed to bulk update invoices.', -1);
      },

      onSuccess: () => {
        pushToast('Successfully bulk updated invoices!', 'success', -1);
      },

      onSettled: () => {
        queryClient.invalidateQueries(dataKey);
        queryClient.invalidateQueries(TRANSACTIONS_CACHE_KEY);
        queryClient.invalidateQueries(CACHE_KEY);
      },
    },
  });

  const bulkResetMemo = useMutation(({ data }) => bulkResetInvoicesMemo({ orgId, body: data }), {
    onError: (err) => {
      pushError(err, 'Failed to bulk change template.');
    },

    onSuccess: () => {
      pushToast('Successfully bulk changed template!', 'success');
    },

    onSettled: () => {
      queryClient.invalidateQueries(dataKey);
      queryClient.invalidateQueries(TRANSACTIONS_CACHE_KEY);
      queryClient.invalidateQueries(CACHE_KEY);
    },
  });

  const bulkResetSecondaryMemo = useMutation(({ data }) => bulkResetInvoicesSecondaryMemo({ orgId, body: data }), {
    onError: (err) => {
      pushError(err, 'Failed to bulk change secondary memo template.');
    },

    onSuccess: () => {
      pushToast('Successfully bulk changed secondary memo template!', 'success');
    },

    onSettled: () => {
      queryClient.invalidateQueries(dataKey);
      queryClient.invalidateQueries(TRANSACTIONS_CACHE_KEY);
      queryClient.invalidateQueries(CACHE_KEY);
    },
  });

  const bulkUpdateUnsentInvoices = useMutation(({ data }) => _bulkUpdateUnsentInvoices({ orgId, body: data }), {
    onError: (err) => {
      pushError(err, 'Failed to update details of existing unsent invoices');
    },

    onSuccess: () => {
      pushToast('Successfully updated details of existing unsent invoices!', 'success');
    },

    onSettled: () => {
      queryClient.invalidateQueries(dataKey);
      queryClient.invalidateQueries(TRANSACTIONS_CACHE_KEY);
      queryClient.invalidateQueries(CACHE_KEY);
    },
  });

  const bulkDetachInvoices = useMutation(
    ({ data, ...rest }) => _bulkDetachInvoices({ orgId, body: data, params: rest }),
    {
      onError: (err) => {
        pushError(err, 'Failed to bulk detach invoices');
      },

      onSuccess: () => {
        pushToast('Successfully detached invoices!', 'success');
      },

      onSettled: () => {
        queryClient.invalidateQueries(dataKey);
        queryClient.invalidateQueries(TRANSACTIONS_CACHE_KEY);
        queryClient.invalidateQueries(CACHE_KEY);
      },
    },
  );

  const bulkDeleteInvoices = useMutation(
    ({ data, ...rest }) => _bulkDeleteInvoices({ orgId, body: data, params: rest }),
    {
      onError: (err) => {
        pushError(err, 'Failed to bulk delete invoices');
      },

      onSuccess: () => {
        pushToast('Successfully deleted invoices!', 'success');
      },

      onSettled: () => {
        queryClient.invalidateQueries(dataKey);
        queryClient.invalidateQueries(TRANSACTIONS_CACHE_KEY);
        queryClient.invalidateQueries(CACHE_KEY);
      },
    },
  );

  const removeInvoice = useMutation(({ id, params }) => deleteInvoice({ orgId, invoiceId: id, params }), {
    onError: (err) => {
      pushError(err, 'Failed to remove invoice.');
    },

    onSuccess: () => {
      pushToast('Successfully removed invoice!', 'success');
    },

    onSettled: () => {
      queryClient.invalidateQueries(dataKey);
      queryClient.invalidateQueries(TRANSACTIONS_CACHE_KEY);
      queryClient.invalidateQueries(CACHE_KEY);
    },
  });

  const chargeInvoice = useMutation(({ id, params }) => _chargeInvoice({ orgId, invoiceId: id, params }), {
    onError: (err) => {
      pushError(err, 'Failed to charge invoice.');
    },

    onSuccess: () => {
      pushToast('Successfully processing invoice payment!', 'success');
    },

    onSettled: () => {
      queryClient.invalidateQueries(CACHE_KEY);
    },
  });

  const regenerateInvoicePDF = useMutation(
    ({ id, data }) => _regenerateInvoicePDF({ orgId, invoiceId: id, body: data }),
    {
      onError: (err) => {
        pushError(err, 'Failed to regenerate invoice PDF.');
      },

      onSuccess: () => {
        pushToast('Successfully regenerated invoice PDF!', 'success');
      },

      onSettled: () => {
        queryClient.invalidateQueries(CACHE_KEY);
      },
    },
  );

  const skipTaxPolling = useMutation(({ id }) => _skipTaxPolling({ orgId, invoiceId: id }), {
    onError: (err) => {
      pushError(err, 'Failed to end tax sync.');
    },

    onSuccess: () => {
      pushToast('Ending tax sync!', 'success');
    },

    onSettled: () => {
      queryClient.invalidateQueries(CACHE_KEY);
    },
  });

  return {
    data,
    error,
    isLoading,
    isFetching: isFetching && !['queued_up', 'processing'].includes(data?.metadata?.job_status),
    operations: {
      refetch,
      createInvoice: createInvoice.mutateAsync,
      editInvoice: editInvoice.mutateAsync,
      bulkEditInvoices: bulkEditInvoice.mutateAsync,
      bulkMarkInvoicesAsPaid: bulkMarkInvoicesAsPaid.mutateAsync,
      bulkMarkInvoicesAsUnpaid: bulkMarkInvoicesAsUnpaid.mutateAsync,
      bulkMarkInvoicesAsSent: bulkMarkInvoicesAsSent.mutateAsync,
      bulkResetMemo: bulkResetMemo.mutateAsync,
      removeInvoice: removeInvoice.mutateAsync,
      bulkDetachInvoices: bulkDetachInvoices.mutateAsync,
      bulkDeleteInvoices: bulkDeleteInvoices.mutateAsync,
      bulkUpdateUnsentInvoices: bulkUpdateUnsentInvoices.mutateAsync,
      chargeInvoice: chargeInvoice.mutateAsync,
      regenerateInvoicePDF: regenerateInvoicePDF.mutateAsync,
      bulkResetSecondaryMemo: bulkResetSecondaryMemo.mutateAsync,
      skipTaxPolling: skipTaxPolling.mutateAsync,
    },
  };
};
