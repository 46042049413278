import { sortBy } from 'lodash';
import NewIcon from 'images/fire.svg';
import UpsellIcon from 'images/upsell.svg';
import ExistingIcon from 'images/circuit.svg';
import DownsellIcon from 'images/downsell.svg';
import ChurnIcon from 'images/churn.svg';
import InfluxIcon from 'images/influx.svg';
import { convertQuartersConfig } from 'utils/dateUtils';
import { humanize } from 'utils/stringUtils';
import {
  BUSINESS_SNAPSHOT_COLUMNS,
  SEGMENT_BY_OPTIONS,
  SEGMENT_TYPES,
  NUMBER_FORMATS,
  WATERFALL_GRAPH_PERIOD_OPTIONS,
} from 'consts/global';
import {
  CUSTOMER_TIMESERIES_METRICS_SUFFIX,
  NULL_SEGMENT_STRING_TIMESERIES,
} from 'shared/Filters/MetadataFilter/consts';
import { dataFilterSegmentKeyToDatabaseSegmentKey } from 'shared/MetadataSegmentsModal/utils';
import { REVENUE_METRIC } from 'consts/revenueMetrics';
import { getSegmentTitle } from 'views/Spreads/utils';
import { setQuarterColumn } from './setQuarterColumn';

const CUMULATIVE_COLUMN_HEADER = 'CUMULATIVE';

// We're calling this function before adding all the segmentation rows
//  so that both for regular columns and summary columns we have all the breakdown
//  rows that we need
const addMissingBreakdownRowsForTable = ({
  breakdowns,
  breakdownRows,
  segmentBytopLevel,
  segmentBy,
  dataType,
  organizations,
  segmentTooltips,
}) => {
  const isTimeseriesMetric = segmentBy.includes(CUSTOMER_TIMESERIES_METRICS_SUFFIX);

  const metadataSegmentKey = dataFilterSegmentKeyToDatabaseSegmentKey(segmentBy);

  for (const [breakdownMetric, breakdownMetricValues] of Object.entries(breakdowns ?? {})) {
    for (const [segmentKey, segmentValue] of Object.entries(breakdownMetricValues ?? {})) {
      const customSegmentTooltip = segmentTooltips?.[metadataSegmentKey]?.[segmentKey];
      const breakdownRowById = breakdownRows[breakdownMetric].find((row) => row.key === segmentKey);
      if (!breakdownRowById && segmentValue !== 0) {
        if (
          [
            SEGMENT_BY_OPTIONS.PRODUCT,
            SEGMENT_BY_OPTIONS.CUSTOMER_METADATA,
            SEGMENT_BY_OPTIONS.CUSTOMER_METADATA_CUSTOM,
            SEGMENT_BY_OPTIONS.TRANSACTION_METADATA,
            SEGMENT_BY_OPTIONS.TRANSACTION_METADATA_CUSTOM,
            SEGMENT_BY_OPTIONS.CHANGE_CATEGORY,
            SEGMENT_BY_OPTIONS.PRODUCT_CATEGORY,
          ].includes(segmentBytopLevel)
        ) {
          const title = getSegmentTitle({
            segmentKey,
            segmentBy: segmentBytopLevel,
            products: organizations?.[0]?.products ?? [],
            productCategories: organizations?.[0]?.productCategories ?? [],
          });

          breakdownRows[breakdownMetric].push({
            title,
            type: 'defaultBreakdown',
            isTotalRow: breakdownMetric === 'total',
            // Summary breakdowns not supported for change category breakdown
            dontShowQuarterAndYearCells: segmentBytopLevel === SEGMENT_BY_OPTIONS.CHANGE_CATEGORY,
            dataType,
            key: segmentKey,
            data: {},
            tooltipText:
              isTimeseriesMetric && segmentKey === NULL_SEGMENT_STRING_TIMESERIES
                ? "Customers who don't have a value for the month"
                : customSegmentTooltip
                ? customSegmentTooltip
                : undefined,
          });
        }
      }
    }

    //we use sortBy there for consistent order in segments
    breakdownRows[breakdownMetric] = sortBy(breakdownRows[breakdownMetric], 'title');
  }
};

const generateWaterfallBreakdown = ({
  organizations,
  segmentBytopLevel,
  segmentBreakdowns,
  segmentBy,
  breakdownRows,
  month,
  segmentType,
  waterfallType,
  formattedMonthForTable,
  dataType,
  segmentTooltips,
}) => {
  addMissingBreakdownRowsForTable({
    breakdowns: segmentBreakdowns?.[month]?.[segmentType]?.[waterfallType],
    breakdownRows,
    segmentBytopLevel,
    segmentBy,
    waterfallType,
    dataType,
    organizations,
    segmentTooltips,
  });

  Object.entries(segmentBreakdowns?.[month]?.[segmentType]?.[waterfallType] ?? {}).forEach(
    ([breakdownMetric, breakdownMetricValues]) => {
      Object.entries(breakdownMetricValues).forEach(([segmentKey, segmentValue]) => {
        if (segmentValue !== 0) {
          const breakdownRowById = breakdownRows[breakdownMetric].find((row) => row.key === segmentKey);
          breakdownRowById.data[formattedMonthForTable] = segmentValue;
        }
      });
    },
  );
};

const generateSummaryData = ({
  stats = {},
  header,
  showInflux,
  newData,
  upsellData,
  existingData,
  downsellData,
  churnData,
  influxData,
  showInfluxMovement,
  influxMovementData,
  interpolatedData,
  totalData,
}) => {
  // add to table data
  newData[header] = stats[REVENUE_METRIC.NEW.key];
  upsellData[header] = stats[REVENUE_METRIC.UPSELL.key];
  existingData[header] = stats[REVENUE_METRIC.EXISTING.key];
  downsellData[header] = stats[REVENUE_METRIC.DOWNSELL.key];
  churnData[header] = stats[REVENUE_METRIC.CHURN.key];
  showInflux && (influxData[header] = stats[REVENUE_METRIC.INFLUX.key]);
  showInfluxMovement && (influxMovementData[header] = stats[REVENUE_METRIC.INFLUX_MOVEMENT.key]);
  totalData[header] = stats[REVENUE_METRIC.TOTAL.key];

  if (interpolatedData) {
    interpolatedData[header] = stats?.Interpolated ?? 0;
  }
};

const generateSummaryBreakdown = ({
  summaryStats,
  breakdownRows,
  summaryHeader,
  segmentType,
  waterfallType,
  segmentBytopLevel,
  segmentBy,
  dataType,
  organizations,
  segmentTooltips,
}) => {
  addMissingBreakdownRowsForTable({
    breakdowns: summaryStats[summaryHeader]?.waterfall?.breakdown?.[segmentType]?.[waterfallType],
    breakdownRows,
    segmentBytopLevel,
    segmentBy,
    waterfallType,
    dataType,
    organizations,
    segmentTooltips,
  });
  // breakdown summaries
  Object.keys(breakdownRows).forEach((breakdownMetric) => {
    breakdownRows[breakdownMetric].forEach(
      (row) =>
        (row.data[summaryHeader] =
          summaryStats[summaryHeader]?.waterfall?.breakdown?.[segmentType]?.[waterfallType]?.[breakdownMetric]?.[
            row.key
          ] ?? 0),
    );
  });
};

const addCustomerTimeseriesRows = ({ rows, startDate, endDate, timeSeriesMetrics }) => {
  const metricKeys = Object.keys(timeSeriesMetrics);
  const metricsForMonths = {};
  // timeseries values for every month in date range
  for (
    let currentDate = startDate;
    currentDate.isBefore(endDate.endOf('month'));
    currentDate = currentDate.add(1, 'month')
  ) {
    const monthKey = currentDate.format('YYYY-MM');
    const formattedMonthForTable = currentDate.format('MMM YYYY');
    for (const metricKey of metricKeys) {
      metricsForMonths[metricKey] = metricsForMonths[metricKey] ?? {};
      metricsForMonths[metricKey][formattedMonthForTable] = timeSeriesMetrics[metricKey][monthKey] ?? 0;
    }
  }

  // add timeseries rows
  metricKeys.forEach((metricKey) => {
    rows.push({
      data: metricsForMonths[metricKey],
      dataType: NUMBER_FORMATS.NUMBER,
      rowKey: metricKey,
      title: humanize(metricKey),
      type: 'customer_timeseries_metric',
    });
  });
};

const IS_INCREASES_ORDERING = [
  REVENUE_METRIC.NEW.label,
  REVENUE_METRIC.UPSELL.label,
  REVENUE_METRIC.EXISTING.label,
  REVENUE_METRIC.INFLUX.label,
  REVENUE_METRIC.INFLUX_MOVEMENT.label,
  REVENUE_METRIC.DOWNSELL.label,
  REVENUE_METRIC.CHURN.label,
];

// This function takes the rawData that comes back from the API call and formats
// it in a way that DataTable can display it
export const reshapeDataForTable = ({
  startDate,
  endDate,
  organizations,
  rawData,
  summaryStats,
  segmentBreakdowns = null,
  isIncreases,
  showWaterFallExistingRow,
  showInflux,
  showInfluxMovement,
  segmentBy,
  selectedColumns = {
    [BUSINESS_SNAPSHOT_COLUMNS.MONTHS]: true,
  },
  waterfallGraphPeriod,
  quarters,
  quartersYearOffset,
  waterfallType,
  countInfluxAsRenewed,
  timeSeriesMetrics,
  segmentTooltips,
}) => {
  const columnHeaders = [];
  const waterfallColumnHeaders = [];
  const totalData = {};
  const newData = {};
  const upsellData = {};
  const existingData = {};
  const downsellData = {};
  const churnData = {};
  const influxData = {};
  const influxMovementData = {};
  const interpolatedData = {};

  const breakdownRows = {
    influx: [],
    influxMovement: [],
    downsell: [],
    upsell: [],
    new: [],
    existing: [],
    churn: [],
    total: [],
  };
  const segmentBytopLevel = segmentBy ? segmentBy.split('-')[0] : null;
  const segmentType = SEGMENT_TYPES[segmentBytopLevel];
  const quarterEndMonths = convertQuartersConfig(quarters);
  const summaryUnitsKey = waterfallType?.toLowerCase();
  const dataType = waterfallType === 'Revenue' ? NUMBER_FORMATS.CURRENCY : NUMBER_FORMATS.NUMBER;

  for (
    let currentDate = startDate;
    currentDate.isBefore(endDate.endOf('month'));
    currentDate = currentDate.add(1, 'month')
  ) {
    const month = currentDate.format('YYYY-MM');
    const formattedMonthForTable = currentDate.format('MMM YYYY');
    if (selectedColumns && selectedColumns[BUSINESS_SNAPSHOT_COLUMNS.MONTHS]) {
      columnHeaders.push(formattedMonthForTable);
    }
    if (waterfallGraphPeriod === WATERFALL_GRAPH_PERIOD_OPTIONS.MONTHS)
      waterfallColumnHeaders.push(formattedMonthForTable);
    const { quarterHeader, yearHeader } = setQuarterColumn({
      date: currentDate,
      selectedColumns,
      waterfallGraphPeriod,
      waterfallColumnHeaders,
      columnHeaders,
      quarterEndMonths,
      quartersYearOffset,
    });

    generateSummaryData({
      stats: rawData[month],
      header: formattedMonthForTable,
      showInflux,
      totalData,
      newData,
      upsellData,
      existingData,
      downsellData,
      churnData,
      influxData,
      showInfluxMovement,
      influxMovementData,
      interpolatedData,
      waterfallType,
    });

    // Waterfall Breakdowns
    if (segmentBreakdowns && segmentBy) {
      generateWaterfallBreakdown({
        organizations,
        segmentBytopLevel,
        segmentBreakdowns,
        segmentBy,
        breakdownRows,
        month,
        segmentType,
        waterfallType,
        formattedMonthForTable,
        dataType,
        segmentTooltips,
      });
    }

    // add in Quarter Summary data
    if (quarterHeader && summaryStats?.[quarterHeader]) {
      generateSummaryData({
        stats: summaryStats[quarterHeader]?.waterfall[summaryUnitsKey],
        header: quarterHeader,
        showInflux,
        totalData,
        newData,
        upsellData,
        existingData,
        downsellData,
        churnData,
        influxData,
        showInfluxMovement,
        influxMovementData,
      });

      // breakdown quarterly summaries
      if (segmentBreakdowns && segmentBy) {
        generateSummaryBreakdown({
          breakdownRows,
          summaryStats,
          summaryHeader: quarterHeader,
          segmentType,
          waterfallType,
          segmentBytopLevel,
          segmentBy,
          dataType,
          organizations,
          segmentTooltips,
        });
      }
    }

    // add in Year Summary data
    if (yearHeader && summaryStats?.[yearHeader]) {
      generateSummaryData({
        stats: summaryStats[yearHeader]?.waterfall[summaryUnitsKey],
        header: yearHeader,
        showInflux,
        totalData,
        newData,
        upsellData,
        existingData,
        downsellData,
        churnData,
        influxData,
        showInfluxMovement,
        influxMovementData,
      });

      // breakdown quarterly summaries
      if (segmentBreakdowns && segmentBy) {
        generateSummaryBreakdown({
          breakdownRows,
          summaryStats,
          summaryHeader: yearHeader,
          segmentType,
          waterfallType,
          segmentBytopLevel,
          segmentBy,
          dataType,
          organizations,
          segmentTooltips,
        });
      }
    }
  }

  if (selectedColumns && selectedColumns[BUSINESS_SNAPSHOT_COLUMNS.CUMULATIVE] && summaryStats) {
    // add to header
    columnHeaders.push(CUMULATIVE_COLUMN_HEADER);

    generateSummaryData({
      stats: summaryStats[CUMULATIVE_COLUMN_HEADER]?.waterfall[summaryUnitsKey],
      header: CUMULATIVE_COLUMN_HEADER,
      showInflux,
      totalData,
      newData,
      upsellData,
      existingData,
      downsellData,
      churnData,
      influxData,
      showInfluxMovement,
      influxMovementData,
    });

    // breakdown quarterly summaries
    if (segmentBreakdowns && segmentBy) {
      generateSummaryBreakdown({
        breakdownRows,
        summaryStats,
        summaryHeader: CUMULATIVE_COLUMN_HEADER,
        segmentType,
        waterfallType,
        segmentBytopLevel,
        segmentBy,
        dataType,
        organizations,
        segmentTooltips,
      });
    }
  }

  const rows = [
    {
      title: REVENUE_METRIC.CHURN.label,
      rowKey: REVENUE_METRIC.CHURN.key,
      icon: ChurnIcon,
      dataType,
      data: churnData,
      subRows: [],
    },
    {
      title: REVENUE_METRIC.DOWNSELL.label,
      rowKey: REVENUE_METRIC.DOWNSELL.key,
      icon: DownsellIcon,
      dataType,
      data: downsellData,
      subRows: [],
    },
  ];

  if (showWaterFallExistingRow) {
    rows.push({
      title: REVENUE_METRIC.EXISTING.label,
      rowKey: REVENUE_METRIC.EXISTING.key,
      icon: ExistingIcon,
      dataType,
      data: existingData,
      subRows: [],
    });
  }

  if (countInfluxAsRenewed && showWaterFallExistingRow && showInflux) {
    rows.push({
      title: REVENUE_METRIC.INFLUX.label,
      rowKey: REVENUE_METRIC.INFLUX.key,
      icon: InfluxIcon,
      dataType,
      data: influxData,
      subRows: [],
      tooltipText: 'In Flux is a sub-category of Existing',
    });
  }

  // [JB 2021-11-24] Let's not show InfluxMovement when segmentedBy
  // Let's wait for customer requesting this because the BE work for this is not trivial
  if (countInfluxAsRenewed && showWaterFallExistingRow && showInflux && showInfluxMovement && !segmentBy) {
    rows.push({
      title: REVENUE_METRIC.INFLUX_MOVEMENT.label,
      rowKey: REVENUE_METRIC.INFLUX_MOVEMENT.key,
      icon: InfluxIcon,
      dataType,
      data: influxMovementData,
      subRows: [],
      tooltipText: 'In Flux Movement is the change in In Flux',
    });
  }

  rows.push({
    title: REVENUE_METRIC.UPSELL.label,
    rowKey: REVENUE_METRIC.UPSELL.key,
    icon: UpsellIcon,
    dataType,
    data: upsellData,
    subRows: [],
  });

  rows.push({
    title: REVENUE_METRIC.NEW.label,
    rowKey: REVENUE_METRIC.NEW.key,
    icon: NewIcon,
    dataType,
    data: newData,
    subRows: [],
  });

  if (isIncreases) {
    // ordering based on IS_INCREASES_ORDERING
    rows.sort((a, b) =>
      IS_INCREASES_ORDERING.findIndex((e) => e === a.title) < IS_INCREASES_ORDERING.findIndex((e) => e === b.title)
        ? -1
        : 1,
    );
  }

  rows.push({
    title: `Total ${waterfallType}`,
    rowKey: 'Total',
    dataType,
    type: 'totalRow',
    data: totalData,
    subRows: [],
  });

  // customer timeseries metrics
  if (timeSeriesMetrics) {
    addCustomerTimeseriesRows({ rows, startDate, endDate, timeSeriesMetrics });
  }

  // push product breakdown rows to metric subRows
  if (segmentBy && breakdownRows) {
    rows.forEach((row) => {
      const rowKeyLowerCase = String(row.rowKey).toLocaleLowerCase();
      if (row.rowKey && breakdownRows[rowKeyLowerCase].length) {
        row.subRows.unshift(...breakdownRows[rowKeyLowerCase]);
      }
    });
  }

  return {
    columnHeaders,
    waterfallColumnHeaders,
    tooltips: {
      [REVENUE_METRIC.EXISTING.label]: interpolatedData,
    },
    rows,
  };
};
