import React, { useContext, useMemo, useState, useEffect } from 'react';
import { Form } from 'formik';
import styled from 'styled-components';
import { isEmpty } from 'lodash';
import dayjs from 'dayjs';

import { AppContext } from 'AppContext';
import { createCustomer } from 'api';
import { useUsageSubscriptionsAPI, getPricingPlansFromSearch } from 'api/usageBasedEngine';
import { HelpCircleIcon, TrashIcon } from 'components/Icons';
import { ModalFooter } from 'components/Modal';
import { CancelButton, SaveButton } from 'components/Buttons';
import { Centerer, Flexer, FlexBetweenContainer, FlexEndContainer, FlexerRow, Spacer } from 'components/Core';
import {
  FormikCustomSelector,
  CustomDatePicker,
  FormikCustomInput,
  FormikCustomCheckbox,
  SwitchWithLabel,
} from 'components/Controls';
import { InfoIcon } from 'components/Icons';
import { TooltipContainer } from 'components/Tooltip';
import { getCustomersFromSearch } from 'shared/TransactionContent';
import { FormikDiscountSection } from 'shared/FormikDiscountSection';
import { METADATA_FILTER_TYPES } from 'shared/Filters/MetadataFilter';
import { MetadataItemFormikInput } from 'shared/Common/MetadataSection';
import { METADATA_TYPES, useCombinedMetadata, useMetadataActionsModal } from 'shared/Common';
import { getCustomerDisplayName } from 'models/customer';
import { INTERNAL_TRANSACTION_METADATA } from 'models/transaction';
import { formatDateForDatepicker, updateDateFromDatePicker } from 'utils/dateUtils';
import { ReactComponent as ExternalLinkIcon } from 'images/external-link-full.svg';
import { ReactComponent as PencilIcon } from 'images/pencil-new.svg';
import { useMVP } from 'utils/hooks';

const Header = styled.div`
  font-size: 24px;
  line-height: 38px;
  margin-bottom: 20px;

  font-weight: 900;
`;

const AggregatedUsageText = styled.div`
  font-weight: 600;
`;

const FormRow = styled(FlexBetweenContainer)`
  margin-bottom: 20px;
  gap: 12px;
`;

const FlexBasis = styled.div`
  flex-basis: 33%;
`;

const CustomFlexBasis = styled.div`
  flex-basis: ${({ width }) => width ?? '33%'};
`;

const FullWidthFlexBasis = styled.div`
  flex-basis: 100%;
`;

const Wrapper = styled.div`
  padding: 0 50px;
  background-color: ${({ bgcolor }) => bgcolor ?? 'var(--primaryGray)'};
`;

const FormFooter = styled(ModalFooter)`
  width: 100%;
`;

export const SUBSCRIPTION_EDIT_MODAL_MODE = {
  CREATE: 'create',
  EDIT: 'edit',
};

const InfoTextWrapper = styled(Flexer)`
  padding: 12px;
  gap: 10px;
  border-radius: 8px;
  border: 1px solid var(--neutralGray);
  background-color: var(--accentGrayFourth);
  margin-bottom: 16px;
`;

const InfoText = styled.div`
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  color: var(--primaryBlack);
`;

const Seperator = styled.div`
  height: 1px;
  align-self: stretch;
  background: var(--accentGraySecond);
  margin-bottom: 20px;
`;

const SubscriptionSubtext = styled.div`
  font-size: 12px;
  line-height: 16px;
  margin-bottom: 8px;
  color: var(--primaryBlack);
  opacity: 0.4;
`;

const TransactionText = styled.div`
  color: var(--primaryBlack);
  font-size: 14px;
  font-weight: 900;
  line-height: 20px;
  margin-right: 16px;
  margin-left: 8px;
  white-space: nowrap;
  display: flex;
  gap: 6px;
`;

const TransactionLinkWrapper = styled.div`
  display: flex;
  gap: 8px;
  cursor: pointer;
`;

const MetadataBlockWrapper = styled.div`
  display: flex;
  padding: 8px;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  align-self: stretch;
  border-radius: 12px;
  background: var(--primaryBlack2);
`;

const MetadataBadge = styled.div`
  display: flex;
  width: 24px;
  height: 24px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 100px;
  background: var(--primaryBlack5);
  font-size: 12px;
`;

const AddMetadataButton = styled.div`
  display: flex;
  padding: 4px 8px;
  align-items: center;
  gap: 10px;
  border-radius: 4px;
  background: var(--primaryBlue10);
  color: var(--primaryBlue);
  font-size: 12px;
  cursor: pointer;
`;

const ExternalLinkIconWrapper = styled.div`
  display: flex;
  width: 20px;
  height: 20px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 4px;
  background: var(--primaryBlue10);
  cursor: pointer;
`;

const StyledPencilIcon = styled(PencilIcon)`
  margin-bottom: 2px;
  width: 20px;
  height: 20px;

  path {
    fill: var(--primaryBlack);
  }
`;

const TabsContainer = styled.div`
  display: flex;
  width: ${(props) => (props.width ? props.width : '300px')};
  padding: 4px;
  align-items: flex-start;
  gap: 4px;
  border-radius: 8px;
  border: 1px solid var(--neutralGray);
  background: #fff;
  box-shadow: 4px 4px 24px 0px var(--primaryBlack4);
`;

const Tab = styled.div`
  display: flex;
  padding: 4px 8px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4px;
  flex: 1 0 0;
  background: ${(props) => (props.active ? 'var(--primaryBlue10)' : 'initial')};
  color: ${(props) => (props.active ? '#0075FF' : 'initial')};
  border-radius: 4px;
  font-size: 12px;
  font-weight: ${(props) => (props.active ? '700' : '600')};
  border-radius: 4px;
  cursor: pointer;
`;

const OverflowDiv = styled.div`
  overflow: auto;
`;

const formatDate = (date) => dayjs.utc(date).format('MMM D');

const displayPreviousSubscription = (subscription) =>
  `${formatDate(subscription.start_date)} - ${formatDate(subscription.end_date)} | ${subscription.pricing_plan.name}`;

export const SubscriptionForm = ({
  values,
  setFieldValue,
  handleSubmit,
  getFieldMeta,
  createUsageSubscription,
  editUsageSubscription,
  mode,
  onClose,
}) => {
  const initialCustomer = {
    label: `All child customers${values?.customer_name ? ` of ${values.customer_name}` : ''}`,
    manual: false,
    value: -1,
    id: -1,
  };

  const { orgId } = useContext(AppContext);
  const [mainTransactionSelected, setMainTransactionSelected] = useState(true);
  const [selectedChildCustomers, setSelectedChildCustomers] = useState(
    values?.selected_child_customers?.reduce((obj, child_customer) => {
      obj[child_customer.id] = child_customer.label;
      return obj;
    }, {}),
  );
  const isMvp = useMVP();

  const { data: previousSubcriptionsData } = useUsageSubscriptionsAPI({
    orgId,
    autoFetch: !!values?.customer_id,
    params: {
      filters: {
        customerIds: [values?.customer_id],
      },
      pagination: {
        limit: 100,
      },
      scopes: ['pricing_plans'],
    },
  });

  const existingOverageTransaction = values?.transactions?.filter(
    (t) => t.metadata['Usage Transaction Type'] === 'overage',
  )?.[0];
  const existingPrepaidTransaction = values?.transactions?.filter(
    (t) => t.metadata['Usage Transaction Type'] === 'prepaid',
  )?.[0];

  const selectedTransactionId = mainTransactionSelected
    ? existingOverageTransaction?.id
    : existingPrepaidTransaction?.id;

  const previousSubscriptionOptions = useMemo(
    () =>
      (previousSubcriptionsData?.data ?? [])
        .filter(
          ({ id, renewal_id }) =>
            [null, values?.id].includes(renewal_id) && id !== values?.id && id !== values?.renewal_id,
        )
        .map((subscription) => ({
          value: subscription.id,
          label: displayPreviousSubscription(subscription),
        })),
    [previousSubcriptionsData, values?.renewal_id, values?.id],
  );

  const handlePreviousSubscriptionChange = (option) => {
    const previousSubscription = (previousSubcriptionsData?.data ?? []).find(({ id }) => id === option?.value);
    setFieldValue('previous_subscription_id', previousSubscription?.id ?? null);
    if (previousSubscription) {
      setFieldValue('pricing_plan_id', previousSubscription.pricing_plan_id);
      setFieldValue('pricing_plan_name', previousSubscription.pricing_plan.name);
      setFieldValue('start_date', previousSubscription.end_date);
      const previousLength = dayjs(previousSubscription.end_date).diff(previousSubscription.start_date, 'month');
      setFieldValue('end_date', dayjs(previousSubscription.end_date).add(previousLength, 'month'));
    }
  };

  useEffect(() => {
    // Set child_customer_ids from selected_child_customers, if it exists
    if (!values?.child_customer_ids && values?.selected_child_customers?.length) {
      setFieldValue(
        'child_customer_ids',
        values?.selected_child_customers.map(({ id }) => id),
      );
    }
  }, [values?.selected_child_customers, values?.child_customer_ids, setFieldValue]);

  const { combinedMetadataOptions } = useCombinedMetadata({
    orgId,
    currentValues: {
      ...Object.fromEntries(
        Object.entries({ ...values?.overage_transaction_metadata }).filter(
          ([key]) => !Object.values(INTERNAL_TRANSACTION_METADATA).includes(key),
        ),
      ),
    },
    metadataFilterType: METADATA_FILTER_TYPES.TRANSACTIONS,
  });

  const { combinedMetadataOptions: combinedPrepaidMetadataOptions } = useCombinedMetadata({
    orgId,
    currentValues: {
      ...Object.fromEntries(
        Object.entries({ ...values?.prepaid_transaction_metadata }).filter(
          ([key]) => !Object.values(INTERNAL_TRANSACTION_METADATA).includes(key),
        ),
      ),
    },
    metadataFilterType: METADATA_FILTER_TYPES.TRANSACTIONS,
  });

  const selectedCombinedMetadataOptions = mainTransactionSelected
    ? combinedMetadataOptions
    : combinedPrepaidMetadataOptions;

  const formatToValueStructure = ({ draft }) =>
    draft.reduce(
      (obj, item) => ({
        ...obj,
        [item.key]: item.value,
      }),
      {},
    );

  const handleFormChange = (newMetadata) => {
    if (mainTransactionSelected) {
      setFieldValue('overage_transaction_metadata', newMetadata);
    } else {
      setFieldValue('prepaid_transaction_metadata', newMetadata);
    }
  };

  const handleOnCloseMetadataModal = (metadataItem) => {
    if (metadataItem) {
      const metadataItems = {
        ...formatToValueStructure({
          draft: selectedCombinedMetadataOptions,
        }),
        [metadataItem.key]: metadataItem.value,
      };

      handleFormChange(metadataItems);
    }
  };

  const { openCreateMetadataModal, MetadataActionsModal } = useMetadataActionsModal({
    organizationId: orgId,
    onClose: handleOnCloseMetadataModal,
  });

  return (
    <OverflowDiv>
      <Form>
        <Wrapper>
          <Header data-cy="edit-subscription-modal__title">
            {mode === SUBSCRIPTION_EDIT_MODAL_MODE.EDIT ? 'Edit' : 'Create'} Usage-based Subscription
          </Header>

          <FormRow>
            <FlexBasis>
              <FormikCustomSelector
                isDisabled={isEmpty(previousSubscriptionOptions)}
                label="Previous subscription"
                placeholder="Select subscription"
                name="previous_subscription_id"
                value={
                  values?.previous_subscription_id
                    ? previousSubscriptionOptions.find(({ value }) => value === values.previous_subscription_id)
                    : null
                }
                options={previousSubscriptionOptions}
                handleChange={handlePreviousSubscriptionChange}
                isClearable
              />
            </FlexBasis>
            <FlexBasis>
              <FormikCustomSelector
                label="Pricing Plan"
                placeholder="Select pricing plan"
                name="pricing_plan_id"
                value={
                  values?.pricing_plan_id
                    ? {
                        label: values?.pricing_plan_name,
                        value: values?.pricing_plan_id,
                      }
                    : null
                }
                handleChange={(option) => {
                  if (option) {
                    setFieldValue('pricing_plan_id', option.value);
                    setFieldValue('pricing_plan_name', option.label);
                    setFieldValue('currency', option.data?.currency);
                  } else {
                    setFieldValue('pricing_plan_id', null);
                    setFieldValue('pricing_plan_name', null);
                    setFieldValue('currency', null);
                  }
                }}
                loadOptions={(searchQuery, prevOptions, additional) =>
                  getPricingPlansFromSearch({ searchQuery, orgId, prevOptions, additional })
                }
                isPaginateable
                isClearable
              />
            </FlexBasis>

            <FlexBasis>
              <FormikCustomInput
                name="trial_units"
                data-cy="subscription-create-modal__trial-units"
                label={
                  <Centerer>
                    <span>Trial Units</span>
                    {!values?.previous_subscription_id && (
                      <TooltipContainer toolTipContent="We will only start counting after the customer has used up this number of units">
                        <HelpCircleIcon />
                      </TooltipContainer>
                    )}
                  </Centerer>
                }
                placeholder="Enter trial units"
                type="number"
                handleChange={(value) => setFieldValue('trial_units', value)}
                isDisabled={!!values?.previous_subscription_id}
              />
            </FlexBasis>
            <FlexBasis>
              <CustomDatePicker
                meta={getFieldMeta('start_date')}
                name="start_date"
                label="Start Date"
                selected={values?.start_date ? formatDateForDatepicker(values?.start_date) : null}
                onChange={(value) => setFieldValue('start_date', updateDateFromDatePicker(value))}
              />
            </FlexBasis>
            <FlexBasis>
              <CustomDatePicker
                meta={getFieldMeta('end_date')}
                name="end_date"
                label="End Date"
                selected={values?.end_date ? formatDateForDatepicker(values?.end_date) : null}
                onChange={(value) => setFieldValue('end_date', updateDateFromDatePicker(value))}
              />
            </FlexBasis>
          </FormRow>

          <FormRow>
            <Flexer gap="12px">
              <TooltipContainer
                toolTipContent={`We will prorate invoices and related spreads based on usage within a period. 
                  Prepaid amounts will be prorated, whereas fixed amounts will remain the same.`}
                fontSize="12px"
              >
                <FormikCustomCheckbox name="is_prorated" label="Prorate subscription" />
              </TooltipContainer>

              {values?.is_prorated && (
                <TooltipContainer
                  toolTipContent={`We will prorate by total period amount instead of usage date`}
                  fontSize="12px"
                  width={200}
                >
                  <SwitchWithLabel
                    name="prorate_by_amount"
                    label="Prorate by amount"
                    labelSize="12px"
                    onChange={(value) => setFieldValue('prorate_by_amount', value)}
                    checked={values?.prorate_by_amount}
                  />
                </TooltipContainer>
              )}
            </Flexer>
          </FormRow>

          {isMvp && (
            <FormRow>
              <FullWidthFlexBasis>
                <SubscriptionSubtext>Additional Settings</SubscriptionSubtext>
                <FormikCustomCheckbox
                  data-cy="subscription-create-modal__aggregate-usage-on-parent"
                  name="aggregate_usage_on_parent"
                  label={
                    <AggregatedUsageText>
                      Aggregate usage on parent customer (usage of child customers you select will be summed before
                      comparing to plan tiers, then the results will be divided among the children)
                    </AggregatedUsageText>
                  }
                />
              </FullWidthFlexBasis>
            </FormRow>
          )}

          <FormRow>
            <CustomFlexBasis width={values?.aggregate_usage_on_parent ? '25%' : '100%'}>
              <FormikCustomSelector
                isDisabled={mode === SUBSCRIPTION_EDIT_MODAL_MODE.EDIT}
                label="Customer"
                placeholder="Select customer"
                value={
                  values?.customer_id
                    ? {
                        label: getCustomerDisplayName({
                          customerName: values?.customer_name,
                          customerId: values?.customer_id,
                        }),
                        value: values?.customer_id,
                      }
                    : null
                }
                name="customer_id"
                handleChange={(option) => {
                  if (option) {
                    setFieldValue('customer_id', option.value);
                    setFieldValue('customer_name', option.label);
                  } else {
                    setFieldValue('customer_id', null);
                    setFieldValue('customer_name', null);
                  }
                }}
                loadOptions={(searchQuery, prevOptions, additional) =>
                  getCustomersFromSearch({ searchQuery, orgId, prevOptions, additional })
                }
                onCreateOption={async (newCustomerName) => {
                  const newCustomer = await createCustomer({
                    orgId,
                    customerName: newCustomerName,
                  });
                  setFieldValue('customer_id', newCustomer.id);
                  setFieldValue('customer_name', newCustomer.name);
                }}
                creatable
                isPaginateable
                isClearable
              />
            </CustomFlexBasis>

            {values?.aggregate_usage_on_parent && (
              <CustomFlexBasis width="75%">
                <FormikCustomSelector
                  isDisabled={!values?.customer_id}
                  label="Child customer(s)"
                  placeholder="+ Customer"
                  name="child_customer_ids"
                  key={`parent_customers_${values?.customer_id}_child_num_${values?.child_customer_ids?.length}`}
                  value={
                    !isEmpty(values?.child_customer_ids)
                      ? values.child_customer_ids.map((id) => ({
                          id: id,
                          value: id,
                          label: selectedChildCustomers[id],
                        }))
                      : initialCustomer
                  }
                  handleChange={(selectedItems) => {
                    const manualAllChild = selectedItems.filter((item) => item.manual);
                    const excludingInitialCustomer = selectedItems.filter((item) => item.value !== -1);
                    if (excludingInitialCustomer.length === 0 || manualAllChild?.length) {
                      return setFieldValue('child_customer_ids', []);
                    }

                    const newSelectedChildCustomers = {};
                    excludingInitialCustomer.forEach((customer) => {
                      newSelectedChildCustomers[customer.id] = customer.label || customer.name;
                    });

                    setSelectedChildCustomers(newSelectedChildCustomers);
                    setFieldValue(
                      'child_customer_ids',
                      excludingInitialCustomer.map((item) => item.id),
                    );
                  }}
                  loadOptions={(searchQuery, prevOptions, additional) =>
                    getCustomersFromSearch({
                      searchQuery,
                      orgId,
                      parentCustomerId: values?.customer_id,
                      prevOptions,
                      additional,
                      customFilter: (customer) => !values?.child_customer_ids?.includes(customer.id.toString()),
                    })
                  }
                  defaultOptions={[{ ...initialCustomer, manual: true }]}
                  onCreateOption={async (newCustomerName) => {
                    const newChildCustomer = await createCustomer({
                      orgId,
                      customerName: newCustomerName,
                      customerParent: values?.customer_id,
                    });

                    const currentChildCustomerIds = values?.child_customer_ids ?? [];
                    const excludingInitialCustomer = currentChildCustomerIds.filter((item) => item.id !== -1);
                    setFieldValue('child_customer_ids', [...excludingInitialCustomer, newChildCustomer.id]);
                    setSelectedChildCustomers({
                      ...selectedChildCustomers,
                      [newChildCustomer.id]: newChildCustomer.name,
                    });
                  }}
                  creatable
                  isPaginateable
                  isMulti
                  boldValue
                  blueVer
                  forceShowBorder
                  customBackgroundColor="#FFFFFF"
                  customBorderRadius={4}
                  CustomDeleteIcon={TrashIcon}
                />
              </CustomFlexBasis>
            )}
          </FormRow>

          {!!values?.previous_subscription_id && (
            <InfoTextWrapper>
              <InfoIcon size="40px" />
              <InfoText>
                The units paid for but not used from the previous subscription will be used as the trial units of this
                subscription. These units will not be invoiced again, but recognized revenue will be calculated at the
                price point from the previous subscription.
              </InfoText>
            </InfoTextWrapper>
          )}

          <Seperator />

          <FormikDiscountSection onDiscountUpdated={onClose} />
        </Wrapper>

        <FormFooter style={{ marginTop: '20px', display: 'inline-table' }}>
          <FlexerRow alignItems="center">
            <StyledPencilIcon />
            <TransactionText>Edit related transaction{existingPrepaidTransaction ? 's' : ''}:</TransactionText>
            <TabsContainer width={existingPrepaidTransaction ? '340px' : '170px'}>
              <Tab active={mainTransactionSelected} onClick={() => setMainTransactionSelected(true)}>
                Main transaction
              </Tab>
              {existingPrepaidTransaction ? (
                <Tab active={!mainTransactionSelected} onClick={() => setMainTransactionSelected(false)}>
                  Prepaid tier transaction
                </Tab>
              ) : null}
            </TabsContainer>
          </FlexerRow>

          <FlexerRow style={{ marginTop: '12px' }} justifyContent="space-between">
            {existingOverageTransaction ? (
              <SubscriptionSubtext>Transaction name</SubscriptionSubtext>
            ) : (
              <SubscriptionSubtext>Subscript will create a new transaction</SubscriptionSubtext>
            )}
            {existingOverageTransaction ? (
              <TransactionLinkWrapper onClick={() => window.open(`/transactions/${selectedTransactionId}`, '_blank')}>
                <span style={{ color: '#0075FF', fontWeight: '700' }}>Open Transaction: #{selectedTransactionId}</span>
                <ExternalLinkIconWrapper>
                  <ExternalLinkIcon />
                </ExternalLinkIconWrapper>
              </TransactionLinkWrapper>
            ) : null}
          </FlexerRow>

          <FlexerRow style={{ marginTop: '8px' }}>
            <FormikCustomInput
              name={mainTransactionSelected ? 'overage_transaction_name' : 'prepaid_transaction_name'}
              width={'100%'}
              data-cy="subscription-create-modal__overage-transaction-name"
            />
          </FlexerRow>

          <Spacer height="16px" />

          <MetadataBlockWrapper>
            <FlexerRow alignItems="center">
              <MetadataBadge>{selectedCombinedMetadataOptions.filter((item) => item.value).length}</MetadataBadge>
              <TransactionText>Transaction Metadata</TransactionText>
              <AddMetadataButton onClick={() => openCreateMetadataModal({ metadataType: METADATA_TYPES.TRANSACTIONS })}>
                Add metadata
              </AddMetadataButton>
            </FlexerRow>
            <div
              style={{
                display: 'grid',
                gridTemplateColumns: 'repeat(3, minmax(0, 1fr))',
                width: '100%',
              }}
            >
              {selectedCombinedMetadataOptions
                .filter((item) => item.value)
                .map((item) => (
                  <MetadataItemFormikInput
                    key={item.key}
                    name={
                      mainTransactionSelected
                        ? `overage_transaction_metadata.${item.key}`
                        : `prepaid_transaction_metadata.${item.key}`
                    }
                    metadataType={METADATA_TYPES.TRANSACTIONS}
                    organizationId={orgId}
                    metadataDataType={item.dataType}
                    onDataChange={(newValue) => {
                      handleFormChange({
                        ...selectedCombinedMetadataOptions.reduce(
                          (obj, item) => ({
                            ...obj,
                            [item.key]: item.value,
                          }),
                          {},
                        ),
                        [item.key]: newValue,
                      });
                    }}
                    item={item}
                    disabled={false}
                  />
                ))}
            </div>
          </MetadataBlockWrapper>
        </FormFooter>

        <FormFooter>
          <FlexEndContainer>
            <CancelButton onClick={onClose}>Cancel</CancelButton>
            <SaveButton
              loading={createUsageSubscription.isLoading || editUsageSubscription.isLoading}
              data-cy="usage-subscription-modal__save-button"
              onClick={handleSubmit}
            />
          </FlexEndContainer>
        </FormFooter>
      </Form>
      <MetadataActionsModal />
    </OverflowDiv>
  );
};
