import React, { useContext, useState, useCallback } from 'react';
import styled from 'styled-components';

import { AppContext } from 'AppContext';
import { EVENTS } from 'consts/analytics';
import { BinIcon } from 'components/Icons';
import { ModalContainer, Modal, ModalFooter, ModalCloseIcon } from 'components/Modal';
import { Spacer, Column, FlexerColumn, FlexEndContainer, Flexer } from 'components/Core';
import { SubscriptionInfo } from './SubscriptionInfo';
import { usePricingPlansAPI } from 'api/usageBasedEngine';
import { useAnalytics } from 'utils/hooks';

const Header = styled.div`
  font-size: 24px;
  line-height: 38px;
  margin-bottom: 20px;

  font-weight: 900;
`;

const Wrapper = styled(FlexerColumn)`
  padding: 0 50px;
`;

const CancelButton = styled.div`
  background: var(--primaryBlack5);
  border: 1px solid var(--primaryBlack5);
  border-radius: 100px;
  margin-right: 14px;
  padding: 11px 14px;
  cursor: pointer;
`;

const RemoveButton = styled.div`
  background: var(--primaryRed);
  display: flex;
  align-items: center;
  border: 1px solid var(--primaryBlack5);
  border-radius: 100px;
  color: #ffffff;
  padding: 8px 14px;
  padding-right: 8px;
  cursor: pointer;
  pointer-events: ${(props) => props.unactive && 'none'};
  opacity: ${(props) => props.unactive && '0.3'};
`;

const FormButtonsRow = styled(FlexEndContainer)``;

const FormFooter = styled(ModalFooter)`
  position: absolute;
  margin-top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
`;

const Banner = styled(Flexer)`
  background-color: var(--primaryRed5);
  border-radius: 8px;
  padding: 16px;
`;

const GreyText = styled.span`
  color: var(--primaryBlack);
  opacity: 0.7;
  font-size: 14px;
  font-weight: 400;
`;

const BoldText = styled.span`
  font-weight: 900;
  font-size: 14px;
`;

const GreyBoldText = styled.span`
  color: var(--primaryBlack);
  opacity: 0.7;

  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
`;

export const DeletePricingPlanModal = ({ onClose, pricingPlan }) => {
  const { orgId } = useContext(AppContext);

  const {
    operations: { deletePricingPlan },
  } = usePricingPlansAPI({
    orgId,
    autoFetch: false,
  });

  return (
    <ModalContainer>
      <Flexer>
        <Modal
          overflow="visible"
          width="440px"
          minHeight="300px"
          data-cy="delete-pricing-plan-modal"
          height="auto"
          maxHeight="auto"
        >
          <>
            <ModalCloseIcon onClose={onClose} />

            <Wrapper>
              <Header data-cy="delete-pricing-plan-modal__title">Remove Pricing Plan</Header>

              <GreyText> Do you really want to remove the following item?</GreyText>
              <GreyText> This cannot be undone </GreyText>

              <Spacer height="8px" />

              <Banner>
                <BinIcon size="20px" />
                <Spacer width="10px" />
                <Column horizontal="start">
                  <BoldText>{pricingPlan.name}</BoldText>

                  <GreyBoldText>{pricingPlan.description}</GreyBoldText>
                </Column>
              </Banner>
            </Wrapper>
            <FormFooter>
              <FormButtonsRow>
                <CancelButton onClick={onClose}>Cancel</CancelButton>
                <RemoveButton
                  onClick={async () => {
                    await deletePricingPlan.mutateAsync({ id: pricingPlan.id });
                    onClose();
                  }}
                  data-cy="delete-pricing-plan-modal__remove-button"
                >
                  Remove
                  <Spacer width="10px" />
                  <BinIcon size="20px" fill="white" />
                  <Spacer width="10px" />
                </RemoveButton>
              </FormButtonsRow>
            </FormFooter>
          </>
        </Modal>

        <SubscriptionInfo pricingPlan={pricingPlan} onClose={onClose} />
      </Flexer>
    </ModalContainer>
  );
};

export const useDeletePricingPlanModal = () => {
  const { trackEvent } = useAnalytics();
  const [openModalProps, setOpenModalProps] = useState({});
  const [showModal, setShowModal] = useState(false);
  const openModal = useCallback(
    (openModalProps = {}) => {
      trackEvent({ name: EVENTS.OPEN_DELETE_PRICING_PLAN_MODAL });
      setOpenModalProps(openModalProps);
      setShowModal(true);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setShowModal],
  );
  const closeModal = useCallback(() => setShowModal(false), [setShowModal]);

  const Modal = useCallback(
    () => (showModal ? <DeletePricingPlanModal {...openModalProps} onClose={closeModal} /> : <></>),
    [closeModal, openModalProps, showModal],
  );

  return {
    openModal,
    DeletePricingPlanModal: Modal,
  };
};
