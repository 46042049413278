import styled from 'styled-components';
import { columnGenerator } from './columnGenerator';
import { HighLightedText } from 'views/Billing/UsageBasedEngine/PricingPlans/styles';
import { FadedText } from 'views/Billing/UsageBasedEngine/Subscriptions/styles';
import { TextBox } from 'components/Core';

const column = columnGenerator({ entityName: 'pricing plans' });

const NameDescriptionWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 5px 0;
  cursor: pointer;
  text-align: left;
`;

const Description = styled(TextBox)`
  width: 100%;
  color: var(--dark100);
  opacity: 0.5;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const NameDescriptionCell = ({ name, description }) => {
  return (
    <NameDescriptionWrapper>
      <TextBox color="var(--blueNormal100)" fontWeight="700">
        {name}
      </TextBox>
      {description && <Description>{description}</Description>}
    </NameDescriptionWrapper>
  );
};

export const generateColumns = ({ onEditClick, numberFormatter }) => {
  const columns = [
    column.header(),
    {
      Header: 'Name / Description',
      accessor: 'name',
      width: 265,
      Cell: ({ row }) => (
        <NameDescriptionCell name={row.original.name} description={row.original.description}></NameDescriptionCell>
      ),
    },
    column.product(),
    {
      Header: 'Event Name',
      accessor: 'event_name',
      width: 265,
      Cell: ({ row }) => <HighLightedText>{row.original.event_name}</HighLightedText>,
    },
    column.billingScheme({ numberFormatter, openEditPricingPlanModal: onEditClick }),
    {
      Header: 'Interval',
      accessor: 'invoicing_frequency',
      id: 'invoicing_frequency',
      width: 100,
      Cell: ({ row }) => <>{row.original.invoicing_frequency}</>,
    },
    {
      Header: 'Currency',
      accessor: 'currency',
      id: 'currency',
      width: 80,
      Cell: ({ row }) => (row.original.currency ? `$ ${row.original.currency}` : <FadedText>Not specified</FadedText>),
      disableSortBy: true,
    },
    column.actions({ onEditClick: onEditClick }),
  ];

  return columns;
};
