import { useEffect, useMemo, useState } from 'react';
import { generateColumns } from './pricingPlanColumns';
import { PreviewTable } from './PreviewTable';
import { useEditPricingPlanModal } from 'views/Billing/UsageBasedEngine/PricingPlans/PricingPlanModal/EditPricingPlanModal';
import { PRICING_PLAN_MODAL_MODE } from 'views/Billing/UsageBasedEngine/PricingPlans/PricingPlanModal/consts';
import { useCurrencyNumberFormatter } from 'utils/hooks';
import { Row, Spacer, TextBox } from 'components/Core';

export const PricingPlansPreviewTable = ({ data = [], setData, setDataHook }) => {
  const [tableData, setTableData] = useState(data);
  const { openModal, EditPricingPlanModal } = useEditPricingPlanModal();

  const numberFormatter = useCurrencyNumberFormatter();

  // for updating isCreated
  useEffect(() => {
    setTableData((prevTableData) =>
      prevTableData.map((value, index) => ({
        ...value,
        isCreated: data[index].isCreated,
      })),
    );
  }, [data]);

  const columns = useMemo(
    () =>
      generateColumns({
        numberFormatter,
        onEditClick: (row) => {
          openModal({
            pricingPlan: row.original,
            savePricingPlan: false,
            mode: PRICING_PLAN_MODAL_MODE.CREATE,
            onPricingPlanCreated: (data) => {
              const tableDataClone = [...tableData];
              tableDataClone[row.index] = {
                ...tableDataClone[row.index],
                ...data,
              };
              delete tableDataClone[row.index].product_confidence;
              setTableData(tableDataClone);
              setData(tableDataClone);
            },
          });
        },
      }),
    [openModal, tableData, numberFormatter, setData],
  );

  return (
    <PreviewTable
      entityName="usage-based pricing plans"
      columns={columns}
      tableData={tableData}
      setDataHook={setDataHook}
      setData={setData}
      title={
        <Row horizontal="flex-start">
          <TextBox bold fontSize={'18px'}>
            Review the
          </TextBox>
          <Spacer width="5px" />
          <TextBox bold italic opacity="0.5" fontSize={'18px'}>
            usage-based pricing plans
          </TextBox>
          <Spacer width="8px" />
          <TextBox bold fontSize={'18px'}>
            that we'll create
          </TextBox>
        </Row>
      }
    >
      <EditPricingPlanModal />
    </PreviewTable>
  );
};
