import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { AppContext } from 'AppContext';
import { useQuery } from 'react-query';
import { ADDITIONAL_ORG_CONFIGS } from 'consts/global';
import { Row, Spacer, TextBox } from 'components/Core';
import { INTEGRATION_TYPES } from 'consts/integrations';
import { getIncomeAccountOptions } from 'api/integrations/requests';
import { useProductActionsModal } from 'shared/ProductActionsModal';
import { SERVICE_WITH_INCOME_ACCOUNT_REF } from 'views/Billing/consts';
import { getServiceCategory } from 'models/integration';
import { generateColumns } from './productColumns';
import { PreviewTable } from './PreviewTable';

export const ProductPreviewTable = ({ data = [], setData, setDataHook }) => {
  const { orgId, orgConfigs, integrations } = useContext(AppContext);
  const [tableData, setTableData] = useState(data);
  const { openModal, ProductActionsModal } = useProductActionsModal();
  const glIntegrations = useMemo(
    () => integrations?.filter((integration) => integration.type === INTEGRATION_TYPES.GL),
    [integrations],
  );
  const crmIntegrations = useMemo(
    () => integrations?.filter((integration) => integration.type === INTEGRATION_TYPES.CRM),
    [integrations],
  );

  const incomeAccountRefIdByIntegrationId = orgConfigs[ADDITIONAL_ORG_CONFIGS.INCOME_ACCOUNT_REF_ID_BY_INTEGRATION_ID];

  // Get account options
  const getIncomeAccountOptionsByIntegrationId = useCallback(async () => {
    const result = {};

    for (const integration of glIntegrations) {
      const incomeAccountOptions = await getIncomeAccountOptions({ orgId, integrationId: integration.id });
      if (incomeAccountOptions) result[integration.id] = incomeAccountOptions;
    }

    return result;
  }, [glIntegrations, orgId]);

  const { data: incomeAccountOptionsByIntegrationId = {} } = useQuery(
    'income-account-options',
    () => getIncomeAccountOptionsByIntegrationId(),
    {
      enabled: glIntegrations.some((integration) =>
        SERVICE_WITH_INCOME_ACCOUNT_REF.includes(getServiceCategory(integration.service)),
      ),
    },
  );

  // for updating isCreated
  useEffect(() => {
    setTableData((prevTableData) =>
      prevTableData.map((value, index) => ({
        ...value,
        isCreated: data[index].isCreated,
      })),
    );
  }, [data]);

  const columns = useMemo(
    () =>
      generateColumns({
        glIntegrations,
        crmIntegrations,
        incomeAccountOptionsByIntegrationId,
        incomeAccountRefIdByIntegrationId,
        onEditClick: (row) => {
          const product = row.original;
          openModal({
            product,
            action: 'create',
            saveProduct: false,
            onClose: (data) => {
              // if data is null, it means the modal was closed on canceled (not submitted)
              if (!data) return;
              const tableDataClone = [...tableData];
              tableDataClone[row.index] = {
                ...tableDataClone[row.index],
                ...data,
                imports: [...(data.glImports ?? []), ...(data.crmImports ?? [])],
              };
              setTableData(tableDataClone);
              setData(tableDataClone);
            },
          });
        },
      }),
    [
      openModal,
      tableData,
      setData,
      glIntegrations,
      crmIntegrations,
      incomeAccountOptionsByIntegrationId,
      incomeAccountRefIdByIntegrationId,
    ],
  );

  return (
    !!data.length && (
      <PreviewTable
        entityName="products"
        columns={columns}
        tableData={tableData}
        setDataHook={setDataHook}
        title={
          <Row horizontal="flex-start">
            <TextBox bold fontSize={'18px'}>
              Confirm the creation of
            </TextBox>
            <Spacer width="5px" />
            <TextBox bold italic fontSize={'18px'} color="var(--dark50)">
              new products
            </TextBox>
          </Row>
        }
      >
        <ProductActionsModal />
      </PreviewTable>
    )
  );
};
