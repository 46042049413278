import React, { useMemo, useState } from 'react';
import { get, unset } from 'lodash';
import { Form, Formik } from 'formik';
import { CsvTable } from './CsvTable';

export const ImportForm = ({
  currentTab,
  mappedData,
  parsedCsv,
  formRef,
  csvColumnsMapper = {},
  entityName,
  setCsvColumnsMapper,
  createOrUpdateMode,
  handleSubmit,
  fuzzyErrors,
  getColumnsWithCsvUploadState,
  defaultMapper,
  isLoading,
  showViewModes,
}) => {
  const csvColumns = Object.keys(parsedCsv?.data?.[0] ?? {}).map((columnKey) => ({
    value: columnKey,
    label: columnKey,
  }));

  const [currentPageIndex, setCurrentPageIndex] = useState(0);

  const columns = useMemo(
    () =>
      getColumnsWithCsvUploadState({
        csvColumnsMapper,
        setCsvColumnsMapper,
        csvColumns,
        fuzzyErrors,
        mappedData,
        createOrUpdateMode,
        formRef,
        defaultMapper,
      }),
    [
      csvColumnsMapper,
      setCsvColumnsMapper,
      csvColumns,
      mappedData,
      formRef,
      fuzzyErrors,
      createOrUpdateMode,
      defaultMapper,
      getColumnsWithCsvUploadState,
    ],
  );

  // We want to clear field error after change value, if there are still an error
  // we will show it after the backend validation
  const clearFieldError = (fieldName) => {
    if (formRef.current?.errors && fieldName) {
      if (get(formRef?.current?.errors, fieldName)) {
        const newErrors = { ...formRef?.current?.errors };
        unset(newErrors, fieldName);
        formRef?.current?.setErrors(newErrors);
      }
    }
  };

  return (
    <Formik
      validateOnChange={false}
      validateOnBlur={false}
      validateOnMount={false}
      enableReinitialize
      onSubmit={handleSubmit}
      initialValues={mappedData}
      innerRef={(formikRef) => {
        formRef.current = formikRef;
        if (formikRef) {
          const originalSetFieldValue = formikRef.setFieldValue;
          formikRef.setFieldValue = (field, value, shouldValidate) => {
            clearFieldError(field);
            return originalSetFieldValue(field, value, shouldValidate);
          };
        }
      }}
    >
      {({ handleChange }) => (
        <Form
          onChange={(e) => {
            handleChange(e);
            clearFieldError(e?.target?.id);
          }}
        >
          <CsvTable
            isLoading={isLoading}
            fuzzyErrors={fuzzyErrors}
            currentTab={currentTab}
            createOrUpdateMode={createOrUpdateMode}
            columns={columns}
            entityName={entityName}
            currentPageIndex={currentPageIndex}
            setCurrentPageIndex={setCurrentPageIndex}
            showViewModes={showViewModes}
          />
        </Form>
      )}
    </Formik>
  );
};
