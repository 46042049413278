import { components, createFilter } from 'react-select';
import dayjs from 'dayjs';
import { unset } from 'lodash';

import { ISO_CODE_TO_SYMBOL } from 'consts/global';
import { CustomDatePicker, FormikCustomInput, FormikCustomSelector } from 'components/Controls';
import { ColumnMapperSelector } from 'shared/CsvUpload/CsvTable/CsvTable';
import { SelectDropdownButton } from 'components/Buttons';
import { Spacer } from 'components/Core';
import {
  HeaderSelector,
  SelectorTitle,
  CustomOption,
  CustomOptionKey,
  CustomOptionValue,
  IdSourceSelectorWrapper,
} from 'shared/CsvUpload/styles';
import { CSV_MODES } from 'shared/CsvUpload/CsvUpload';

import { INTERNAL_CUSTOMER_ID_SOURCE, SOURCE_TYPES, NAME_MATCHING_SOURCE } from './consts';
import { CustomerSelectorCell } from './cells/CustomerSelectorCell';
import { InvoicesSelectorCell } from './cells/InvoicesSelectorCell';
import { InvoicingSchedulesCell } from './cells/InvoicingSchedulesCell';

const { Option } = components;

export const CustomSingleOption = (props) => {
  // optimization for large lists
  delete props.innerProps.onMouseMove;
  delete props.innerProps.onMouseOver;

  return (
    <Option {...props}>
      <CustomOption>
        <CustomOptionKey>{props.data.value}</CustomOptionKey>
        <CustomOptionValue>{props.data.label}</CustomOptionValue>
      </CustomOption>
    </Option>
  );
};

export const SelectDropdownWithTooltip = ({ name, selected, options, onSelect }) => (
  <SelectDropdownButton
    name={name}
    noMargin
    selected={selected}
    options={options}
    fontSize="12px"
    onSelect={onSelect}
    toolTipContent="Click to change type"
    toolTipWidth="125px"
  />
);

export const getColumns = ({
  organization,
  formRef,
  csvColumns,
  csvColumnsMapper,
  setCsvColumnsMapper,

  createOrUpdateMode,
  fuzzyErrors,

  customersIdSources,
  customerIdSourceAndType,
  setCustomerIdSourceAndType,
  uploadCsvCustomersIdSource,
}) => {
  const handleCustomerIdSourceChange = (newValue) => {
    setCustomerIdSourceAndType({
      type: [uploadCsvCustomersIdSource, NAME_MATCHING_SOURCE, INTERNAL_CUSTOMER_ID_SOURCE].includes(newValue)
        ? SOURCE_TYPES.INTERNAL
        : SOURCE_TYPES.EXTERNAL,
      source: newValue,
    });
  };

  return [
    {
      Header: () => <></>,
      width: 30,
      accessor: 'status',
      Cell: () => <></>,
    },
    ...(createOrUpdateMode === CSV_MODES.UPDATE
      ? [
          {
            Header: () => (
              <HeaderSelector>
                <SelectorTitle>Invoice ID</SelectorTitle>
                <ColumnMapperSelector
                  field="id"
                  csvColumnsMapper={csvColumnsMapper}
                  setCsvColumnsMapper={setCsvColumnsMapper}
                  csvColumns={csvColumns}
                />
              </HeaderSelector>
            ),
            width: 180,
            accessor: 'id',
            Cell: ({ row, cell }) => (
              <InvoicesSelectorCell
                cell={cell}
                row={row}
                customerIdSourceAndType={customerIdSourceAndType}
                formRef={formRef}
                CustomSingleOption={CustomSingleOption}
                organization={organization}
              />
            ),
          },
        ]
      : []),
    {
      Header: () => (
        <HeaderSelector>
          <SelectorTitle>Date</SelectorTitle>
          <ColumnMapperSelector
            field="date"
            csvColumnsMapper={csvColumnsMapper}
            setCsvColumnsMapper={setCsvColumnsMapper}
            csvColumns={csvColumns}
          />
        </HeaderSelector>
      ),
      accessor: 'date',
      width: 180,
      Cell: ({ row, cell: { value } }) => (
        <CustomDatePicker
          offsetContainer={false}
          errorWithoutTooltip
          formik
          height="32px"
          onChange={formRef?.current && formRef?.current?.setFieldValue}
          selected={value && dayjs(value).isValid() ? dayjs(value).toDate() : ''}
          meta={formRef?.current && formRef?.current?.getFieldMeta(`[${row.original.rowIndex}].date`)}
          width="100%"
          name={`[${row.original.rowIndex}].date`}
        />
      ),
    },
    {
      Header: () => (
        <HeaderSelector>
          <SelectorTitle>Invoicing Schedule ID</SelectorTitle>
          <ColumnMapperSelector
            field="invoicing_schedule_id"
            csvColumnsMapper={csvColumnsMapper}
            setCsvColumnsMapper={setCsvColumnsMapper}
            csvColumns={csvColumns}
          />
        </HeaderSelector>
      ),
      accessor: 'invoicing_schedule_id',
      width: 180,
      Cell: ({ row, cell }) => (
        <InvoicingSchedulesCell
          cell={cell}
          row={row}
          formRef={formRef}
          CustomSingleOption={CustomSingleOption}
          organization={organization}
        />
      ),
    },
    {
      Header: (props) => (
        <IdSourceSelectorWrapper data-cy="upload-csv__header-selector--customer_id">
          <SelectDropdownWithTooltip
            name="customers-ids-source"
            selected={customerIdSourceAndType?.source}
            options={customersIdSources}
            onSelect={(newValue) => {
              handleCustomerIdSourceChange(newValue);

              // Clear all customer id errors
              const newErrors = { ...(formRef?.current?.errors ?? {}) };
              props?.rows?.forEach((row) => {
                unset(newErrors, `[${row?.original?.rowIndex}].customer_id`);
              });
              formRef?.current?.setErrors(newErrors);
            }}
          />
          <Spacer height="6px" />
          <ColumnMapperSelector
            field="customer_id"
            csvColumnsMapper={csvColumnsMapper}
            setCsvColumnsMapper={setCsvColumnsMapper}
            csvColumns={csvColumns}
          />
        </IdSourceSelectorWrapper>
      ),
      width: 220,
      accessor: 'customer_id',
      Cell: ({ row, cell }) => (
        <CustomerSelectorCell
          cell={cell}
          row={row}
          formRef={formRef}
          fuzzyErrors={fuzzyErrors}
          CustomSingleOption={CustomSingleOption}
          organization={organization}
          customerIdSourceAndType={customerIdSourceAndType}
        />
      ),
    },
    {
      Header: () => (
        <HeaderSelector>
          <SelectorTitle>Service Start (optional)</SelectorTitle>
          <ColumnMapperSelector
            field="service_start"
            csvColumnsMapper={csvColumnsMapper}
            setCsvColumnsMapper={setCsvColumnsMapper}
            csvColumns={csvColumns}
          />
        </HeaderSelector>
      ),
      accessor: 'service_start',
      width: 180,
      Cell: ({ row, cell: { value } }) => (
        <CustomDatePicker
          offsetContainer={false}
          errorWithoutTooltip
          formik
          height="32px"
          onChange={formRef?.current && formRef?.current?.setFieldValue}
          selected={value && dayjs(value).isValid() ? dayjs(value).toDate() : ''}
          meta={formRef?.current && formRef?.current?.getFieldMeta(`[${row.original.rowIndex}].service_start`)}
          width="100%"
          name={`[${row.original.rowIndex}].service_start`}
        />
      ),
    },
    {
      Header: () => (
        <HeaderSelector>
          <SelectorTitle>Service End (optional)</SelectorTitle>
          <ColumnMapperSelector
            field="service_end"
            csvColumnsMapper={csvColumnsMapper}
            setCsvColumnsMapper={setCsvColumnsMapper}
            csvColumns={csvColumns}
          />
        </HeaderSelector>
      ),
      accessor: 'service_end',
      width: 180,
      Cell: ({ row, cell: { value } }) => (
        <CustomDatePicker
          offsetContainer={false}
          errorWithoutTooltip
          formik
          height="32px"
          onChange={formRef?.current && formRef?.current?.setFieldValue}
          selected={value && dayjs(value).isValid() ? dayjs(value).toDate() : ''}
          meta={formRef?.current && formRef?.current?.getFieldMeta(`[${row.original.rowIndex}].service_end`)}
          width="100%"
          name={`[${row.original.rowIndex}].service_end`}
        />
      ),
    },
    {
      Header: () => (
        <HeaderSelector>
          <SelectorTitle>Currency (optional)</SelectorTitle>
          <ColumnMapperSelector
            field="currency"
            csvColumnsMapper={csvColumnsMapper}
            setCsvColumnsMapper={setCsvColumnsMapper}
            csvColumns={csvColumns}
          />
        </HeaderSelector>
      ),
      accessor: 'currency',
      width: 185,
      Cell: ({ row }) => (
        <FormikCustomSelector
          height={32}
          minWidth={165}
          menuWidth={200}
          //optimization thing
          filterOption={createFilter({ ignoreAccents: false })}
          errorWithoutTooltip
          placeholder="Select currency..."
          options={Object.entries(ISO_CODE_TO_SYMBOL).map(([code, symbol]) => ({
            label: `${symbol} (${code})`,
            value: code,
          }))}
          name={`[${row.original.rowIndex}].currency`}
          handleChange={(option) => {
            formRef?.current?.setFieldValue(`[${row.original.rowIndex}].currency`, option.value);
          }}
        />
      ),
    },
    {
      Header: () => (
        <HeaderSelector>
          <SelectorTitle>Invoice Number (optional)</SelectorTitle>
          <ColumnMapperSelector
            field="invoice_number"
            csvColumnsMapper={csvColumnsMapper}
            setCsvColumnsMapper={setCsvColumnsMapper}
            csvColumns={csvColumns}
          />
        </HeaderSelector>
      ),
      accessor: 'invoice_number',
      width: 180,
      Cell: ({ row }) => (
        <FormikCustomInput
          style={{ height: 32 }}
          errorWithoutTooltip
          placeholder="Enter invoice number..."
          width="100%"
          name={`[${row.original.rowIndex}].invoice_number`}
        />
      ),
    },
    {
      Header: () => (
        <HeaderSelector>
          <SelectorTitle>Send Date (optional)</SelectorTitle>
          <ColumnMapperSelector
            field="send_date"
            csvColumnsMapper={csvColumnsMapper}
            setCsvColumnsMapper={setCsvColumnsMapper}
            csvColumns={csvColumns}
          />
        </HeaderSelector>
      ),
      accessor: 'send_date',
      width: 180,
      Cell: ({ row, cell: { value } }) => (
        <CustomDatePicker
          offsetContainer={false}
          errorWithoutTooltip
          formik
          height="32px"
          onChange={formRef?.current && formRef?.current?.setFieldValue}
          selected={value && dayjs(value).isValid() ? dayjs(value).toDate() : ''}
          meta={formRef?.current && formRef?.current?.getFieldMeta(`[${row.original.rowIndex}].send_date`)}
          width="100%"
          name={`[${row.original.rowIndex}].send_date`}
        />
      ),
    },
    {
      Header: () => (
        <HeaderSelector>
          <SelectorTitle>Memo (optional)</SelectorTitle>
          <ColumnMapperSelector
            field="memo"
            csvColumnsMapper={csvColumnsMapper}
            setCsvColumnsMapper={setCsvColumnsMapper}
            csvColumns={csvColumns}
          />
        </HeaderSelector>
      ),
      accessor: 'memo',
      width: 180,
      Cell: ({ row }) => (
        <FormikCustomInput
          style={{ height: 32 }}
          errorWithoutTooltip
          placeholder="Enter memo..."
          width="100%"
          name={`[${row.original.rowIndex}].memo`}
        />
      ),
    },
    {
      Header: () => (
        <HeaderSelector>
          <SelectorTitle>External Notes (optional)</SelectorTitle>
          <ColumnMapperSelector
            field="external_notes"
            csvColumnsMapper={csvColumnsMapper}
            setCsvColumnsMapper={setCsvColumnsMapper}
            csvColumns={csvColumns}
          />
        </HeaderSelector>
      ),
      accessor: 'external_notes',
      width: 180,
      Cell: ({ row }) => (
        <FormikCustomInput
          style={{ height: 32 }}
          errorWithoutTooltip
          placeholder="Enter external notes..."
          width="100%"
          name={`[${row.original.rowIndex}].external_notes`}
        />
      ),
    },
    {
      Header: () => (
        <HeaderSelector>
          <SelectorTitle>Internal Notes (optional)</SelectorTitle>
          <ColumnMapperSelector
            field="internal_notes"
            csvColumnsMapper={csvColumnsMapper}
            setCsvColumnsMapper={setCsvColumnsMapper}
            csvColumns={csvColumns}
          />
        </HeaderSelector>
      ),
      accessor: 'internal_notes',
      width: 180,
      Cell: ({ row }) => (
        <FormikCustomInput
          style={{ height: 32 }}
          errorWithoutTooltip
          placeholder="Enter internal notes..."
          width="100%"
          name={`[${row.original.rowIndex}].internal_notes`}
        />
      ),
    },
    {
      Header: () => (
        <HeaderSelector>
          <SelectorTitle>PO Number (optional)</SelectorTitle>
          <ColumnMapperSelector
            field="po_number"
            csvColumnsMapper={csvColumnsMapper}
            setCsvColumnsMapper={setCsvColumnsMapper}
            csvColumns={csvColumns}
          />
        </HeaderSelector>
      ),
      accessor: 'po_number',
      width: 180,
      Cell: ({ row }) => (
        <FormikCustomInput
          style={{ height: 32 }}
          errorWithoutTooltip
          placeholder="Enter PO number..."
          width="100%"
          name={`[${row.original.rowIndex}].po_number`}
        />
      ),
    },
    {
      Header: () => (
        <HeaderSelector>
          <SelectorTitle>Language (optional)</SelectorTitle>
          <ColumnMapperSelector
            field="language"
            csvColumnsMapper={csvColumnsMapper}
            setCsvColumnsMapper={setCsvColumnsMapper}
            csvColumns={csvColumns}
          />
        </HeaderSelector>
      ),
      accessor: 'language',
      width: 180,
      Cell: ({ row }) => (
        <FormikCustomInput
          style={{ height: 32 }}
          errorWithoutTooltip
          placeholder="Enter language..."
          width="100%"
          name={`[${row.original.rowIndex}].language`}
        />
      ),
    },
    {
      Header: () => (
        <HeaderSelector>
          <SelectorTitle>Invoice Items</SelectorTitle>
          <ColumnMapperSelector
            field="invoice_items"
            csvColumnsMapper={csvColumnsMapper}
            setCsvColumnsMapper={setCsvColumnsMapper}
            csvColumns={csvColumns}
          />
        </HeaderSelector>
      ),
      accessor: 'invoice_items',
      width: 180,
      Cell: ({ row }) => (
        <FormikCustomInput
          style={{ height: 32 }}
          errorWithoutTooltip
          placeholder="Enter invoice items..."
          width="100%"
          name={`[${row.original.rowIndex}].invoice_items`}
        />
      ),
    },
  ];
};
