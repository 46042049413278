import styled from 'styled-components';

import { Spacer, Centerer } from 'components/Core';
import { ReactComponent as ProcessingIcon } from 'images/contracts-processing-icon.svg';
import { ReactComponent as FileAlertIcon } from 'images/contracts-file-alert-icon.svg';
import { ReactComponent as FileCheckIcon } from 'images/contracts-file-check-icon.svg';

import { Wrapper, Line } from './styles';
import { CONTRACT_STATUS } from '../consts';

export const TabButtonWrapper = styled(Centerer)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;

  padding: 12px 20px;

  font-weight: 700;
  font-size: 12px;
  line-height: 16px;

  background-color: ${(props) => (props.active ? '#0075FF1A' : 'transparent')};
  color: ${(props) => (props.active ? '#0075FF' : 'inherit')};

  border-radius: 16px;

  cursor: pointer;

  svg {
    fill: inherit;
  }

  path {
    fill: ${(props) => (props.active ? '#0075FF' : 'inherit')};
  }
`;

export const TabButton = ({ active, icon, children, ...props }) => {
  return (
    <TabButtonWrapper active={active} {...props}>
      {icon}
      <Spacer height="8px" />
      {children}
    </TabButtonWrapper>
  );
};

export const TabSelector = ({ activeTab, setTab }) => {
  return (
    <Wrapper alignItems="center" justifyContent="space-between">
      <TabButton
        onClick={() => setTab(CONTRACT_STATUS.PROCESSING)}
        icon={<ProcessingIcon />}
        active={activeTab === CONTRACT_STATUS.PROCESSING}
        data-cy="contracts-processing-tab"
      >
        In processing
      </TabButton>
      <Line />
      <TabButton
        onClick={() => setTab(CONTRACT_STATUS.NEEDS_REVIEW)}
        icon={<FileAlertIcon />}
        active={activeTab === CONTRACT_STATUS.NEEDS_REVIEW}
        data-cy="contracts-to-be-reviewed-tab"
      >
        Need to be reviewed
      </TabButton>
      <Line />
      <TabButton
        onClick={() => setTab(CONTRACT_STATUS.PARSED)}
        icon={<FileCheckIcon />}
        active={activeTab === CONTRACT_STATUS.PARSED}
        data-cy="contracts-parsed-tab"
      >
        Parsed
      </TabButton>
    </Wrapper>
  );
};
