import { useContext, useState, useEffect } from 'react';
import { useFormikContext } from 'formik';

import { AppContext } from 'AppContext';
import {
  RECOGNITION_TYPES,
  RECOGNITION_TYPES_LABELS,
  ISO_CODE_TO_SYMBOL,
  RECURRING_RECOGNITION_TYPES,
  ACCOUNTING_RECOGNITION_TYPES,
  ACCOUNTING_RECOGNITION_TYPES_DESCRIPTIONS,
  ACCOUNTING_RECOGNITION_TYPES_LABELS,
} from 'consts/global';
import { FlexerColumn } from 'components/Core';
import {
  CustomDatePicker,
  FormikCustomInput,
  FormikCustomSelector,
  FormikSelectorWithDescription,
} from 'components/Controls';
import { TooltipContainer } from 'components/Tooltip';
import { ChargedIcon, EditCircleIcon } from 'components/Icons';
import { Row, Spacer } from 'components/Core';
import { useProductsAPI } from 'api/products';
import { useTillCancelMonthlyIncrementPercentages } from 'api/transactions/hooks';
import { useClickOutside } from 'utils/hooks';
import { formatDateForDatepicker, updateDateFromDatePicker } from 'utils/dateUtils';
import { TransactionContext } from 'shared/TransactionContent/TransactionContext';
import { TOTAL_AMOUNT_METHOD } from 'shared/TransactionContent/consts';
import { ItemsRow } from 'shared/TransactionContent/styles';
import { TRANSACTION_MODAL_MODE } from 'shared/TransactionContent/consts';
import { getOriginalField, SYMBOL_getOriginalField } from 'shared/TransactionContent/utils';

import { TransactionProductField } from '../TransactionProductField';
import { TransactionCustomerField } from '../TransactionCustomerField';
import { RecognitionSelect } from '../TransactionRecognitionSection/RecognitionSelect';
import {
  recurringAmountHandleChange,
  totalAmountHandleChange,
  startDateHandleChange,
  endDateHandleChange,
} from '../utils';
import {
  Container,
  SelectIconContainer,
  ProductRecognitionTooltip,
  ProductTooltipButton,
  EditMonthlyRecurringButton,
} from './styles';
import { TransactionAmountToBill } from './TransactionAmountToBill';
import { TransactionLinkToInvoicingSchedule } from './TransactionLinkToInvoicingSchedule';
import { TransactionAutoIncreaseInput } from '../../TransactionAutoIncrease';

const TOTAL_AMOUNT_METHOD_OPTIONS = [
  {
    value: TOTAL_AMOUNT_METHOD.PREDETERMINED,
    label: 'Predetermined',
    description:
      'You enter the total amount first. You can then choose to enter either the % or the amount for each month, and Subscript will auto-calculate so that the total matches what you entered.',
  },
  {
    value: TOTAL_AMOUNT_METHOD.AUTO_CALCULATED,
    label: 'Auto-calculated',
    description: 'You enter the spread amount for each month. Subscript will auto-calculate the total.',
  },
];

const ACCOUNTING_RECOGNITION_OPTIONS = Object.values(ACCOUNTING_RECOGNITION_TYPES).map((value) => ({
  value,
  label: ACCOUNTING_RECOGNITION_TYPES_LABELS[value],
  description: ACCOUNTING_RECOGNITION_TYPES_DESCRIPTIONS[value],
}));

export const TransactionDetails = () => {
  const {
    appSettings: { currencyISOCode: currency },
    organizations,
    orgId,
  } = useContext(AppContext);
  const {
    mode,
    currentTransaction,
    spreadsFormRef,
    transactionFormValues,
    changedDataFormatted,
    setStoredTotalAmountMethod,
  } = useContext(TransactionContext);
  const { setFieldValue, getFieldMeta } = useFormikContext();

  // used to get the initial incrementPercentagesByDate value
  const { data: incrementPercentagesByDate } = useTillCancelMonthlyIncrementPercentages({
    orgId,
    transactionId: currentTransaction.id,
    usesAutoIncrease: currentTransaction?.use_auto_increase,
    increments: currentTransaction?.auto_increase_increments,
    monthlyAmount: currentTransaction?.recurring_amount || 0,
    endDate: currentTransaction?.end_date,
    autoIncreaseMonths: currentTransaction?.auto_increase_months,
    autoFetch: !!(
      currentTransaction &&
      currentTransaction.use_auto_increase &&
      currentTransaction.auto_increase_increments?.length
    ),
  });
  useEffect(() => {
    setFieldValue('incrementPercentagesByDate', incrementPercentagesByDate ?? {});
  }, [incrementPercentagesByDate, setFieldValue]);

  const {
    operations: { bulkUpdate },
  } = useProductsAPI({ orgId: organizations?.[0].id, autoFetch: false });
  const [showProductRecognitionTooltip, setShowProductRecognitionTooltip] = useState(false);
  const productTooltipRef = useClickOutside(() => setShowProductRecognitionTooltip(false));
  const mrrInputRef = useClickOutside(() => setHighlightMRR(false));

  const currentProduct = organizations?.[0]?.products?.find(
    (product) => product.id === transactionFormValues?.product_id,
  );

  const [disableMRR, setDisableMRR] = useState(false);
  const [highlightMRR, setHighlightMRR] = useState(false);

  useEffect(() => {
    if (transactionFormValues?.recognition && currentProduct && !currentProduct?.recognition) {
      setShowProductRecognitionTooltip(true);
    }

    setDisableMRR(transactionFormValues?.recognition === RECOGNITION_TYPES.linear);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transactionFormValues?.recognition, currentProduct, organizations]);

  const isChanged = (key) =>
    mode === TRANSACTION_MODAL_MODE.EXTERNAL_UPDATE
      ? getOriginalField({ key, data: changedDataFormatted }) !== SYMBOL_getOriginalField
      : undefined;

  const getTooltipLabel = (key) => {
    return mode === TRANSACTION_MODAL_MODE.EXTERNAL_UPDATE && isChanged(key) ? (
      <>
        <div>Old value:</div>
        <div>{getOriginalField({ key, data: changedDataFormatted }) || 'No previous value'}</div>
      </>
    ) : undefined;
  };

  const mainCurrency = transactionFormValues?.currency ?? currency ?? 'USD';
  const currencySymbol = ISO_CODE_TO_SYMBOL[mainCurrency] ?? '$';
  const isTotalAmountAutoCalculated =
    RECOGNITION_TYPES.eventNotRecurring === transactionFormValues?.recognition &&
    TOTAL_AMOUNT_METHOD.AUTO_CALCULATED === transactionFormValues?.total_amount_method;

  return (
    <Container disabled={!!currentTransaction.replaced_by}>
      {mode === TRANSACTION_MODAL_MODE.CREATE && (
        <ItemsRow>
          <FormikCustomInput
            name="name"
            label="Name"
            data-cy="input-name"
            placeholder="Enter name"
            isChanged={
              mode === TRANSACTION_MODAL_MODE.EXTERNAL_UPDATE
                ? getOriginalField({ key: 'name', data: changedDataFormatted }) !== SYMBOL_getOriginalField
                : undefined
            }
            tooltipInputDisplay={
              mode === TRANSACTION_MODAL_MODE.EXTERNAL_UPDATE ? (
                getOriginalField({ key: 'name', data: changedDataFormatted }) !== SYMBOL_getOriginalField ? (
                  <>
                    <div>Old value:</div>{' '}
                    <div> {getOriginalField({ key: 'name', data: changedDataFormatted }) || 'No previous value'} </div>{' '}
                  </>
                ) : null
              ) : undefined
            }
          />

          <FormikCustomSelector
            label="Confirmation"
            options={[
              { label: 'Unconfirmed', value: false },
              { label: 'Confirmed', value: true },
            ]}
            name="confirmed"
          />
        </ItemsRow>
      )}

      <ItemsRow>
        <TransactionCustomerField />
        <TransactionProductField />

        {/* SaaS Rev. Recognition */}
        <div style={{ position: 'relative' }}>
          {isChanged('recognition') ? (
            <TooltipContainer width={150} toolTipContent={getTooltipLabel('recognition')}>
              <SelectIconContainer>
                <RecognitionSelect values={transactionFormValues} setFieldValue={setFieldValue} formik />
                <Spacer width="10px" />
                <ChargedIcon size="16px" />
              </SelectIconContainer>
            </TooltipContainer>
          ) : (
            <RecognitionSelect values={transactionFormValues} setFieldValue={setFieldValue} formik />
          )}

          {showProductRecognitionTooltip && (
            <ProductRecognitionTooltip ref={productTooltipRef}>
              <b>{currentProduct?.name}</b> has no recognition policy. Do you want to update it to{' '}
              <b>{RECOGNITION_TYPES_LABELS[transactionFormValues?.recognition]}</b>?
              <Row style={{ marginTop: 12 }}>
                <ProductTooltipButton onClick={() => setShowProductRecognitionTooltip(false)} noButton>
                  no, thanks
                </ProductTooltipButton>
                <ProductTooltipButton
                  onClick={() => {
                    bulkUpdate.mutateAsync({
                      productIds: [currentProduct.id],
                      data: {
                        recognition: transactionFormValues?.recognition,
                      },
                    });

                    setShowProductRecognitionTooltip(false);
                  }}
                >
                  yes, update
                </ProductTooltipButton>
              </Row>
            </ProductRecognitionTooltip>
          )}
        </div>

        {transactionFormValues?.recognition === RECOGNITION_TYPES.tillCanceled && (
          <div style={{ maxWidth: '125px' }}>
            <TransactionAutoIncreaseInput values={transactionFormValues} currency={mainCurrency} />
          </div>
        )}

        <FormikSelectorWithDescription
          label="Accounting Rev. Recognition"
          placeholder="Select recognition..."
          name="accounting_recognition"
          key="accounting_recognition"
          values={transactionFormValues}
          value={
            transactionFormValues?.accounting_recognition
              ? {
                  value: transactionFormValues.accounting_recognition,
                  label: ACCOUNTING_RECOGNITION_TYPES_LABELS[transactionFormValues.accounting_recognition],
                }
              : {
                  // default value is TRANSACTION
                  value: ACCOUNTING_RECOGNITION_TYPES.TRANSACTION,
                  label: ACCOUNTING_RECOGNITION_TYPES_LABELS[ACCOUNTING_RECOGNITION_TYPES.TRANSACTION],
                }
          }
          options={ACCOUNTING_RECOGNITION_OPTIONS}
        />

        {/* TODO: Add billing invoicing options */}
        {/* <FormikCustomSelector
          label="Billing"
          options={[
            { label: 'Allow invoicing', value: 'Allow invoicing' },
            { label: "Don't allow invoicing", value: "Don't allow invoicing" },
          ]}
          boldValue
          name="allow_invoicing"
          defaultValue="Allow invoicing"
          handleChange={(option) => {
            if (option) setFieldValue('currency', option.value);
            else setFieldValue('currency', null);
          }}
        /> */}
      </ItemsRow>

      <ItemsRow>
        <FormikCustomSelector
          label="Currency"
          options={Object.entries(ISO_CODE_TO_SYMBOL).map(([code, symbol]) => ({
            label: `${symbol} (${code})`,
            value: code,
          }))}
          boldValue
          name="currency"
          handleChange={(option) => {
            if (option) setFieldValue('currency', option.value);
            else setFieldValue('currency', null);
          }}
          isDisabled={!!transactionFormValues?.invoicing_schedule_id}
          tooltipInputDisplay={
            transactionFormValues?.invoicing_schedule_id
              ? 'Transactions that have invoicing schedules cannot change currency. If no invoices have been sent or voided, delete the invoicing schedule first.'
              : null
          }
        />
        <CustomDatePicker
          formik
          label="Booking Date"
          selected={transactionFormValues?.date ? formatDateForDatepicker(transactionFormValues?.date) : null}
          onChange={(name, date) => setFieldValue(name, updateDateFromDatePicker(date))}
          name="date"
          isChanged={
            mode === TRANSACTION_MODAL_MODE.EXTERNAL_UPDATE
              ? getOriginalField({ key: 'date', data: changedDataFormatted }) !== SYMBOL_getOriginalField
              : undefined
          }
          tooltipInputDisplay={
            mode === TRANSACTION_MODAL_MODE.EXTERNAL_UPDATE ? (
              getOriginalField({ key: 'date', data: changedDataFormatted }) !== SYMBOL_getOriginalField ? (
                <>
                  <div>Old value:</div> <div> {getOriginalField({ key: 'date', data: changedDataFormatted })} </div>{' '}
                </>
              ) : null
            ) : undefined
          }
        />
        {transactionFormValues?.recognition !== RECOGNITION_TYPES.eventNotRecurring && (
          <div ref={mrrInputRef} style={{ position: 'relative' }}>
            <FormikCustomInput
              name="recurring_amount"
              data-cy="input-recurring-amount"
              label="Monthly Recurring"
              placeholder={
                RECURRING_RECOGNITION_TYPES.includes(transactionFormValues?.recognition) ? 'Enter amount' : 0
              }
              suffix={currencySymbol}
              type="number"
              isDisabled={!RECURRING_RECOGNITION_TYPES.includes(transactionFormValues?.recognition) || disableMRR}
              handleChange={recurringAmountHandleChange(setFieldValue, transactionFormValues)}
              defaultValue={currentTransaction ? currentTransaction.recurring_amount : ''}
              precision={2}
              isChanged={isChanged('recurring_amount')}
              tooltipInputDisplay={getTooltipLabel('recurring_amount')}
              isOpen={highlightMRR}
              TopRightHeader={
                disableMRR && (
                  <TooltipContainer
                    toolTipContent="This was auto-calculated, but you can manually change it"
                    width={180}
                    fontSize="12px"
                    hideArrow
                  >
                    <EditMonthlyRecurringButton
                      data-cy="input-recurring-amount__edit-button"
                      onClick={() => {
                        setDisableMRR(false);
                        setHighlightMRR(true);
                      }}
                    >
                      <EditCircleIcon />
                    </EditMonthlyRecurringButton>
                  </TooltipContainer>
                )
              }
            />
          </div>
        )}
        {transactionFormValues?.recognition === RECOGNITION_TYPES.eventNotRecurring && (
          <FormikSelectorWithDescription
            name="total_amount_method"
            label="Total Amount"
            options={TOTAL_AMOUNT_METHOD_OPTIONS}
            onSelect={(value) => {
              if (value === TOTAL_AMOUNT_METHOD.AUTO_CALCULATED) {
                const totalValuesAmount = spreadsFormRef?.current?.values?.recognitionEvents?.reduce(
                  (sum, value) => Number(sum) + Number(value?.amount),
                  0,
                );

                setFieldValue('amount', totalValuesAmount);
                for (const [i, recognitionEvent] of spreadsFormRef?.current?.values?.recognitionEvents?.entries()) {
                  spreadsFormRef?.current?.setFieldValue(
                    `recognitionEvents.${i}.percent`,
                    Math.round((recognitionEvent?.amount / totalValuesAmount) * 10000) / 100,
                  );
                }
              }
              setFieldValue('total_amount_method', value);
              setStoredTotalAmountMethod(value);
            }}
          />
        )}
        <FlexerColumn>
          {transactionFormValues?.recognition === RECOGNITION_TYPES.eventNotRecurring && <Spacer height="24px" />}
          <FormikCustomInput
            name="amount"
            data-cy="input-total"
            label={transactionFormValues?.recognition === RECOGNITION_TYPES.eventNotRecurring ? '' : 'Total Amount'}
            placeholder={transactionFormValues?.recognition === RECOGNITION_TYPES.tillCanceled ? 0 : 'Enter total'}
            suffix={currencySymbol}
            type="number"
            isDisabled={
              RECOGNITION_TYPES.tillCanceled === transactionFormValues?.recognition || isTotalAmountAutoCalculated
            }
            handleChange={totalAmountHandleChange(setFieldValue, transactionFormValues)}
            defaultValue={currentTransaction ? currentTransaction.amount : ''}
            precision={2}
            isChanged={isChanged('amount')}
            tooltipInputDisplay={getTooltipLabel('amount')}
          />
        </FlexerColumn>
        <CustomDatePicker
          formik
          label="Start Date"
          selected={
            transactionFormValues?.start_date ? formatDateForDatepicker(transactionFormValues?.start_date) : null
          }
          onChange={startDateHandleChange(setFieldValue, transactionFormValues)}
          name="start_date"
          meta={getFieldMeta('start_date')}
          isChanged={isChanged('start_date')}
          tooltipInputDisplay={getTooltipLabel('start_date')}
        />
        <CustomDatePicker
          formik
          label="End Date"
          selected={transactionFormValues?.end_date ? formatDateForDatepicker(transactionFormValues?.end_date) : null}
          presetStartDate={
            transactionFormValues?.start_date ? formatDateForDatepicker(transactionFormValues?.start_date) : null
          }
          onChange={async (name, date) => {
            await endDateHandleChange(setFieldValue, transactionFormValues)({ name, date, orgId });
          }}
          name="end_date"
          meta={getFieldMeta('end_date')}
          isChanged={isChanged('end_date')}
          tooltipInputDisplay={getTooltipLabel('end_date')}
        />

        {!Boolean(currentTransaction.replaced_by) &&
          (transactionFormValues?.recognition === RECOGNITION_TYPES.linear ||
            transactionFormValues?.recognition === RECOGNITION_TYPES.tillCanceled) && (
            <div data-cy="transaction-form__renewal-deadline-date">
              <CustomDatePicker
                formik
                label="Renewal Deadline"
                data-cy="transaction-form__renewal-deadline-date"
                selected={
                  transactionFormValues?.renewal_deadline
                    ? formatDateForDatepicker(transactionFormValues?.renewal_deadline)
                    : null
                }
                onChange={(name, date) => setFieldValue(name, updateDateFromDatePicker(date))}
                name="renewal_deadline"
                isChanged={isChanged('renewal_deadline')}
                tooltipInputDisplay={getTooltipLabel('renewal_deadline')}
                disabled={!!currentTransaction.replaced_by}
              />
            </div>
          )}

        <FormikCustomInput
          bold
          name="seats"
          label="Seats"
          data-cy="input-seats"
          placeholder="Enter seats"
          min="0"
          type="number"
          isChanged={
            mode === TRANSACTION_MODAL_MODE.EXTERNAL_UPDATE
              ? getOriginalField({ key: 'seats', data: changedDataFormatted }) !== SYMBOL_getOriginalField
              : undefined
          }
          tooltipInputDisplay={
            mode === TRANSACTION_MODAL_MODE.EXTERNAL_UPDATE ? (
              getOriginalField({ key: 'seats', data: changedDataFormatted }) !== SYMBOL_getOriginalField ? (
                <>
                  <div>Old value:</div> <div> {getOriginalField({ key: 'seats', data: changedDataFormatted })} </div>{' '}
                </>
              ) : null
            ) : undefined
          }
        />
      </ItemsRow>

      <ItemsRow>
        {transactionFormValues?.recognition && (
          <TransactionAmountToBill
            recognition={transactionFormValues?.recognition}
            totalAmount={transactionFormValues?.amount}
            currency={transactionFormValues?.currency}
          />
        )}

        <TransactionLinkToInvoicingSchedule
          transaction={transactionFormValues}
          invoicingScheduleId={transactionFormValues?.invoicing_schedule_id}
        />
      </ItemsRow>
    </Container>
  );
};
