import { API_URL } from 'api/consts';
import axios from 'axios';

export const getPricingPlans = async ({ orgId, params }) => {
  const response = await axios.get(`${API_URL}/organizations/${orgId}/pricing-plans`, {
    params,
  });
  return response.data;
};

export const createPricingPlan = async ({ orgId, body }) => {
  const response = await axios.post(`${API_URL}/organizations/${orgId}/pricing-plans`, body);
  return response.data;
};

export const updatePricingPlan = async ({ orgId, body, id }) => {
  const response = await axios.patch(`${API_URL}/organizations/${orgId}/pricing-plans/${id}`, body);
  return response.data;
};

export const deletePricingPlan = async ({ orgId, id }) => {
  const response = await axios.delete(`${API_URL}/organizations/${orgId}/pricing-plans/${id}`);
  return response.data;
};

export const bulkDeletePricingPlans = async ({ orgId, pricingPlanIds }) => {
  const response = await axios.post(`${API_URL}/organizations/${orgId}/pricing-plans/bulk-delete`, {
    pricingPlanIds,
  });
  return response.data;
};

export const bulkCreatePricingPlans = async ({ orgId, body }) => {
  const response = await axios.post(`${API_URL}/organizations/${orgId}/pricing-plans/bulk-create`, body);
  return response.data;
};

// TODO: Make searchable
export const getUsageSubscriptions = async ({ orgId, params }) => {
  const response = await axios.get(`${API_URL}/organizations/${orgId}/usage-subscriptions`, {
    params,
  });
  return response.data;
};

export const getUsageSubscription = async ({ orgId, id, params }) => {
  const response = await axios.get(`${API_URL}/organizations/${orgId}/usage-subscriptions/${id}`, {
    params,
  });
  return response.data;
};

export const recalculateUsageSubscription = async ({ orgId, id }) => {
  const response = await axios.post(`${API_URL}/organizations/${orgId}/usage-subscriptions/${id}/recalculate`);
  return response.data;
};

export const createUsageSubscription = async ({ orgId, body }) => {
  const response = await axios.post(`${API_URL}/organizations/${orgId}/usage-subscriptions`, body);
  return response.data;
};

export const editUsageSubscription = async ({ orgId, subscriptionId, body, params }) => {
  const response = await axios.patch(`${API_URL}/organizations/${orgId}/usage-subscriptions/${subscriptionId}`, body, {
    params,
  });
  return response.data;
};

export const deleteUsageSubscription = async ({ orgId, subscriptionId }) => {
  const response = await axios.delete(`${API_URL}/organizations/${orgId}/usage-subscriptions/${subscriptionId}`);
  return response.data;
};

export const bulkDeleteUsageSubscriptions = async ({ orgId, subscriptionIds }) => {
  const response = await axios.post(`${API_URL}/organizations/${orgId}/usage-subscriptions/bulk-delete`, {
    subscriptionIds,
  });
  return response.data;
};

export const bulkCreateUsageSubscriptions = async ({ orgId, body, params }) => {
  const response = await axios.post(`${API_URL}/organizations/${orgId}/usage-subscriptions/bulk-create`, body, {
    params,
  });
  return response.data;
};

export const getUsageEvents = async ({ orgId, params }) => {
  const response = await axios.get(`${API_URL}/organizations/${orgId}/usage-events`, { params });
  return response.data;
};

export const createUsageEvent = async ({ orgId, body }) => {
  const response = await axios.post(`${API_URL}/organizations/${orgId}/usage-events`, body);
  return response.data;
};

export const bulkCreateUsageEvent = async ({ orgId, body }) => {
  const response = await axios.post(`${API_URL}/organizations/${orgId}/usage-events/bulk`, body);
  return response.data;
};

export const getUniqueEventNames = async ({ orgId }) => {
  const response = await axios.get(`${API_URL}/organizations/${orgId}/unique-event-names`);
  return response.data;
};

export const bulkDeleteUsageEvents = async ({ orgId, filters, data, filtersCount }) => {
  const response = await axios.post(
    `${API_URL}/organizations/${orgId}/usage-events/bulk-delete`,
    {
      data,
      filtersCount,
    },
    { params: { ...filters } },
  );
  return response.data;
};

export const getUniqueEventSources = async ({ orgId }) => {
  const response = await axios.get(`${API_URL}/organizations/${orgId}/unique-event-sources`);
  return response.data;
};

export const evaluatePricingPlanFormula = async ({ orgId, body }) => {
  const response = await axios.post(`${API_URL}/organizations/${orgId}/pricing-plans/evaluate-formula`, body);
  return response.data;
};

export const getDiscounts = async ({ orgId, params }) => {
  const response = await axios.get(`${API_URL}/organizations/${orgId}/discounts`, { params });
  return response.data;
};

export const getDiscount = async ({ orgId, id, params }) => {
  const response = await axios.get(`${API_URL}/organizations/${orgId}/discounts/${id}`, { params });
  return response.data;
};

export const createDiscount = async ({ orgId, body }) => {
  const response = await axios.post(`${API_URL}/organizations/${orgId}/discounts`, body);
  return response.data;
};

export const updateDiscount = async ({ orgId, body, id }) => {
  const response = await axios.patch(`${API_URL}/organizations/${orgId}/discounts/${id}`, body);
  return response.data;
};

export const deleteDiscount = async ({ orgId, id }) => {
  const response = await axios.delete(`${API_URL}/organizations/${orgId}/discounts/${id}`);
  return response.data;
};
