import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { roundToDecimalPlaces } from 'utils/mathUtils';
import { capitalize } from 'utils/stringUtils';
import { SSP_CADENCES } from 'views/Billing/consts';
import { ReactComponent as CalculatorIcon } from 'images/bxs-calculator.svg';
import { FlexerColumn, CentererVertical, Spacer, Divider, Centerer } from 'components/Core';
import { Modal, ModalBody, ModalCloseIcon, ModalContainer, ModalHeader, ModalTitle } from 'components/Modal';
import { SizedLoader } from 'components/Loaders';

const TitleText = styled.span`
  font-size: 20px;
  font-weight: 900;
`;

const TransactionText = styled.span`
  font-size: 12px;
`;

const SubHeader = styled.span`
  font-size: 16px;
  font-weight: 900;
`;

const Bold = styled.span`
  font-weight: 700;
`;

const TotalSSPText = styled.span`
  font-size: 12px;
`;

const LoaderWrapper = styled(Centerer)`
  margin-bottom: 20px;
`;

export const SeeWhyModal = ({
  transactions: _transactions,
  onClose,
  getDefaultContractAllocations,
  selectedTransactionIds = null,
}) => {
  const [allocationDetails, setAllocationDetails] = useState({});

  useEffect(() => {
    const getAllocationDetails = async () => {
      const result = await getDefaultContractAllocations({ transactionIds: selectedTransactionIds });
      setAllocationDetails(result);
    };

    if (getDefaultContractAllocations) {
      getAllocationDetails();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(getDefaultContractAllocations)]);

  const { sspInfoPerTransaction, totalSSP, totalTransactionAmount, anyProductHasSSP } = allocationDetails;

  const transactions = selectedTransactionIds
    ? _transactions.filter((transaction) => selectedTransactionIds.includes(transaction.id))
    : _transactions;

  return (
    <ModalContainer>
      <Modal width="700px" height="auto">
        <ModalCloseIcon onClose={onClose} />
        {Object.keys(allocationDetails).length === 0 ? (
          <LoaderWrapper>
            <SizedLoader size={40} />
          </LoaderWrapper>
        ) : (
          <>
            <ModalHeader>
              <ModalTitle padding="12px 36px">
                <CentererVertical gap="12px">
                  <CalculatorIcon />
                  <TitleText>{`Recognition based on ratios of ${
                    anyProductHasSSP ? 'SSPs' : 'Transaction Amounts'
                  }`}</TitleText>
                </CentererVertical>
              </ModalTitle>
            </ModalHeader>
            <ModalBody paddingLeft="36px" paddingRight="36px">
              <Divider />
              <FlexerColumn gap="16px">
                {anyProductHasSSP && (
                  <>
                    <SubHeader>Standalone selling price(s) of products</SubHeader>
                    {transactions.map((transaction) => (
                      <CentererVertical justifyContent="space-between" key={transaction.id}>
                        <TransactionText>
                          The SSP of product <Bold>{transaction.productName}</Bold> is{' '}
                          <Bold>{transaction.productSSP ?? 0}</Bold> ({capitalize(transaction.sspCadence.toLowerCase())}
                          )
                        </TransactionText>
                      </CentererVertical>
                    ))}
                    <Divider />
                  </>
                )}

                {anyProductHasSSP && (
                  <>
                    <SubHeader>Standalone selling price(s) of transactions</SubHeader>
                    {transactions.map((transaction) => (
                      <CentererVertical justifyContent="space-between" key={transaction.id}>
                        {transaction.sspCadence === SSP_CADENCES.PER_UNIT ? (
                          <TransactionText>
                            The SSP of transaction <Bold>{transaction.name}</Bold> = per_unit_ssp * seats ={' '}
                            {transaction.productSSP} * {transaction?.seats || 1} ={' '}
                            <Bold>{sspInfoPerTransaction[transaction.id].ssp}</Bold>
                          </TransactionText>
                        ) : (
                          <TransactionText>
                            The SSP of transaction <Bold>{transaction.name}</Bold> = monthly_ssp * months in transaction
                            * seats ={' '}
                            {`${roundToDecimalPlaces({
                              number: sspInfoPerTransaction[transaction.id].monthlySSP,
                              decimalPlaces: 2,
                            })} * ${sspInfoPerTransaction[transaction.id].monthsInTransaction} * ${
                              transaction?.seats || 1
                            } = `}
                            <Bold>{sspInfoPerTransaction[transaction.id].ssp}</Bold>
                          </TransactionText>
                        )}
                      </CentererVertical>
                    ))}
                    <Divider />
                  </>
                )}

                {anyProductHasSSP && (
                  <>
                    <SubHeader>Total of SSPs</SubHeader>
                    <TotalSSPText>
                      The total of SSPs is <Bold>{totalSSP}</Bold>
                    </TotalSSPText>
                    <Divider />
                  </>
                )}

                <SubHeader>Allocations</SubHeader>
                {anyProductHasSSP ? (
                  <>
                    {transactions.map((transaction) => (
                      <CentererVertical justifyContent="space-between" key={transaction.id}>
                        <TransactionText>
                          The allocation of <Bold>{transaction.productName}</Bold> for transaction{' '}
                          <Bold>{transaction.name}</Bold> = {sspInfoPerTransaction[transaction.id].ssp} / {totalSSP} ={' '}
                          <Bold>
                            {totalSSP === 0
                              ? 0
                              : roundToDecimalPlaces({
                                  number: sspInfoPerTransaction[transaction.id].allocation * 100,
                                  decimalPlaces: 2,
                                })}
                            %
                          </Bold>
                        </TransactionText>
                      </CentererVertical>
                    ))}
                  </>
                ) : (
                  <>
                    {transactions.map((transaction) => (
                      <CentererVertical justifyContent="space-between" key={transaction.id}>
                        <TransactionText>
                          The allocation for transaction <Bold>{transaction.name}</Bold> is
                          <Bold>
                            {` ${transaction.amount} / ${totalTransactionAmount} = `}
                            {(totalTransactionAmount === 0
                              ? 0
                              : roundToDecimalPlaces({
                                  number: transaction.amount / totalTransactionAmount,
                                  decimalPlaces: 4,
                                })) * 100}
                            %
                          </Bold>
                        </TransactionText>
                      </CentererVertical>
                    ))}
                  </>
                )}
                <Divider />
              </FlexerColumn>
            </ModalBody>
            <Spacer height="36px" />
          </>
        )}
      </Modal>
    </ModalContainer>
  );
};
