import React from 'react';

import { Tooltip } from 'components/Tooltip_NEW';
import { Divider } from 'components/Core/Lines';
import { formatValueForDisplay } from 'utils/formatters';

import { TooltipLabel, TooltipValue, TooltipSection, DisplayValueWrapper } from '../styles';

export const ComparatorDisplay = ({
  isLink,
  onClick,
  isChanged,
  original,
  updated,
  displayOriginal,
  displayUpdated,
  newValueTooltip,
  field,
  overrideOriginalDisplay,
}) => {
  if ((updated !== undefined || ['product_name', 'customer_name'].includes(field)) && isChanged) {
    return (
      <Tooltip
        overlay={
          <>
            <TooltipSection>
              <TooltipLabel> New value: </TooltipLabel>
              <TooltipValue> {newValueTooltip ?? formatValueForDisplay(updated)} </TooltipValue>
            </TooltipSection>
            <Divider />
            {original !== undefined ? (
              <TooltipSection>
                <TooltipLabel> Old value: </TooltipLabel>
                <TooltipValue> {formatValueForDisplay(original)} </TooltipValue>
              </TooltipSection>
            ) : (
              <TooltipLabel> No previous value </TooltipLabel>
            )}
          </>
        }
      >
        <DisplayValueWrapper data-cy={`external-updates__table__cell-${field}`} isLink={isLink} onClick={onClick}>
          {displayUpdated}
        </DisplayValueWrapper>
      </Tooltip>
    );
  } else {
    return (
      <DisplayValueWrapper data-cy={`external-updates__table__cell-${field}`} isLink={isLink} onClick={onClick}>
        {overrideOriginalDisplay ?? displayOriginal}
      </DisplayValueWrapper>
    );
  }
};
