import { FlexerColumn, FlexerRow } from 'components/Core';
import { USAGE_BASED_BILLING_SCHEMES, USAGE_BASED_BILLING_SCHEMES_LABELS } from './consts';
import { isTieredPlan } from './utils';
import { CountTag, StyledLink, SubText } from './styles';
import { NUMBER_FORMATS } from 'consts/global';

export const displayBillingScheme = ({ tiers, currency, numberFormatter, onSeeMoreClick }) => {
  const showTieredDisplay =
    tiers.length === 2
      ? !(
          tiers[0].billing_scheme === USAGE_BASED_BILLING_SCHEMES.PREPAID &&
          tiers[1].billing_scheme === USAGE_BASED_BILLING_SCHEMES.PER_UNIT
        )
      : isTieredPlan({ tiers });
  const perUnitTier = tiers.find(({ billing_scheme }) => billing_scheme === USAGE_BASED_BILLING_SCHEMES.PER_UNIT);
  return (
    <>
      {showTieredDisplay ? (
        <FlexerRow gap="10px" alignItems="center">
          <span
            style={{
              fontWeight: 700,
            }}
          >
            Tiered
          </span>
          <CountTag>{tiers.length}</CountTag>
          <StyledLink onClick={onSeeMoreClick}>See more</StyledLink>
        </FlexerRow>
      ) : (
        perUnitTier && (
          <FlexerColumn gap="5px">
            <span
              style={{
                fontWeight: 700,
              }}
            >
              {`${USAGE_BASED_BILLING_SCHEMES_LABELS[perUnitTier.billing_scheme]} ${numberFormatter({
                type: NUMBER_FORMATS.CURRENCY,
                rawValue: perUnitTier.amount,
                decimalPlaces: 2,
                overrideCurrency: currency,
              })}`}
            </span>

            {tiers[0].billing_scheme === USAGE_BASED_BILLING_SCHEMES.PREPAID && (
              <SubText>
                Minimum amount:{' '}
                {numberFormatter({
                  type: NUMBER_FORMATS.CURRENCY,
                  rawValue: tiers[0].amount,
                  decimalPlaces: 2,
                  overrideCurrency: currency,
                })}
              </SubText>
            )}
          </FlexerColumn>
        )
      )}
    </>
  );
};

export const columnGenerator = {
  billingScheme: ({ numberFormatter, openEditPricingPlanModal }) => ({
    Header: 'Billing Scheme',
    accessor: 'tiers',
    id: 'tiers',
    width: 180,
    Cell: ({ row }) => {
      const tiers = row.original.tiers ?? [];
      return displayBillingScheme({
        tiers,
        currency: row.original.currency,
        numberFormatter,
        onSeeMoreClick: () => {
          openEditPricingPlanModal(row);
        },
      });
    },
    disableSortBy: true,
  }),
};
