import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useToasts } from 'components/Toasts';
import { useDelegatedMutationPolling } from 'api/jobHooks.helper';

import {
  getCSVImports,
  createCSVImport as _createCSVImport,
  deleteCSVImport as _deleteCSVImport,
  uploadCSV as _uploadCSV,
} from './requests';

export const CACHE_KEY = 'csvimports';

export const useCSVImportsAPI = ({ orgId, params, autoFetch = true, enableToasts = true }) => {
  const dataKey = [CACHE_KEY, orgId, JSON.stringify(params)];

  const { data, error, isLoading, isFetching, refetch } = useQuery(
    dataKey,
    async () => getCSVImports({ orgId, params }),
    { enabled: autoFetch },
  );

  const { pushToast: wrappedPushToast, pushError: wrappedPushError } = useToasts();
  const pushToast = (...args) => {
    if (enableToasts) wrappedPushToast(...args);
  };
  const pushError = (...args) => {
    if (enableToasts) wrappedPushError(...args);
  };
  const queryClient = useQueryClient();

  const uploadCSV = useDelegatedMutationPolling({
    mutationFn: ({ rawFile, data, settings }) =>
      _uploadCSV({
        orgId,
        body: {
          rawFile,
          data,
          settings,
        },
      }),
    mutationOptions: {
      onError: (err) => {
        const errorMessage = err.response?.data || 'Failed to upload CSV import.';
        pushError(errorMessage);
      },

      onSuccess: (props, { settings }) => {
        if (Object.keys(props?.errors || {}).length === 1 && props?.errors?.fuzzyErrors) {
          pushToast(
            `Some values were automatically matched. Please review and submit again if correct.`,
            'warning',
            -1,
          );
        } else if (props?.errors) {
          pushError(`Failed to upload CSV import for ${settings.entity}, please check the CSV errors!`, '', -1);
        } else {
          pushToast(`Successfully uploaded CSV import for ${settings.entity}!`, 'success', -1);
        }
      },

      onSettled: () => {
        // Invalidate every single query since we did a large import
        // We could invalidate only the queries that are related to the entity, but this is easier
        // Consider optimising if needed
        queryClient.invalidateQueries();
      },
    },
  });

  const createCSVImport = useMutation(({ data }) => _createCSVImport({ orgId, body: data }), {
    onError: (err) => {
      pushError(err, 'Failed to create CSV import record.');
    },

    onSuccess: () => {
      pushToast('Successfully created CSV import record!', 'success');
    },

    onSettled: () => {
      queryClient.invalidateQueries(CACHE_KEY);
    },
  });

  const deleteCSVImport = useMutation(({ id }) => _deleteCSVImport({ orgId, csvImportId: id }), {
    onError: (err) => {
      pushError(err, 'Failed to delete CSV import record.');
    },

    onSuccess: () => {
      pushToast('Successfully deleted CSV import record!', 'success');
    },

    onSettled: () => {
      queryClient.invalidateQueries(CACHE_KEY);
    },
  });

  return {
    data: data?.data,
    metadata: data?.metadata,
    error,
    isLoading,
    isFetching,
    operations: {
      uploadCSV,
      createCSVImport,
      deleteCSVImport,
      refetch,
    },
  };
};
