import { useCallback, useState } from 'react';
import { ContractFilesModal } from './ContractFilesModal';

export const useContractFilesModal = ({
  transactionIds,
  customerId,
  uploadNew = false,
  readOnly = false,
  onSubmit,
}) => {
  const [showModal, setShowModal] = useState(false);
  const [selectedContracts, setSelectedContracts] = useState([]);

  const openModal = useCallback(
    ({ contracts = [] } = {}) => {
      setShowModal(true);
      setSelectedContracts(contracts);
    },
    [setShowModal],
  );
  const Modal = useCallback(
    () =>
      showModal ? (
        <ContractFilesModal
          selectedContracts={selectedContracts}
          transactionIds={transactionIds}
          customerId={customerId}
          uploadNew={uploadNew}
          readOnly={readOnly}
          onClose={() => setShowModal(false)}
          onSubmit={(data) => {
            setShowModal(false);
            onSubmit(data);
          }}
        />
      ) : null,
    [showModal, setShowModal, transactionIds, customerId, uploadNew, readOnly, onSubmit, selectedContracts],
  );

  return {
    openContractFilesModal: openModal,
    ContractFilesModal: Modal,
    isModalVisible: showModal,
  };
};
