import React from 'react';
import styled from 'styled-components';

export const DottedDivider = () => (
  <div
    style={{
      height: 1,
      backgroundImage: `linear-gradient(to right, #8B8B8B 33%, rgba(255,255,255,0) 0%)`,
      backgroundPosition: 'bottom',
      backgroundSize: '6px 2px',
      backgroundRepeat: 'repeat-x',
      marginTop: '8px',
      marginBottom: '8px',
    }}
  />
);

export const Divider = styled.hr`
  border: none;
  padding: 0.5px;
  margin: 4px 0;
  background-color: ${(props) => props.backgroundColor || 'var(--lightGray)'};
`;

export const VerticalLine = styled.hr`
  border: none;
  width: 1px;
  height: ${({ height }) => height};
  background-color: ${({ color }) => color || 'var(--lightGray)'};
`;

export const Line = styled.div`
  width: 100%;
  height: 1px;
  opacity: 0.03;
  background-color: var(--primaryBlack);
`;
