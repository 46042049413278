import React, { useCallback, useContext, useRef, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import dayjs from 'dayjs';
import { isNil } from 'lodash';

import { AppContext } from 'AppContext';
import { generateInvoicesJSON } from 'api/billing/requests';
import { useClickOutside, useToasts } from 'utils/hooks';
import { MAGIC_METADATA, RECOGNITION_TYPES } from 'consts/global';
import { FlexBetweenContainer, FlexerColumn, Spacer } from 'components/Core';
import { LightStrokeThumbsUpIcon } from 'components/Icons';
import { PopoverListItem } from 'components/Portal';
import { TooltipContainer } from 'components/Tooltip';
import { INVOICING_FREQUENCIES } from 'views/Billing/consts';
import { getSuggestedFrequency } from 'views/Billing/utils';
import { BillingContext } from 'views/Billing/BillingContext';
import { useInvoicingSchedulesAPI } from 'api/billing/hooks';
import { FormikCustomSelector } from 'components/Controls';

import {
  WhiteButtonScheduleHeader,
  ConfirmationText,
  StyledPopoverWrapper,
  StyledPopover,
  FrequencyOption,
  PopoverItemsSection,
  StyledRefreshIcon,
  ConfirmActionTitle,
  ConfirmationPopoverButton,
} from './styles';
import { usePastAndFutureInvoiceOptions } from './PastAndFutureInvoiceOptions';
import { InvoicingScheduleContext } from '../InvoicingScheduleContext';
import { INVOICE_ADDITION_OPTIONS } from '../consts';

export const InvoicingScheduleFrequencyDropdown = ({
  setFieldValue,
  regenerateInvoices,
  includedTransactions,
  invoices,
  buttonText,
  xOffset,
  iconVersion,
  hasImportedInvoices,
  onSelect,
  isRegularSelector,
  regenerateAfterExistingInvoice,
  setRegenerateAfterExistingInvoice,
  ignoreImportInvoiceDates,
  setIgnoreImportInvoiceDates,
  persistedDropdownStateRef,
  showOptionToIgnoreImports,
  regenerateImportedInvoices,
  setRegenerateImportedInvoices,
}) => {
  const generateButtonRef = useRef();

  const { invoicingScheduleFormValues } = useContext(InvoicingScheduleContext);

  const [showDropdown, setShowDropdown] = useState(!!persistedDropdownStateRef?.current);
  const [showConfirmation, setShowConfirmation] = useState(false);

  const frequencyForConfirmationRef = useRef();

  const closeDropdown = () => {
    setShowDropdown(false);
    if (!isNil(persistedDropdownStateRef)) persistedDropdownStateRef.current = false;
  };

  const dropdownRef = useClickOutside(() => {
    closeDropdown();
    setShowConfirmation(false);
  });

  const invoicesCount = invoices?.length ?? 0;
  const hasSentOrVoidedOrPaidInvoices = invoices?.length
    ? invoices?.some((invoice) => invoice.voided_at || invoice.sent_at || invoice.paid_at)
    : false;
  const hasInvoicesInExternalSystem = invoices?.length
    ? invoices?.some((invoice) => invoice.invoice_external_id)
    : false;

  const hasInvociesImportedFromCSV = invoices?.some((invoice) => invoice.metadata?.[MAGIC_METADATA.IS_HISTORICAL]);

  const hasInvoicesToNotRegenerate =
    hasSentOrVoidedOrPaidInvoices || hasInvoicesInExternalSystem || hasInvociesImportedFromCSV;

  const allowPastInvoices =
    !Object.values(invoicingScheduleFormValues?.transaction_options ?? {}).length ||
    Object.values(invoicingScheduleFormValues?.transaction_options ?? {}).some(
      ({ allowPastInvoices }) => allowPastInvoices,
    );
  const onlyFutureInvoices = Object.values(invoicingScheduleFormValues?.transaction_options ?? {}).every(
    ({ onlyFutureInvoices }) => onlyFutureInvoices,
  );

  const onSwitchChange = useCallback(
    ({ allowPastInvoices, onlyFutureInvoices }) => {
      const addType =
        !allowPastInvoices || onlyFutureInvoices
          ? { addType: INVOICE_ADDITION_OPTIONS.NEW, prorateFirstInvoice: false }
          : {};
      setFieldValue(
        'transaction_options',
        Object.entries(invoicingScheduleFormValues?.transaction_options ?? {}).reduce(
          (acc, [key, value]) => ({
            ...acc,
            [key]: {
              ...value,
              ...addType,
              allowPastInvoices,
              onlyFutureInvoices,
            },
          }),
          {},
        ),
      );
    },
    [invoicingScheduleFormValues?.transaction_options, setFieldValue],
  );

  const { PastAndFutureInvoiceOptions, hasInvoicesThatStartInThePast, elementsShown } = usePastAndFutureInvoiceOptions({
    includedTransactions,
    hasInvoicesToNotRegenerate,
    regenerateAfterExistingInvoice,
    setRegenerateAfterExistingInvoice,
    optionValues: { allowPastInvoices, onlyFutureInvoices },
    otherOptions: [
      {
        label: 'Ignore imported invoice dates',
        onChange: () => {
          setIgnoreImportInvoiceDates((prev) => !prev);
        },
        checked: ignoreImportInvoiceDates,
        tooltipInfo: `Subscript by default only generates invoices after the latest imported invoice date (including CSV imports).`,
        name: 'billing__invoice-schedule-ignore-import-invoices',
        show: hasImportedInvoices,
        disabled: regenerateImportedInvoices,
      },
      ...(showOptionToIgnoreImports
        ? [
            {
              label: 'Regenerate imported invoices',
              onChange: () => {
                setRegenerateImportedInvoices((prev) => !prev);
                setIgnoreImportInvoiceDates(true); // this must be true for imported invoices to be regenerated
              },
              checked: regenerateImportedInvoices,
              tooltipInfo: `Subscript by default does not regenerate imported invoices (including CSV imports).`,
              name: 'billing__invoice-schedule-ignore-invoices-with-imports',
              show: hasImportedInvoices,
              disabled: false,
            },
          ]
        : []),
      {
        label: 'Prorate invoices',
        onChange: () => setFieldValue('prorate_invoices', !invoicingScheduleFormValues?.prorate_invoices),
        checked: invoicingScheduleFormValues?.prorate_invoices,
        name: 'prorate_invoices',
        show: true,
        disabled: false,
      },
    ],
    onSwitchChange,
  });
  const showPastAndFuture = (hasInvoicesThatStartInThePast || hasImportedInvoices) && !onSelect;

  const hasTillCanceledTransaction = includedTransactions?.some(
    ({ recognition }) => recognition === RECOGNITION_TYPES.tillCanceled,
  );
  const availableFrequencies = hasTillCanceledTransaction
    ? [
        INVOICING_FREQUENCIES.MONTHLY,
        INVOICING_FREQUENCIES.QUARTERLY,
        INVOICING_FREQUENCIES.SEMI_ANNUALLY,
        INVOICING_FREQUENCIES.ANNUALLY,
      ]
    : Object.values(INVOICING_FREQUENCIES);

  const toggleDropdown = (event) => {
    const buttonRect = generateButtonRef.current.getBoundingClientRect();
    const spaceBelow = window.innerHeight - buttonRect.bottom;

    if (spaceBelow < 350) {
      setDropDownYOffset(
        -39 -
          10 * (elementsShown > 0 && showPastAndFuture) -
          46 * (elementsShown * showPastAndFuture) -
          41 * availableFrequencies.length,
      );
    } else {
      setDropDownYOffset(50);
    }

    event?.preventDefault();
    setShowDropdown(!showDropdown);
    if (!isNil(persistedDropdownStateRef)) persistedDropdownStateRef.current = !showDropdown;
  };
  const [dropDownYOffset, setDropDownYOffset] = useState(50); // by default below

  const handleOnClickFrequency = (frequency) => {
    setFieldValue('invoicing_frequency', frequency);
    closeDropdown();
    if (onSelect) {
      onSelect({ frequency });
    } else {
      regenerateInvoices({
        frequency,
        onlyFutureInvoices,
        allowPastInvoices,
        ignoreImportInvoiceDates,
        regenerateAfterExistingInvoice: !allowPastInvoices && regenerateAfterExistingInvoice,
        hasInvoicesToNotRegenerate,
        regenerateImportedInvoices,
      });
    }
  };

  const suggestedFrequency = getSuggestedFrequency({ transactions: includedTransactions });

  return isRegularSelector ? (
    <>
      <FormikCustomSelector
        name="invoicing_frequency"
        options={availableFrequencies.map((frequency) => ({
          label: `${frequency} ${frequency === suggestedFrequency ? '(recommended)' : ''}`,
          value: frequency,
        }))}
        label="Invoicing frequency"
      />
      {PastAndFutureInvoiceOptions}
    </>
  ) : (
    <>
      <StyledPopoverWrapper ref={dropdownRef}>
        <TooltipContainer
          toolTipContent={
            includedTransactions?.length === 0
              ? 'Select transactions first'
              : invoicesCount > 0
              ? 'This will undo all of your manual changes of the invoices'
              : ''
          }
          width={includedTransactions?.length === 0 ? 180 : 220}
          isVisible={
            hasInvoicesToNotRegenerate || showDropdown ? false : includedTransactions?.length === 0 || invoicesCount > 0
          }
          tooltipWrapperStyles={{
            width: '100%',
          }}
        >
          <WhiteButtonScheduleHeader
            iconVersion={iconVersion}
            data-cy="billing__invoice-schedule-modal__schedule-generate-button"
            onClick={toggleDropdown}
            disabled={includedTransactions?.length === 0 || showConfirmation}
            ref={generateButtonRef}
          >
            {iconVersion ? <StyledRefreshIcon /> : <span>{buttonText ?? 'Regenerate'}</span>}
          </WhiteButtonScheduleHeader>
        </TooltipContainer>
        {showDropdown && (
          <StyledPopover YOffset={dropDownYOffset} XOffset={xOffset ?? 100} width="300px">
            {showPastAndFuture && <PopoverItemsSection>{PastAndFutureInvoiceOptions}</PopoverItemsSection>}

            <PopoverItemsSection>
              {availableFrequencies.map((frequency) => (
                <PopoverListItem
                  data-cy={`billing__invoice-schedule-modal__schedule-generate-button--${frequency}`}
                  key={frequency}
                  onClick={() => {
                    if (hasInvoicesToNotRegenerate) {
                      frequencyForConfirmationRef.current = frequency;
                      closeDropdown();
                      setShowConfirmation(true);
                    } else {
                      handleOnClickFrequency(frequency);
                    }
                  }}
                >
                  <FrequencyOption>
                    <div>{frequency}</div>
                    {frequency === suggestedFrequency ? (
                      <TooltipContainer toolTipContent="We recommend this billing frequency based on the transactions you selected">
                        <LightStrokeThumbsUpIcon size="16px" />
                      </TooltipContainer>
                    ) : null}
                  </FrequencyOption>
                </PopoverListItem>
              ))}
            </PopoverItemsSection>
          </StyledPopover>
        )}
        {showConfirmation && (
          <StyledPopover YOffset={50} XOffset={50} width="220px">
            <FlexerColumn justifyContent="center" alignItems="center">
              <ConfirmActionTitle>CONFIRM ACTION</ConfirmActionTitle>

              <Spacer height="16px" />

              <ConfirmationText fontStyle="italic" fontWeight={700}>
                Do you want to regenerate only{' '}
                <ConfirmationText fontStyle="italic" fontWeight={700} highlight>
                  unsent draft
                </ConfirmationText>{' '}
                invoices?
              </ConfirmationText>

              <Spacer height="4px" />

              <ConfirmationText subText>
                All invoices that have restrictions would stay exactly as they are.
              </ConfirmationText>

              <Spacer height="8px" />

              <FlexBetweenContainer gap="8px">
                <ConfirmationPopoverButton onClick={() => setShowConfirmation(false)}>Cancel</ConfirmationPopoverButton>
                <ConfirmationPopoverButton
                  isConfirmButton={true}
                  onClick={() => handleOnClickFrequency(frequencyForConfirmationRef.current)}
                  data-cy="billing__invoice-schedule-modal__schedule-generate-button--confirm"
                >
                  Confirm
                </ConfirmationPopoverButton>
              </FlexBetweenContainer>
            </FlexerColumn>
          </StyledPopover>
        )}
      </StyledPopoverWrapper>
    </>
  );
};

export const useInvoicingScheduleFrequencyDropdown = ({
  onSelect,
  invoices,
  setFieldValue,
  importedInvoices,
  isRegularSelector,
  showOptionToIgnoreImports,
}) => {
  const { orgId } = useContext(AppContext);
  const {
    invoicingScheduleFormValues,
    fillScheduleWithDraftInvoices,
    setSelectedInvoiceId,
    setFetchedSelectedInvoice,
    setExpectedNumberOfInvoices,
    includedTransactions,
    isScheduleDraft,
    customerDetails,
  } = useContext(InvoicingScheduleContext);

  const { refetchInvoicingScheduleRef } = useContext(BillingContext);

  const {
    operations: { regenerateInvoicingScheduleDraftInvoices },
  } = useInvoicingSchedulesAPI({ orgId, autoFetch: false });

  const { pushError } = useToasts();

  const [loading, setLoading] = useState(false);

  const [generatedInvoicesExplanations, setGeneratedInvoicesExplanations] = useState([]);
  const [regenerateAfterExistingInvoice, setRegenerateAfterExistingInvoice] = useState(false);
  const [ignoreImportInvoiceDates, setIgnoreImportInvoiceDates] = useState(false);
  const [regenerateImportedInvoices, setRegenerateImportedInvoices] = useState(false);

  const persistedDropdownStateRef = useRef(false);

  const allowPastInvoices = Object.values(invoicingScheduleFormValues?.transaction_options ?? {}).every(
    ({ allowPastInvoices }) => allowPastInvoices,
  );

  const regenerateInvoices = useCallback(
    async ({
      frequency,
      allowPastInvoices = false,
      onlyFutureInvoices = false,
      ignoreImportInvoiceDates = false,
      regenerateImportedInvoices = false,
      hasInvoicesToNotRegenerate,
      regenerateAfterExistingInvoice,
    }) => {
      setLoading(true);
      let result;

      try {
        if (hasInvoicesToNotRegenerate && !isScheduleDraft) {
          const updatedInvoicingSchedule = await regenerateInvoicingScheduleDraftInvoices.mutateAsync({
            data: {
              invoicingSchedule: invoicingScheduleFormValues,
              frequency,
              allowPastInvoices,
              onlyFutureInvoices,
              externalInvoices: importedInvoices,
              ignoreImportInvoiceDates,
              currency: invoicingScheduleFormValues?.currency,
              regenerateAfterExistingInvoice,
              regenerateImportedInvoices,
            },
          });
          await refetchInvoicingScheduleRef.current();

          if (!updatedInvoicingSchedule.invoices?.length === 0) pushError('No invoices were generated', 'error');

          setSelectedInvoiceId(updatedInvoicingSchedule.invoices?.[0]?.id);

          result = updatedInvoicingSchedule.invoices;
        } else {
          const { data, invoicingSchedule, metadata } = await generateInvoicesJSON({
            orgId,
            body: {
              customerId:
                invoicingScheduleFormValues?.useParentCustomer && customerDetails?.parent_customer_id
                  ? customerDetails?.parent_customer_id
                  : invoicingScheduleFormValues?.customer_id ?? includedTransactions?.[0]?.customer_id,
              transactionIds: includedTransactions.map(({ id }) => id),
              invoicingSchedule: invoicingScheduleFormValues,
              frequency: frequency ?? invoicingScheduleFormValues?.invoicing_frequency,
              allowPastInvoices,
              todayForClient: dayjs().format('YYYY-MM-DD'),
              currency: invoicingScheduleFormValues?.currency,
              onlyFutureInvoices,
              externalInvoices: importedInvoices,
              ignoreImportInvoiceDates,
              prorateInvoices: invoicingScheduleFormValues?.prorate_invoices,
            },
            params: {
              includeExplanation: true,
            },
          });

          setGeneratedInvoicesExplanations(metadata?.explanations ?? []);

          setExpectedNumberOfInvoices(data?.length);

          if (data?.length === 0) {
            pushError('No invoices were generated', 'error');
          } else {
            const invoices = data.map((rawInvoice) => ({
              ...rawInvoice,
              unsavedId: uuidv4(),
              invoice_items: rawInvoice.invoice_items?.map((item) => ({ id: Math.random() * 100000, ...item })),
            }));

            await fillScheduleWithDraftInvoices({
              invoices,
              invoicingSchedule,
              frequency: frequency ?? invoicingScheduleFormValues?.invoicing_frequency,
              allowPastInvoices,
            });
          }

          setSelectedInvoiceId(null);
          setFetchedSelectedInvoice(null);

          result = data;
        }
      } catch (err) {
        pushError(err, 'Failed to regenerate invoices');
        console.error({ message: err.message, component: 'InvoicingScheduleFrequencyDropdown', stack: err });
      }
      setLoading(false);
      return result;
    },
    [
      isScheduleDraft,
      regenerateInvoicingScheduleDraftInvoices,
      invoicingScheduleFormValues,
      importedInvoices,
      customerDetails,
      refetchInvoicingScheduleRef,
      setSelectedInvoiceId,
      orgId,
      includedTransactions,
      setExpectedNumberOfInvoices,
      fillScheduleWithDraftInvoices,
      setFetchedSelectedInvoice,
      pushError,
    ],
  );

  const DropDown = useCallback(
    ({ buttonText, xOffset, iconVersion }) => (
      <InvoicingScheduleFrequencyDropdown
        buttonText={buttonText}
        xOffset={xOffset}
        iconVersion={iconVersion}
        setFieldValue={setFieldValue}
        regenerateInvoices={regenerateInvoices}
        includedTransactions={includedTransactions}
        invoices={invoices}
        hasImportedInvoices={importedInvoices && importedInvoices.length > 0}
        onSelect={onSelect}
        isRegularSelector={isRegularSelector}
        regenerateAfterExistingInvoice={regenerateAfterExistingInvoice}
        setRegenerateAfterExistingInvoice={setRegenerateAfterExistingInvoice}
        ignoreImportInvoiceDates={ignoreImportInvoiceDates}
        setIgnoreImportInvoiceDates={setIgnoreImportInvoiceDates}
        regenerateImportedInvoices={regenerateImportedInvoices}
        setRegenerateImportedInvoices={setRegenerateImportedInvoices}
        persistedDropdownStateRef={persistedDropdownStateRef}
        showOptionToIgnoreImports={showOptionToIgnoreImports}
      />
    ),
    [
      ignoreImportInvoiceDates,
      importedInvoices,
      includedTransactions,
      invoices,
      isRegularSelector,
      onSelect,
      regenerateAfterExistingInvoice,
      regenerateImportedInvoices,
      regenerateInvoices,
      setFieldValue,
      showOptionToIgnoreImports,
    ],
  );

  return {
    InvoicingScheduleFrequencyDropdown: DropDown,
    isLoading: loading,
    generatedInvoicesExplanations,
    regenerateInvoices,
    ignoreImportInvoiceDates,
    regenerateAfterExistingInvoice: !allowPastInvoices && regenerateAfterExistingInvoice,
  };
};
