import { getInvoices } from 'api/billing/requests';
import { getInvoiceLabel } from 'views/Billing/utils';

export const searchInvoices = async ({ searchQuery, orgId }) => {
  const invoicesList = await getInvoices({
    orgId,
    params: {
      searchQuery,
      limit: 10,
    },
  });

  const invoices = invoicesList.data.map((invoice) => ({
    label: getInvoiceLabel({ invoice }),
    value: invoice?.id,
    allFields: invoice,
  }));

  return invoices;
};
