import styled from 'styled-components';

import { ReactComponent as Icon } from 'images/circle-plus.svg';

export const CirclePlusIcon = styled(Icon)`
  width: ${(props) => props.size};
  height: ${(props) => props.size};
  cursor: ${(props) => props.cursor};
  fill: ${(props) => props.fill ?? 'var(--primaryGreen)'};
  opacity: ${(props) => props.opacity};

  path {
    fill: ${(props) => props.color};
  }
`;
