import { useCallback, useEffect } from 'react';
import { useFormikContext } from 'formik';

// Fixes this issue: https://github.com/jaredpalmer/formik/issues/445
export const FormikPatchTouched = () => {
  const { errors, setFieldTouched, isSubmitting, isValidating } = useFormikContext();
  const touchPath = useCallback(
    (obj, basePath = '') => {
      for (const key in obj) {
        const path = basePath ? `${basePath}.${key}` : key;
        if (typeof obj[key] === 'object' && obj[key] !== null) {
          touchPath(obj[key], path);
        } else {
          setFieldTouched(path, true, false);
        }
      }
    },
    [setFieldTouched],
  );
  useEffect(() => {
    if (isSubmitting && !isValidating) {
      touchPath(errors);
    }
  }, [errors, isSubmitting, isValidating, touchPath]);
  return null;
};
