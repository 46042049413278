import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { AppContext } from 'AppContext';

import { FlexerColumn, NumberDot } from 'components/Core';
import { DoughnutIcon } from 'components/Icons';
import { pluralize } from 'utils/stringUtils';
import {
  SubscriptionsContainer,
  SubscriptionsTitle,
  SubscriptionsTooltipContentText,
  SubscriptionsButton,
  SubscriptionsListItem,
  SubscriptionsAdditionalInfo,
} from './styles';

export const SubscriptionInfo = ({ pricingPlan, onClose }) => {
  const { formatDateWithLocale } = useContext(AppContext);
  const history = useHistory();

  const viewOtherSubscriptions = () => {
    const searchParams = new URLSearchParams({
      tab: 'usage-subscriptions',
      pricingPlanName: pricingPlan.name,
    });

    history.push(`/billing/usage-based-engine?${searchParams}`);
    onClose();
  };

  if (pricingPlan.subscriptions_count > 0) {
    return (
      <SubscriptionsContainer data-cy="pricing-plan-modal__subscriptions-info">
        <NumberDot
          backgroundColor="white"
          color="var(--primaryBlack)"
          data-cy="pricing-plan-modal__subscriptions-info__counter"
        >
          {pricingPlan.subscriptions_count}
        </NumberDot>
        <FlexerColumn>
          <SubscriptionsTitle>{pluralize(pricingPlan.subscriptions_count, 'Subscription', false)}</SubscriptionsTitle>
          attached to this plan:
        </FlexerColumn>
        <FlexerColumn gap="8px" height="112px">
          {pricingPlan.subscriptions.map((subscription) => {
            return (
              <SubscriptionsTooltipContentText data-cy="pricing-plan-modal__subscriptions-info__customer-content">
                <DoughnutIcon />
                <SubscriptionsListItem>
                  <b
                    title={subscription.customer_name}
                    data-cy="pricing-plan-modal__subscriptions-info__customer-content__item"
                  >
                    {subscription.customer_name}
                  </b>
                  <span>
                    ({formatDateWithLocale(subscription.start_date)} -{' '}
                    {!subscription.end_date ? 'No end date' : formatDateWithLocale(subscription.end_date)})
                  </span>
                </SubscriptionsListItem>
              </SubscriptionsTooltipContentText>
            );
          })}
        </FlexerColumn>

        <SubscriptionsButton filled onClick={viewOtherSubscriptions}>
          View All
        </SubscriptionsButton>

        <SubscriptionsAdditionalInfo>
          All unsent invoices of these subscriptions will be updated.
        </SubscriptionsAdditionalInfo>
      </SubscriptionsContainer>
    );
  }

  return <></>;
};
