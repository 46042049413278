import dayjs from 'dayjs';
import { INTEGRATION_TYPES } from 'consts/integrations';
import { SOURCE_TYPES, NAME_MATCHING_SOURCE, INTERNAL_CUSTOMER_ID_SOURCE } from './consts';

export const getInitialIdSource = () => {
  return {
    type: SOURCE_TYPES.INTERNAL,
    source: INTERNAL_CUSTOMER_ID_SOURCE,
  };
};

export const getOrgIdSources = ({ integrations, internalSource, suffix }) => {
  const idSources = {};
  integrations.forEach((integration) => {
    if (![INTEGRATION_TYPES.Analytics, INTEGRATION_TYPES.CSV].includes(integration.type))
      idSources[integration.service] = `${integration.service} ${suffix}`;
  });

  // Add internal sources if organization has them
  if (internalSource) idSources[internalSource] = internalSource;

  return idSources;
};

export const findCustomerSubscriptId = ({ customerId, customerIdSourceAndType }) => {
  if ([INTERNAL_CUSTOMER_ID_SOURCE, NAME_MATCHING_SOURCE].includes(customerIdSourceAndType?.source)) {
    return customerId;
  }
};

export const getInvoicesCellsValue = ({ formRefCurrent, invoice, index, isCustomerNameMatching }) => {
  if (invoice) {
    // Update field names to match invoice schema
    formRefCurrent?.setFieldValue(`[${index}].date`, invoice.date);
    formRefCurrent?.setFieldValue(`[${index}].invoicing_schedule_id`, invoice.invoicing_schedule_id);
    formRefCurrent?.setFieldValue(
      `[${index}].customer_id`,
      isCustomerNameMatching ? invoice.customer_name ?? invoice.customer_id : invoice.customer_id,
    );
    formRefCurrent?.setFieldValue(`[${index}].service_start`, invoice.service_start);
    formRefCurrent?.setFieldValue(`[${index}].service_end`, invoice.service_end);
    formRefCurrent?.setFieldValue(`[${index}].currency`, invoice.currency);
    formRefCurrent?.setFieldValue(`[${index}].metadata`, invoice.metadata);
    formRefCurrent?.setFieldValue(`[${index}].invoice_number`, invoice.invoice_number);
    formRefCurrent?.setFieldValue(`[${index}].send_date`, invoice.send_date);
    formRefCurrent?.setFieldValue(`[${index}].memo`, invoice.memo);
    formRefCurrent?.setFieldValue(`[${index}].secondary_memo`, invoice.secondary_memo);
    formRefCurrent?.setFieldValue(`[${index}].external_notes`, invoice.external_notes);
    formRefCurrent?.setFieldValue(`[${index}].internal_notes`, invoice.internal_notes);
    formRefCurrent?.setFieldValue(`[${index}].po_number`, invoice.po_number);
    formRefCurrent?.setFieldValue(`[${index}].language`, invoice.language);
  }
};

export const getFormFinalInvoices = ({ formInvoices, organizations }) => {
  return formInvoices.map((value) => {
    const finalInvoice = { ...value, organization_id: organizations[0].id };

    // Format dates
    const datesToFormat = ['date', 'service_start', 'service_end', 'send_date'];
    datesToFormat.forEach((dateField) => {
      if (value[dateField]) {
        finalInvoice[dateField] = dayjs(value[dateField]).format('YYYY-MM-DD');
      } else {
        finalInvoice[dateField] = null;
      }
    });

    if (!value.invoice_items) {
      delete finalInvoice.invoice_items;
    }

    return finalInvoice;
  });
};
