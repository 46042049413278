import { useContext, useEffect, useState } from 'react';
import { AppContext } from 'AppContext';
import { getInvoice } from 'api/billing/requests';
import { useInvoicingScheduleAPI } from 'api/billing/hooks';
import { TransactionContext } from 'shared/TransactionContent/TransactionContext';
import { ViewScheduleContainer, HorizontalLine, LinkToInvoicingSchedule } from './styles';

export const TransactionLinkToInvoicingSchedule = ({ transaction, invoicingScheduleId }) => {
  const { orgId } = useContext(AppContext);
  const [scheduleId, setScheduleId] = useState();
  const { openInvoicingScheduleModal, setInvoicingSchedule } = useContext(TransactionContext);
  const { data: invoicingSchedule } = useInvoicingScheduleAPI({
    orgId,
    invoicingScheduleId: scheduleId,
    scopes: ['invoices'],
    autoFetch: !!scheduleId,
  });

  useEffect(() => {
    if (invoicingSchedule) {
      setInvoicingSchedule(invoicingSchedule);
    }
  }, [invoicingSchedule, setInvoicingSchedule]);

  useEffect(() => {
    const creditNoteAllocation = transaction?.credit_note_allocations?.[0];
    const setScheduleIdFromInvoice = async () => {
      const invoice = await getInvoice({ orgId, invoiceId: creditNoteAllocation?.invoice_id });
      setScheduleId(invoice?.data?.invoicing_schedule_id);
    };

    if (invoicingScheduleId) {
      setScheduleId(invoicingScheduleId);
    } else if (creditNoteAllocation) {
      setScheduleIdFromInvoice();
    }
  }, [transaction?.credit_note_allocations, invoicingScheduleId, orgId]);

  return invoicingSchedule?.id ? (
    <ViewScheduleContainer>
      <LinkToInvoicingSchedule
        data-cy="transaction__view-schedule-button"
        onClick={() => openInvoicingScheduleModal({ invoicingSchedule: { id: invoicingSchedule?.id } })}
      >
        View Invoicing Schedule
      </LinkToInvoicingSchedule>
      <HorizontalLine />
      <span>{invoicingSchedule?.invoices?.length ?? 0} invoices</span>
    </ViewScheduleContainer>
  ) : null;
};
