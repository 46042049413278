import React, { createRef, forwardRef, useRef, useState, useContext } from 'react';
import ReactDOM from 'react-dom';
import getYear from 'date-fns/getYear';
import getMonth from 'date-fns/getMonth';
import dayjs from 'dayjs';
import { ReactComponent as TriangleIcon } from 'images/input_error_message_triagle.svg';
import { InputErrorMessage, LabelIconContainer } from 'components/Blocks';
import { TooltipContainer } from 'components/Tooltip';
import { AppContext } from 'AppContext';
import {
  ControlButton,
  CustomHeader,
  DatePickerContainer,
  DatepickerLabel,
  DatePickerLabelContainer,
  DropdownHeadTime,
  DropdownHeadTimeArrow,
  FilterHeadTime,
  HeaderContent,
  StyledDatePicker,
} from './styles';
import { MONTHS } from './consts';
import { Presets } from './Presets';

// This is necessary so that the calendar is not hidden by overflow
const $body = document.body;
const PopperContainer = ({ children }) => ReactDOM.createPortal(children, $body);

// TODO: Rename to DatePicker??
// TODO: [AT 2021-10-26] Support a view prop which can be filters | dropdown | anotherOption
// Not doing that yet because I'm still unsure if we need to customize the input field a lot, in
//  which case it's more flexible to support pre-made customInput separately.
const CustomDatePicker = ({
  dataCy,
  name,
  onChange,
  selected,
  presetStartDate,
  filtersView,
  filtersViewDateFormat,
  filtersViewPrefix,
  dropdownView,
  dateFormat: userDefinedDateFormat,
  showMonthYearPicker,
  popperPlacement,
  formik,
  label,
  isChanged,
  tooltipInputDisplay,
  errorWithoutTooltip,
  toolTipWidth,
  toolTipHideArrow,
  meta,
  height = '38px',
  setDatePickerOpen,
  renderCustomInput,
  floatErrors = false,
  noPortal = false,
  errorBottomPosition,
  backgroundColor,
  hideCalendarIcon,
  calendarIcon,
  disabled,
  white,
  showTimeSelect,
  timeIntervals,
  customPresets,
  children,
  fixedHeight,
  placeholderText = 'Select date...',
}) => {
  const { dateFormat: appContextDateFormat } = useContext(AppContext);
  // covert all 'Y' and 'D' lower case
  const pickerDateFormat = (userDefinedDateFormat ?? appContextDateFormat).replace(/Y/g, 'y').replace(/D/g, 'd');
  const dayjsDateFormat = pickerDateFormat.toUpperCase();

  const [isOpen, setIsOpen] = useState(false);
  const datepickerRef = useRef();
  const inputRef = createRef();

  const FiltersViewInput = forwardRef(({ onClick, value }, inputRef) => (
    <FilterHeadTime
      white={white}
      isChanged={isChanged}
      onClick={onClick}
      value={value}
      onChange={onClick}
      ref={inputRef}
      disabled={disabled}
    >
      {filtersViewPrefix}
      {filtersViewDateFormat
        ? dayjs(value, dayjsDateFormat).format(filtersViewDateFormat) // dayjs doesn't like dd/MM/yyyy (but Date does), so uppercasing it
        : dayjs(value, 'MM/YYYY').format('MMMM YYYY')}
    </FilterHeadTime>
  ));

  const DropdownViewInput = forwardRef(({ onClick, value }, inputRef) => (
    <DropdownHeadTime onClick={onClick} ref={inputRef}>
      {dayjs(value).format('MMMM YYYY')}
      <DropdownHeadTimeArrow />
    </DropdownHeadTime>
  ));

  const handleCalendarClose = () => {
    setIsOpen(false);
    if (setDatePickerOpen) {
      setDatePickerOpen(false);
    }
  };

  const handleCalendarOpen = () => {
    setIsOpen(true);
    if (setDatePickerOpen) {
      setDatePickerOpen(true);
    }
  };

  const handleOnChange = formik
    ? (val) => {
        if (val === null || !isNaN(val.getTime())) {
          onChange(name, val);
        }
      }
    : onChange;

  const handleOptionClick = (value) => {
    handleOnChange(value);
    datepickerRef.current.setOpen(false);
  };

  const DatePickerComponent = (
    <DatePickerContainer data-cy={`custom-date-picker--${dataCy ? dataCy : name}`}>
      <StyledDatePicker
        isChanged={isChanged}
        fixedHeight={fixedHeight}
        isOpen={isOpen}
        isError={meta?.error && meta?.touched}
        popperPlacement={popperPlacement ?? 'bottom'}
        renderCustomHeader={({
          date,
          decreaseMonth,
          increaseMonth,
          decreaseYear,
          increaseYear,
          prevMonthButtonDisabled,
          nextMonthButtonDisabled,
        }) => (
          <CustomHeader showTimeSelect={showTimeSelect}>
            <ControlButton
              onClick={showMonthYearPicker ? decreaseYear : decreaseMonth}
              disabled={prevMonthButtonDisabled}
              type="button"
            >
              {'‹'}
            </ControlButton>

            <HeaderContent>
              {!showMonthYearPicker && `${MONTHS[getMonth(date)]}, `}
              {getYear(date)}
            </HeaderContent>

            <ControlButton
              onClick={showMonthYearPicker ? increaseYear : increaseMonth}
              disabled={nextMonthButtonDisabled}
              type="button"
            >
              {'›'}
            </ControlButton>
          </CustomHeader>
        )}
        id={name}
        placeholderText={placeholderText}
        height={height}
        ref={datepickerRef}
        selected={selected}
        popperContainer={noPortal ? null : PopperContainer}
        showMonthYearPicker={showMonthYearPicker}
        dateFormat={pickerDateFormat && (userDefinedDateFormat || !showMonthYearPicker) ? pickerDateFormat : undefined}
        onChange={handleOnChange}
        customInput={
          filtersView ? (
            <FiltersViewInput ref={inputRef} />
          ) : dropdownView ? (
            <DropdownViewInput ref={inputRef} />
          ) : renderCustomInput ? (
            renderCustomInput({ inputRef })
          ) : undefined
        }
        showWeekNumbers={false}
        onCalendarClose={handleCalendarClose}
        onCalendarOpen={handleCalendarOpen}
        calendarClassName={(presetStartDate || customPresets) && 'react-datepicker__preset'}
        disabled={disabled}
        backgroundColor={backgroundColor}
        hideCalendarIcon={hideCalendarIcon}
        calendarIcon={calendarIcon}
        showTimeSelect={showTimeSelect}
        timeIntervals={timeIntervals}
      >
        {presetStartDate || customPresets ? (
          <Presets
            selected={selected}
            presetStartDate={presetStartDate}
            customPresets={customPresets}
            onOptionClick={handleOptionClick}
          />
        ) : null}
        {children}
      </StyledDatePicker>
    </DatePickerContainer>
  );

  return (
    <DatePickerLabelContainer>
      {label && (
        <LabelIconContainer>
          <DatepickerLabel htmlFor={name}>{label}</DatepickerLabel>
        </LabelIconContainer>
      )}
      {tooltipInputDisplay ? (
        <TooltipContainer toolTipContent={tooltipInputDisplay} width={toolTipWidth} hideArrow={toolTipHideArrow}>
          {DatePickerComponent}
        </TooltipContainer>
      ) : (
        DatePickerComponent
      )}
      {meta?.touched && meta?.error && !errorWithoutTooltip ? (
        <InputErrorMessage errorBottomPosition={errorBottomPosition} floatErrors={floatErrors}>
          <TriangleIcon />
          {meta.error}
        </InputErrorMessage>
      ) : null}
    </DatePickerLabelContainer>
  );
};

export default CustomDatePicker;
