import { useContext, useMemo, useState } from 'react';
import styled from 'styled-components';
import AsyncSelect from 'react-select/async';
import { components } from 'react-select';
import { AppContext } from 'AppContext';
import { selectStyles } from 'components/Controls';
import { StyledButton } from 'components/Buttons/Button';
import { Row, Spacer, TextBox as _TextBox, Divider, Column } from 'components/Core';
import { CUSTOMERS_MODAL_ACTIONS, CustomersActionsModal } from 'views/Customers/CustomersActionsModal';
import { CirclePlusIcon } from 'components/Icons';
import { formatBillingAddress, formatShippingAddress } from 'models/customer';

import { getCustomersFromSearch } from 'shared/TransactionContent/utils';
import { CreateCustomerOptionWrapper } from 'views/Customers/CustomersActionsModal/GLCustomerSelector';

const Wrapper = styled.div`
  display: flex;
  gap: 16px;
  align-items: flex-start;
  flex-direction: column;
`;

const Header = styled(Row)`
  width: 100%;
  justify-content: flex-start;
  gap: 12px;
`;

const Columns = styled.div`
  columns: ${({ columns }) => columns};
`;

const TextBox = styled(_TextBox)`
  font-size: ${({ fontSize }) => fontSize || '12px'};
  color: ${({ color }) => color || 'var(--dark100)'};
`;

const CardContainerWrapper = styled.div`
  position: relative;
  display: flex;
  gap: 12px;
  align-items: stretch;
  flex-direction: column;
  border: 1px solid var(--naturalAdditional3);
  background-color: var(--light100);
  border-radius: 16px;
  padding: 20px;
  width: 100%;
`;

const SelectContainer = styled.div`
  z-index: 20;
  flex: 1;
  text-align: left;
`;

const EditButton = styled(StyledButton)`
  background-color: var(--blueNormal10);
  padding: 4px 8px;
  color: var(--blueNormal100);
  position: absolute;
  top: 16px;
  right: 16px;
  border-radius: 4px;
  font-weight: 700;
`;

const Title = styled(TextBox)`
  opacity: 0.5;
`;

const BackgroundTextBox = styled(TextBox)`
  display: block;
  background-color: ${({ backgroundColor }) => backgroundColor};
  padding: 1px 4px;
  border-radius: 4px;
`;

const CreateCustomerLabel = ({ name }) => {
  return (
    <Row horizontal={'flex-start'} gap={'4px'}>
      <CirclePlusIcon fill="var(--dark100)" opacity="0.5" size={'16px'} />
      <TextBox italic color="var(--dark100)">
        Create a new customer
      </TextBox>
      <BackgroundTextBox italic backgroundColor="var(--naturalAdditional7)" color="var(--light100)">
        {name}
      </BackgroundTextBox>
    </Row>
  );
};

const CreateCustomerOption = ({ name }) => {
  return (
    <Row horizontal={'flex-start'} gap={'4px'}>
      <CirclePlusIcon fill="var(--blueNormal100)" size={'16px'} />
      <BackgroundTextBox fontWeight="700" italic backgroundColor="var(--dark10)" fontSize={'12px'}>
        Create
      </BackgroundTextBox>
      <TextBox fontWeight="700" italic fontSize={'12px'}>
        {`a new customer "${name}"`}
      </TextBox>
    </Row>
  );
};

export const CustomerPreview = ({ newCustomer, onChange, customer }) => {
  const { orgId } = useContext(AppContext);
  const [showCustomersActionModal, setShowCustomersActionModal] = useState(false);
  // new customer doesn't have id yet, so use `-1` as value
  const [createdCustomerId, setCreatedCustomerId] = useState('-1');

  const newCustomerOption = {
    label: <CreateCustomerLabel name={newCustomer.name} />,
    value: createdCustomerId,
    ...newCustomer,
  };
  const selectingNewCustomer = customer?.value === createdCustomerId;

  const loadOptions = async (searchQuery) => {
    const result = await getCustomersFromSearch({ searchQuery, orgId, scopes: ['invoicing_details', 'imports'] });
    return result.options;
  };

  const onCustomerChange = (newCustomer) => {
    onChange({ ...newCustomerOption, ...newCustomer });
  };

  return (
    <Wrapper>
      <Header>
        <TextBox bold fontSize={'18px'}>
          Select the customer of the imported contract:
        </TextBox>
        <SelectContainer>
          <AsyncSelect
            className="react-select"
            classNamePrefix="react-select"
            menuPlacement="auto"
            label="Customer"
            placeholder="Select customer..."
            components={{
              Menu: ({ children, ...props }) => (
                <components.Menu {...props}>
                  {children}
                  {newCustomer.name && (
                    <CreateCustomerOptionWrapper
                      data-cy="custom-select__create-external-customer-option"
                      onClick={() => {
                        props.selectProps?.onMenuClose();
                        setShowCustomersActionModal(true);
                        onChange(newCustomerOption);
                      }}
                    >
                      <CreateCustomerOption name={newCustomer.name} />
                    </CreateCustomerOptionWrapper>
                  )}
                </components.Menu>
              ),
            }}
            cacheOptions
            defaultOptions
            value={customer}
            onChange={onChange}
            loadOptions={loadOptions}
            styles={selectStyles({
              menuWidth: '100%',
              fullWidth: true,
              width: '100%',
              placeholderOpacity: 0.3,
              placeholderFontSize: '12px',
              placeholderColor: 'var(--dark100)',
              fontSize: '12px',
            })}
          />
        </SelectContainer>
      </Header>
      {selectingNewCustomer && (
        <CardContainer
          customer={customer}
          onEditBtnClick={() => {
            setShowCustomersActionModal(true);
          }}
        />
      )}
      {showCustomersActionModal && (
        <CustomersActionsModal
          modalAction={CUSTOMERS_MODAL_ACTIONS.CREATE}
          closeModal={(customer) => {
            setShowCustomersActionModal(false);
            if (customer) {
              setCreatedCustomerId(customer.id);
              onCustomerChange({
                ...customer,
                label: customer.name,
                value: customer.id,
              });
            }
          }}
          customer={customer}
        />
      )}
    </Wrapper>
  );
};

const CardContainer = ({ customer, onEditBtnClick }) => {
  const formattedBillingAddress = formatBillingAddress({ customer });
  const shippingAddress = formatShippingAddress({ customer });
  const formattedShippingAddress =
    !shippingAddress || shippingAddress.trim() === formattedBillingAddress.trim()
      ? 'same as billing address'
      : shippingAddress;
  const metadata = customer?.metadata ?? {};
  const emails = customer?.invoicing_details?.contacts ?? [];

  const quickbooksImport = useMemo(
    () => customer?.imports?.find((customerImport) => customerImport.provider_name === 'quickbooks'),
    [customer],
  );

  const hubspotImport = useMemo(
    () => customer?.imports?.find((customerImport) => customerImport.provider_name === 'hubspot'),
    [customer],
  );

  const stripeImport = useMemo(
    () => customer?.imports?.find((customerImport) => customerImport.provider_name === 'stripe'),
    [customer],
  );

  const glTitle = useMemo(() => {
    const gl = customer?.imports?.find(
      (customerImport) => customerImport.integration_id === customer?.metadata?.selected_gl_integration_id,
    );
    if (gl) {
      return `${gl.provider_name} / ${gl.integration_id}`;
    }
    return null;
  }, [customer]);

  return (
    <CardContainerWrapper>
      <Row horizontal={'flex-start'}>
        <TextBox fontWeight="700" font>
          New customer:
        </TextBox>
        <Spacer width={'4px'} />
        <TextBox fontWeight="700" color="var(--blueNormal100)">
          {customer.name}
        </TextBox>
        {!!emails.length && (
          <>
            <Spacer width={'4px'} />
            <TextBox>{`(${emails.join(', ')})`}</TextBox>
          </>
        )}
      </Row>
      <EditButton filled onClick={onEditBtnClick}>
        Edit
      </EditButton>
      <Columns columns={2}>
        <Row horizontal={'flex-start'}>
          <Title>Subscript Parent:</Title>
          <Spacer width={'4px'} />
          <TextBox>{customer?.parent_customer_id ?? 'No Parent'}</TextBox>
        </Row>
        <Row horizontal={'flex-start'}>
          <Title>Tax ID:</Title>
          <Spacer width={'4px'} />
          <TextBox>{customer?.invoicing_details?.taxID ?? 'No Tax ID'}</TextBox>
        </Row>
      </Columns>
      <Divider backgroundColor="var(--naturalAdditional3)" />
      <Columns columns={'4'}>
        <Column horizontal={'flex-start'} gap={'12px'}>
          <Title>Link with Hubspot</Title>
          <TextBox>{hubspotImport?.metadata?.company_name ?? 'No Link'}</TextBox>
        </Column>
        <Column horizontal={'flex-start'} gap={'12px'}>
          <Title>Links with your General Ledger</Title>
          <TextBox textTransform="capitalize">{metadata.selected_gl_integration_id ? glTitle : 'No Link'}</TextBox>
        </Column>
        <Column horizontal={'flex-start'} gap={'12px'}>
          <Title>Quickbooks 1: Customer</Title>
          <TextBox>{quickbooksImport?.metadata?.customer_name ?? 'No Link'}</TextBox>
        </Column>
        <Column horizontal={'flex-start'} gap={'12px'}>
          <Title>Link with Stripe</Title>
          <TextBox>{stripeImport?.metadata?.customer_name ?? 'No Link'}</TextBox>
        </Column>
      </Columns>
      <Divider backgroundColor="var(--naturalAdditional3)" />
      <Columns columns={'2'}>
        <Column horizontal={'flex-start'} gap={'12px'}>
          <Title>Billing</Title>
          <TextBox>{formattedBillingAddress}</TextBox>
        </Column>
        <Column horizontal={'flex-start'} gap={'12px'}>
          <Title>Shipping</Title>
          <TextBox>{formattedShippingAddress}</TextBox>
        </Column>
      </Columns>
    </CardContainerWrapper>
  );
};
