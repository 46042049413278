import { useState, useEffect, useCallback, useRef } from 'react';
import { debounce } from 'lodash';
import { FormikCustomSelector } from 'components/Controls';
import { searchTransactionsWithExternal } from 'shared/AsyncSearchUtils/searchTransactionsWithExternal';

export const TransactionsSelectorCell = ({
  cell,
  row,
  orgId,
  transactionsIdSourceAndType,
  formRef,
  CustomSingleOption,
}) => {
  const [selectOptions, setSelectOptions] = useState([]);
  const [isFocused, setIsFocused] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const isMountedRef = useRef(true);

  const value = cell?.value;

  // eslint-disable-next-line
  const debouncedSearch = useCallback(
    debounce(async ({ searchQuery }) => {
      try {
        setIsLoading(true);
        const response = await searchTransactionsWithExternal({
          searchQuery,
          orgId,
          transactionsIdSourceAndType,
        });
        if (isMountedRef.current) {
          setSelectOptions(response ?? []);
        }
      } finally {
        if (isMountedRef.current) {
          setIsLoading(false);
        }
      }
    }, 500),
    [orgId],
  );

  useEffect(() => {
    return () => {
      isMountedRef.current = false;
    };
  }, []);

  useEffect(() => {
    if (isFocused && value) {
      debouncedSearch({ searchQuery: value });
    } else {
      setSelectOptions([]);
    }

    // Cancel any ongoing debounced calls when component unmounts or dependencies change
    return () => debouncedSearch.cancel();
  }, [value, orgId, isFocused, debouncedSearch]);

  return (
    <FormikCustomSelector
      isClearable={true}
      height={32}
      useDefaultSelect={true}
      isLoading={isLoading}
      components={{ Option: CustomSingleOption }}
      placeholder="Select id..."
      minWidth={160}
      menuWidth={200}
      errorWithoutTooltip
      value={value ? { value: value, label: value } : null}
      options={selectOptions}
      onFocus={() => setIsFocused(true)}
      onBlur={() => setIsFocused(false)}
      noSort
      handleChange={(option) => {
        if (option) {
          formRef?.current?.setFieldValue(`[${row.original.rowIndex}].transaction_id`, option.value);
        } else {
          formRef?.current?.setFieldValue(`[${row.original.rowIndex}].transaction_id`, null);
        }
      }}
      name={`[${row.original.rowIndex}].transaction_id`}
    />
  );
};
