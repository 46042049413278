import React, { useContext, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { AppContext } from 'AppContext';
import { useExternalCustomerAPI } from 'api/customers/hooks';
import { useImportsAPI } from 'api/imports';

import { humanize } from 'utils/stringUtils';
import { CentererVertical, FlexerColumn, FlexerRow } from 'components/Core';
import { AlertCircleIcon } from 'components/Icons';
import { TooltipContainer, TruncateStringWithTooltip } from 'components/Tooltip';
import { getCustomerDisplayName } from 'models/customer';
import { getServiceCategory } from 'models/integration';
import { Loader } from 'components/Loaders';

import { CustomersActionsModal, CUSTOMERS_MODAL_ACTIONS } from 'views/Customers/CustomersActionsModal';
import { StyledLinkIcon } from '../InvoicingScheduleModal/InvoicingScheduleTabsPanel/InvoicingScheduleHeader/style';

const CustomerExternalSelectorWrapper = styled.div`
  display: flex;
  font-weight: normal;
  white-space: nowrap;
  flex-direction: column;
  width: 100%;
`;

const ExternalServiceRow = styled(FlexerRow)`
  align-items: center;
  justify-content: space-between;
  font-size: 12px;
  padding: 8px;
  padding-top: 0;
`;

const RoundedLabelWrapper = styled.div`
  width: 100%;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  margin-top: 4px;
  line-height: 16px;
  padding: 12px 8px;
  background: ${(props) => (props.warning ? 'var(--primaryYellow15)' : 'var(--primaryBlack2)')};
  color: ${(props) => (props.warning ? 'var(--primaryBlack)' : 'var(--dark7)')};
  border: none;
  border-radius: 8px;
  width: 100%;

  &:hover {
    background: ${(props) => (props.warning ? 'var(--primaryYellow30)' : 'var(--primaryBlack4)')};
  }
`;

const SelectExistingButton = styled.div`
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
  color: var(--primaryBlue);
  cursor: pointer;
  margin-top: 4px;

  &:hover {
    color: var(--secondaryBlue);
  }
`;

const CustomerExternalLinks = ({ invoice, invoicingService, invoicingServiceDisplayName, externalCustomerName }) => {
  const options = useMemo(
    () =>
      invoice?.customer_external?.reduce((acc, curr) => {
        acc.push({
          service: curr.service,
          url: curr.external_url,
        });
        return acc;
      }, []),
    [invoice],
  );

  const isCurrentService = (service) => getServiceCategory(invoicingService) === getServiceCategory(service);

  return options?.length ? (
    <>
      {options.map((option) => (
        <ExternalServiceRow key={option?.service}>
          <b style={{ opacity: 0.5 }}>
            Customer in {isCurrentService(option.service) ? invoicingServiceDisplayName : humanize(option.service)}:
          </b>
          <FlexerRow
            alignItems="center"
            data-cy={
              isCurrentService(option.service)
                ? 'customer-external-selector--selected-name'
                : 'customer-external-selector--name'
            }
            style={{ cursor: 'pointer' }}
            onClick={() => window.open(option.url, '_blank')}
          >
            <b>
              <TruncateStringWithTooltip length={15} tooltipWidth={300}>
                {isCurrentService(option.service) && !!externalCustomerName
                  ? externalCustomerName
                  : getCustomerDisplayName({
                      customerName: invoice?.customer_name,
                      customerId: invoice?.customer_id,
                      customerEmail: invoice?.customer_email,
                    })}
              </TruncateStringWithTooltip>
            </b>
            <StyledLinkIcon />
          </FlexerRow>
        </ExternalServiceRow>
      ))}
    </>
  ) : (
    <></>
  );
};

export const CustomerExternalSelector = ({
  invoice,
  customerId,
  invoicingService,
  invoicingServiceDisplayName,
  onModalClose,
  integrationId,
}) => {
  const { orgId } = useContext(AppContext);
  const [showCustomersActionModal, setShowCustomersActionModal] = useState(false);
  const [externalCustomerName, setExternalCustomerName] = useState('');

  const { data: glImports, isLoading: importIsLoading } = useImportsAPI({
    orgId: orgId,
    filters: {
      integrationIds: integrationId ? [integrationId] : [],
      chifferObjectName: 'customer',
      chifferObjectIds: [customerId],
    },
  });

  const existingGlImport = glImports?.[0] ?? null;

  const { data: externalCustomer, isLoading: externalCustomerIsLoading } = useExternalCustomerAPI({
    orgId,
    externalCustomerId: existingGlImport?.provider_object_id,
    autoFetch: !!existingGlImport && !existingGlImport?.metadata?.customer_name, // we only fetch if we don't already have the customer_name cached
  });

  useEffect(() => {
    if (existingGlImport?.metadata?.customer_name) {
      setExternalCustomerName(
        `${existingGlImport?.metadata?.customer_name || '<No Name>'} / ${existingGlImport.provider_object_id}`,
      );
    } else if (externalCustomer) {
      setExternalCustomerName(`${externalCustomer.name || '<No Name>'} / ${externalCustomer.id || ''}`);
    }
  }, [externalCustomer, existingGlImport]);

  return (
    <CustomerExternalSelectorWrapper>
      {invoice && (
        <CustomerExternalLinks
          invoice={invoice}
          invoicingService={invoicingService}
          externalCustomerName={externalCustomerName}
          invoicingServiceDisplayName={invoicingServiceDisplayName}
        />
      )}
      {importIsLoading || externalCustomerIsLoading ? (
        <Loader containerStyles={{ width: 20, margin: 20 }} />
      ) : existingGlImport ? null : invoicingService ? (
        <CentererVertical width="100%" gap="8px">
          <TooltipContainer
            tooltipWrapperStyles={{ width: '100%' }}
            toolTipContent={`Subscript couldn't find the customer in ${invoicingServiceDisplayName}. Click to select it manually.`}
          >
            <RoundedLabelWrapper
              data-cy="customer-external-selector--edit"
              warning
              onClick={(e) => {
                e.preventDefault();
                setShowCustomersActionModal(true);
              }}
            >
              <FlexerRow gap="8px">
                <AlertCircleIcon size="16px" fill="var(--tertiaryYellow)" />

                <FlexerColumn>
                  {`We'll create a new customer in ${invoicingServiceDisplayName}`}
                  <SelectExistingButton>Select existing one</SelectExistingButton>
                </FlexerColumn>
              </FlexerRow>
            </RoundedLabelWrapper>
          </TooltipContainer>
        </CentererVertical>
      ) : (
        <></>
      )}

      {showCustomersActionModal && (
        <CustomersActionsModal
          closeModal={() => {
            setShowCustomersActionModal(false);
            onModalClose && onModalClose();
          }}
          modalAction={CUSTOMERS_MODAL_ACTIONS.EDIT}
          customer={{ id: customerId }}
        />
      )}
    </CustomerExternalSelectorWrapper>
  );
};
