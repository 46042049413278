import dayjs from 'dayjs';
import { isEmpty, isEqual, identity, isNil } from 'lodash';
import { convertToPlainText } from 'utils/htmlUtils';
import { getOrgConfigs } from 'models/appSettings/base';
import {
  getInvoiceNotSentReminders,
  hasInvoiceFailedToSave,
  hasInvoiceFailedToSend,
  hasInvoicePaymentFailed,
  isInvoiceStatusUnpaid,
} from '../utils';
import {
  INVOICE_STATUSES,
  INVOICE_ITEM_TYPES,
  INVOICE_ITEM_TYPE_TO_KEY,
  TITLE_BY_STATUS,
  INVOICE_MAGIC_METADATA,
} from '../consts';

// TODO: Add a decorator so the backend fills invoice_status like we do when listing invoices
//modal invoice statuses contains REMIND, usual statuses are not
export const getModalInvoiceStatus = ({ invoice, omitReminder }) => {
  if (invoice.metadata?.job_status === 'queued_up') {
    return INVOICE_STATUSES.QUEUED_UP;
  } else if (invoice.metadata?.job_status === 'processing') {
    return INVOICE_STATUSES.PROCESSING;
  } else if (!!invoice.polling_taxes_job_id) {
    return INVOICE_STATUSES.TAXES_PROCESSING;
  } else if (invoice.voided_at) {
    return INVOICE_STATUSES.VOID;
  } else if (invoice.paid_at) {
    return INVOICE_STATUSES.PAID;
  } else if (hasInvoiceFailedToSave({ invoice })) {
    return INVOICE_STATUSES.SAVE_FAILED;
  } else if (hasInvoiceFailedToSend({ invoice })) {
    return INVOICE_STATUSES.SENT_FAILED;
  } else if (hasInvoicePaymentFailed({ invoice })) {
    return INVOICE_STATUSES.PAYMENT_FAILED;
  } else if (!invoice.sent_at) {
    return INVOICE_STATUSES.UNSENT;
  } else if (!invoice.paid_at) {
    return getInvoiceNotSentReminders({ invoice })?.length && !omitReminder
      ? INVOICE_STATUSES.REMIND
      : INVOICE_STATUSES.SENT;
  }
};

export const getModalInvoiceTitle = ({ invoice, isScheduleAutoCharge, omitReminder }) => {
  const invoiceStatus = getModalInvoiceStatus({
    invoice,
    omitReminder: invoice.auto_charge || omitReminder,
  });

  if (![INVOICE_STATUSES.UNSENT, INVOICE_STATUSES.SENT].includes(invoiceStatus) || !isScheduleAutoCharge) {
    return TITLE_BY_STATUS[invoiceStatus];
  }

  const isDraft = Boolean(invoice?.unsavedId);
  const disableAutoCharge = invoice?.metadata?.[INVOICE_MAGIC_METADATA.DISABLE_AUTO_CHARGE];

  if (isDraft) {
    if (isNil(disableAutoCharge)) {
      const dateToCheck = dayjs.utc(invoice?.auto_charge_date ?? invoice?.date);

      return dateToCheck.isBefore(dayjs.utc(), 'day')
        ? TITLE_BY_STATUS[INVOICE_STATUSES.UNPAID]
        : TITLE_BY_STATUS[INVOICE_STATUSES.AUTO_CHARGE];
    }

    return disableAutoCharge !== true
      ? TITLE_BY_STATUS[INVOICE_STATUSES.AUTO_CHARGE]
      : TITLE_BY_STATUS[INVOICE_STATUSES.UNPAID];
  }

  if (disableAutoCharge !== true) {
    return TITLE_BY_STATUS[INVOICE_STATUSES.AUTO_CHARGE];
  }

  if (
    isInvoiceStatusUnpaid({
      isScheduleAutoCharge,
      invoice,
    })
  ) {
    return TITLE_BY_STATUS[INVOICE_STATUSES.UNPAID];
  }

  return TITLE_BY_STATUS[invoiceStatus];
};

export const calculateAmountFromPercent = ({ percent, total }) => (percent / 100) * total;

export const calculatePercentFromAmount = ({ amount, total }) => (amount / total) * 100;

export const getInvoiceData = (values) => {
  const invoiceData = {
    ...global.structuredClone(values),
  };

  // Conditionally add invoice_number if it exists and is not empty
  if (values?.invoice_number && values?.invoice_number !== '') {
    invoiceData.invoice_number = values.invoice_number;
  }

  // Conditionally add memo, email_subject, email_body if they exist
  if (values?.memo) {
    invoiceData.memo = convertToPlainText(values.memo);
  }
  if (values?.secondary_memo) {
    invoiceData.secondary_memo = convertToPlainText(values.secondary_memo);
  }
  if (values?.email_subject) {
    invoiceData.email_subject = convertToPlainText(values.email_subject);
  }
  if (values?.email_body) {
    invoiceData.email_body = convertToPlainText(values.email_body);
  }

  if (
    values?.email_addresses &&
    Array.isArray(values.email_addresses) &&
    values.email_addresses.filter(Boolean).length > 0
  ) {
    invoiceData.email_addresses = values.email_addresses.filter(Boolean);
  }

  for (const [type, key] of Object.entries(INVOICE_ITEM_TYPE_TO_KEY)) {
    if (invoiceData[key]) {
      const invoiceField = invoiceData[key];
      delete invoiceData[key];

      invoiceData.invoice_items = invoiceData.invoice_items ?? [];

      invoiceData.invoice_items.push({
        ...invoiceField,
        type,
      });
    }
  }

  return invoiceData;
};

export const getInvoiceInitialValues = (invoice) => {
  const initialValues = global.structuredClone(invoice);
  const orgConfigs = getOrgConfigs();

  const { billingInvoiceDefaults } = orgConfigs;
  const { billingSenderDefaults } = orgConfigs;

  let calculatedAmount = 0;

  // Clean up any potentially bad email data from API
  if (initialValues) {
    initialValues.email_addresses = initialValues?.email_addresses
      ? (Array.isArray(initialValues.email_addresses)
          ? initialValues.email_addresses
          : [initialValues.email_addresses]
        )?.filter((e) => typeof e === 'string')
      : [];
    initialValues.email_addresses_from_customer = !!initialValues?.email_addresses_from_customer;
    initialValues.email_cc = initialValues?.email_cc
      ? (Array.isArray(initialValues.email_cc) ? initialValues.email_cc : [initialValues.email_cc])?.filter(
          (e) => typeof e === 'string',
        )
      : billingInvoiceDefaults?.email_CC ?? [];
    initialValues.email_bcc = initialValues?.email_bcc
      ? (Array.isArray(initialValues.email_bcc) ? initialValues.email_bcc : [initialValues.email_bcc])?.filter(
          (e) => typeof e === 'string',
        )
      : billingInvoiceDefaults?.email_BCC ?? [];
    initialValues.invoice_number = initialValues?.invoice_number ?? '';
    initialValues.po_number = initialValues.po_number ?? '';

    // Base amount is sum of all invoice items, excluding those of FEE and TAXES type
    initialValues.base_amount = initialValues?.invoice_items?.reduce((acc, item) => {
      if (!Object.values(INVOICE_ITEM_TYPES).includes(item.type)) return acc + item.amount;
      return acc;
    }, 0);

    calculatedAmount = initialValues.base_amount;
  }

  for (const [type, key] of Object.entries(INVOICE_ITEM_TYPE_TO_KEY)) {
    const indexOfItem = initialValues?.invoice_items?.findIndex((invoiceItem) => invoiceItem.type === type);
    if (indexOfItem !== -1) {
      initialValues[key] = initialValues?.invoice_items?.splice(indexOfItem, 1)[0]; // remove the invoice item from the array and put it in `key` field
    }

    if (initialValues[key]) {
      initialValues[key].percent = calculatePercentFromAmount({
        amount: initialValues[key].amount ?? 0,
        total: initialValues.base_amount,
      });

      calculatedAmount += initialValues[key].amount;
    }
  }

  initialValues.amount = calculatedAmount - (invoice.credit_notes ?? []).reduce((acc, curr) => acc + curr.amount, 0);

  if (initialValues?.grouping) {
    initialValues.grouping = initialValues.grouping.map((group) => ({
      ...group,
      invoice_items_ids: group.invoice_items_ids.map((id) => id),
    }));
  }

  initialValues.metadata = initialValues.metadata ?? {};
  initialValues.metadata[INVOICE_MAGIC_METADATA.SHOW_SHIPPING_ADDRESS] =
    initialValues.paid_at || initialValues.sent_at || initialValues.voided_at
      ? initialValues.metadata[INVOICE_MAGIC_METADATA.SHOW_SHIPPING_ADDRESS] ?? false
      : initialValues.metadata[INVOICE_MAGIC_METADATA.SHOW_SHIPPING_ADDRESS] ??
        billingSenderDefaults?.show_shipping_address;

  return initialValues;
};

const normalizeDate = (val) => (dayjs(val).isValid() ? dayjs.utc(val).format('YYYY-MM-DD') : null);

const MAPPING_FUNCTIONS = {
  date: normalizeDate,
  memo: convertToPlainText,
};

// This was adapted from generateChanges in src/views/ExternalUpdates/utils.js
export const invoiceGenerateChanges = ({ source, target, fields }) => {
  const sourceWithTaxesAndFeesParsed = getInvoiceInitialValues(source);
  const changes = fields.reduce((acc, field) => {
    const mapFunc = MAPPING_FUNCTIONS[field] ?? identity;
    const mappedSourceValue = mapFunc(sourceWithTaxesAndFeesParsed[field]);
    const mappedTargetValue = mapFunc(target[field]);

    if (!isEqual(mappedSourceValue, mappedTargetValue)) {
      acc[field] = target[field];
    }
    return acc;
  }, {});

  if (changes.grouping && !changes.invoice_items) {
    changes.invoice_items = target.invoice_items;
  }

  if (!changes.grouping && changes.invoice_items) {
    changes.grouping = target.grouping;
  }

  return changes;
};

export const invoiceWasEdited = ({ source, target, fields }) => {
  const changedFields = invoiceGenerateChanges({
    source,
    target,
    fields,
  });
  return !isEmpty(changedFields);
};

export const getTransactionIdFromInvoice = (invoice) => {
  const transactionIds = new Set();
  for (const { transaction_id: transactionId } of invoice.invoice_items ?? []) {
    if (transactionId) transactionIds.add(transactionId);
  }
  return Array.from(transactionIds);
};
