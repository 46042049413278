import React from 'react';
import styled from 'styled-components';
import dayjs from 'dayjs';
import { Formik } from 'formik';
import { useAccountingSpreadsForContractAPI } from 'api/accountingRevenue/hooks';
import { FlexEndContainer, Spacer, FlexerColumn, CentererVertical } from 'components/Core';
import { FormikCustomInput } from 'components/Controls';
import {
  Modal,
  ModalBody,
  ModalButton,
  ModalCloseIcon,
  ModalContainer,
  ModalFooter,
  ModalHeader,
  ModalTitle,
  ModalTitleText,
} from 'components/Modal';
import { displayAccountingLockMessage } from 'models/accountingSpreads';

const LabelText = styled.span`
  font-size: 14px;
  font-weight: 700;
`;

const MonthKeyText = styled.span`
  font-size: 12px;
  font-weight: 700;
`;

const ProductName = styled.span`
  font-size: 12px;
  font-weight: 300;
  color: var(--primaryBlack70);
`;

const ResetButton = styled.span`
  width: fit-content;
  height: fit-content;
  padding: 4px 8px;
  border-radius: 4px;
  background: var(--primaryBlue10);
  color: var(--primaryBlue);
  font-size: 12px;
  font-weight: 700;
  cursor: pointer;
`;

const AccountingSpreadsTable = styled.table`
  padding-right: 16px;

  td {
    padding: 4px;
  }
`;

const formatDataForForm = ({ transactionIds, monthKeys, data }) => {
  const result = { accountingSpreads: {} };
  for (const monthKey of monthKeys) {
    for (const transactionId of transactionIds) {
      result.accountingSpreads[transactionId] = result.accountingSpreads[transactionId] ?? {};
      result.accountingSpreads[transactionId][monthKey] =
        data?.grouped?.[transactionId]?.accountingSpreads?.[monthKey] || 0;
    }
  }

  return result;
};

const TransactionRow = ({
  transaction,
  monthKeys,
  getDefaultAccountingSpreadsForContract,
  setFieldValue,
  lockDayJs,
}) => {
  const transactionId = transaction.id;

  const onReset = async () => {
    const result = await getDefaultAccountingSpreadsForContract();

    const value = {};
    for (const accountingSpread of result[transactionId]) {
      const monthKey = dayjs.utc(accountingSpread.date).format('YYYY-MM');
      value[monthKey] = accountingSpread.amount;
    }

    setFieldValue(`accountingSpreads.${transactionId}`, value);
  };

  return (
    <tr key={transactionId} gap="4px">
      <td>
        <CentererVertical gap="8px" width="200px" justifyContent="space-between">
          <FlexerColumn gap="4px">
            <LabelText>{transaction.name}</LabelText>
            <ProductName>({transaction.productName})</ProductName>
          </FlexerColumn>
          <ResetButton onClick={onReset}>reset</ResetButton>
        </CentererVertical>
      </td>
      {monthKeys.map((monthKey) => {
        const shouldLock = lockDayJs?.isSameOrAfter(dayjs.utc(`${monthKey}-01`));
        return (
          <td key={monthKey}>
            <FormikCustomInput
              name={`accountingSpreads.${transactionId}.${monthKey}`}
              type="number"
              precision={2}
              data-cy={`manual-contract-accounting-spreads__input-field--${transaction.id}--${monthKey}`}
              inputWidth="120px"
              disabled={shouldLock}
              tooltipInputDisplay={shouldLock ? displayAccountingLockMessage({ lockDayJs }) : null}
            />
          </td>
        );
      })}
    </tr>
  );
};

export const ManualContractAccountingSpreadsModal = ({ orgId, data, contractData, lockDayJs, onClose }) => {
  const {
    operations: { saveManualAccountingSpreadsForContract, getDefaultAccountingSpreadsForContract },
  } = useAccountingSpreadsForContractAPI({ orgId, contractId: contractData.id, autoFetch: false });

  const transactionIds = Object.keys(data.grouped);
  const monthKeys = data.monthKeys;

  const handleFormSubmit = async ({ values }) => {
    const body = {};
    for (const transactionId of transactionIds) {
      body[transactionId] = [];
      for (const monthKey of monthKeys) {
        body[transactionId].push({
          date: `${monthKey}-01`,
          amount: values.accountingSpreads[transactionId][monthKey],
        });
      }
    }

    onClose();

    await saveManualAccountingSpreadsForContract({ data: body });
  };

  const initialValues = formatDataForForm({ transactionIds, monthKeys, data });

  return (
    <ModalContainer>
      <Formik initialValues={initialValues} onSubmit={(values) => handleFormSubmit({ values })}>
        {({ submitForm, setFieldValue }) => {
          return (
            <Modal data-cy="manual-contract-accounting-spreads-modal" width="95vw" height="auto">
              <ModalCloseIcon onClose={onClose} data-cy="manual-contract-accounting-spreads__close-button" />
              <ModalHeader>
                <ModalTitle padding="12px 36px">
                  <ModalTitleText>Manually Update Accounting Spreads for Contract</ModalTitleText>
                </ModalTitle>
              </ModalHeader>
              <ModalBody paddingLeft="36px" paddingRight="36px">
                <FlexerColumn marginBottom="16px" marginTop="16px">
                  <AccountingSpreadsTable>
                    <thead>
                      <tr>
                        <td></td>
                        {monthKeys.map((monthKey) => (
                          <td key={monthKey}>
                            <MonthKeyText>{monthKey}</MonthKeyText>
                          </td>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {transactionIds.map((transactionId) => (
                        <TransactionRow
                          key={transactionId}
                          transaction={data.grouped[transactionId]}
                          monthKeys={monthKeys}
                          getDefaultAccountingSpreadsForContract={getDefaultAccountingSpreadsForContract}
                          setFieldValue={setFieldValue}
                          lockDayJs={lockDayJs}
                        />
                      ))}
                    </tbody>
                  </AccountingSpreadsTable>
                </FlexerColumn>
              </ModalBody>
              <ModalFooter padding="12px 36px" noFixedHeight>
                <FlexEndContainer>
                  <ModalButton default onClick={onClose}>
                    Cancel
                  </ModalButton>
                  <Spacer width="14px" />
                  <ModalButton primary onClick={submitForm} data-cy="manual-contract-accounting-spreads__submit-button">
                    Save
                  </ModalButton>
                </FlexEndContainer>
              </ModalFooter>
            </Modal>
          );
        }}
      </Formik>
    </ModalContainer>
  );
};
