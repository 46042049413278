import styled from 'styled-components';
import PropTypes from 'prop-types';

import { Centerer } from 'components/Core';

import { getColor, getFilledColor } from './utils';

export const StyledButton = styled(Centerer)`
  display: flex;
  padding: ${({ padding }) => padding || '8px 12px'};
  font-size: ${(props) => props.fontSize ?? '12px'};
  font-weight: ${(props) => props.fontWeight ?? '400'};
  border-radius: 100px;
  cursor: pointer;
  pointer-events: ${(props) => props.disabled && 'none'};
  opacity: ${(props) => props.disabled && '0.3'};
  height: ${({ height }) => height};

  color: ${(props) => (props.filled ? getFilledColor(props.color) : getColor(props.color, props.active))};
  background: ${(props) => (props.filled ? getColor(props.color, props.active) : '#fff')};
  border: ${(props) =>
    props.border ? `1px solid ${getColor(props.color, props.active)}` : '1px solid var(--primaryBlack5)'};
`;

export const Button = (props) => <StyledButton {...props} />;

Button.propTypes = {
  /**
   * Is disabled
   */
  disabled: PropTypes.bool,
  /**
   * Color
   */
  color: PropTypes.string,
  /**
   * Set border
   */
  border: PropTypes.bool,
  /**
   * Is filled
   */
  filled: PropTypes.bool,
  /**
   * Is active
   */
  active: PropTypes.bool,
  /**
   * Optional click handler
   */
  onClick: PropTypes.func,
};

Button.defaultProps = {
  color: undefined,
  disabled: false,
  border: false,
  filled: false,
  active: false,
  onClick: undefined,
};
