import { getInvoicingSchedules } from 'api/billing/requests';

export const searchInvoicingSchedules = async ({ searchQuery, orgId }) => {
  const invoicingSchedulesList = await getInvoicingSchedules({
    orgId,
    params: {
      filters: {
        searchQuery,
        limit: 10,
      },
    },
  });

  const invoicingSchedules = invoicingSchedulesList?.map((invoicingSchedule) => ({
    label: `${invoicingSchedule?.customer_name} / ${invoicingSchedule?.id}`,
    value: invoicingSchedule?.id,
    allFields: invoicingSchedule,
  }));

  return invoicingSchedules;
};
