import { getImports } from 'api/imports/requests';
import { getTransactions } from 'api/transactions';
import { SOURCE_TYPES } from 'views/Billing/InvoicesCsvUploadV2/consts';

export const searchTransactionsWithExternal = async ({
  searchQuery,
  orgId,
  // Used for the CSV upload
  transactionsIdSourceAndType,
}) => {
  let searchResult;

  const isExternal = transactionsIdSourceAndType && transactionsIdSourceAndType?.type === SOURCE_TYPES.EXTERNAL;

  if (isExternal) {
    const imports = await getImports({
      orgId,
      params: {
        providerName: transactionsIdSourceAndType.source,
        chifferObjectName: 'transaction',
        includeTransactionFields: true,
        transactionNameSearchQuery: searchQuery,
        limit: 10,
      },
    });

    searchResult = {
      data: imports,
    };
  } else {
    searchResult = await getTransactions({
      orgId,
      filters: {
        page: 1,
        limit: 10,
        params: {
          searchQuery,
        },
      },
    });
  }

  const transactions = searchResult.data.map((transaction) => ({
    label:
      transaction.transaction_name ??
      transaction.name ??
      `No name / ${isExternal ? transaction.provider_object_id : transaction.id}`,
    id: isExternal ? transaction?.provider_object_id : transaction.id,
    value: isExternal ? transaction?.provider_object_id : transaction.id,
    name: transaction.name,
    allFields: transaction,
  }));
  return transactions;
};
