import { FlexerRow, TextBox } from 'components/Core';
import { Children, cloneElement, createContext, useContext, useState } from 'react';
import styled from 'styled-components';
import { ReactComponent as ArrowUp } from 'images/arrow-up.svg';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Header = styled.div`
  display: flex;
  padding: 20px;
  border-radius: ${({ expanded }) => (expanded ? '16px 16px 0 0' : '16px')};
  border: 1px solid var(--accentGraySecond);
  background: ${({ isExpandable }) => (isExpandable ? 'var(--primaryBlack8)' : '#ffffff')};
`;

const ExpandButton = styled.div`
  display: flex;
  align-items: center;
  gap: 0 8px;
  font-weight: 900;
  font-size: 10px;
  padding: 2px 8px;
  border: 1px solid ${({ expanded }) => (expanded ? '#d0d0d0' : 'transparent')};
  border-radius: 100px;
  background: ${({ expanded }) => (expanded ? '#ffffff' : 'var(--dark5)')};
  cursor: pointer;
`;

const ItemWrapper = styled.div`
  border-bottom: 1px solid var(--accentGraySecond);
  border-left: 1px solid var(--accentGraySecond);
  border-right: 1px solid var(--accentGraySecond);
  border-radius: ${({ position }) => (position === 'last' ? '0 0 16px 16px' : '0')};
`;

const AccordionContext = createContext(null);

export const Accordion = ({ isExpandable = true, open = false, entityName = 'item', children }) => {
  const [isOpen, setIsOpen] = useState(open);
  const itemsCount = Children.count(children) - 1; // exclude header
  return (
    <AccordionContext.Provider value={{ isOpen, setIsOpen, isExpandable, itemsCount, entityName }}>
      <Wrapper>{Children.map(children, (child, index) => cloneElement(child, { index }))}</Wrapper>
    </AccordionContext.Provider>
  );
};

export const AccordionHeader = ({ children }) => {
  const { isOpen, isExpandable } = useContext(AccordionContext);
  return (
    <Header isExpandable={isExpandable} expanded={isOpen}>
      {children}
    </Header>
  );
};

export const AccordionTitle = ({ children }) => {
  const { isOpen, setIsOpen, itemsCount, entityName } = useContext(AccordionContext);
  return (
    <FlexerRow alignItems="center" gap="0 8px">
      <TextBox bold>{children}</TextBox>
      <ExpandButton
        data-cy={`accordion-${entityName}__expand-button`}
        expanded={isOpen}
        onClick={() => setIsOpen(!isOpen)}
      >
        {itemsCount}
        <ArrowUp style={{ rotate: !isOpen ? '180deg' : '' }}></ArrowUp>
      </ExpandButton>
    </FlexerRow>
  );
};

export const AccordionItem = ({ index, children }) => {
  const { itemsCount, isOpen } = useContext(AccordionContext);
  return (
    isOpen && (
      <ItemWrapper position={index === itemsCount ? 'last' : index === 1 ? 'first' : 'between'}>{children}</ItemWrapper>
    )
  );
};
