import styled, { keyframes } from 'styled-components';

const fadeIn = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;

const fadeOut = keyframes`
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
`;

export const Wrapper = styled.div`
  animation: ${({ isVisible }) => (isVisible ? fadeIn : fadeOut)} 0.6s linear;
`;

export const ToastContainer = styled.div`
  background: ${(props) => {
    switch (props.type) {
      case 'success':
        return 'var(--primaryGreen)';
      case 'error':
        return 'var(--secondaryRed)';
      case 'warning':
        return 'var(--tertiaryYellow)';
      default:
        return 'var(--primaryBlue)';
    }
  }};
  align-items: center;
  border-radius: 10px;
  box-shadow: 15px 15px 60px rgba(0, 21, 46, 0.2);
  display: flex;
  justify-content: flex-start;
  margin: 10px;
  padding: 10px;
`;

export const ToastText = styled.div`
  color: white;
  font-size: 14px;
  font-style: normal;
  white-space: break-spaces;
  font-weight: 400;
  margin-left: 10px;
`;

export const DismissButton = styled.button`
  background: white;
  box-shadow: 0px 4px 20px var(--primaryBlack10);
  border-radius: 100px;
  border: none;
  cursor: pointer;
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: 900;
  font-size: 10px;
  margin-left: 5px;
  line-height: 14px;
  padding: 3px 6px;
  text-transform: uppercase;
`;
