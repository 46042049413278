import React, { useContext, useState, useCallback } from 'react';
import { pick } from 'lodash';
import { Formik } from 'formik';

import { AppContext } from 'AppContext';
import { EVENTS } from 'consts/analytics';
import { useUsageSubscriptionsAPI, useDiscountsAPI } from 'api/usageBasedEngine';
import { ModalContainer, Modal, ModalCloseIcon } from 'components/Modal';
import { DISCOUNT_TYPE } from 'views/Billing/consts';
import { useAnalytics } from 'utils/hooks';
import { subscriptionValidationSchema } from './consts';
import { SubscriptionForm } from './SubscriptionForm';

export const SUBSCRIPTION_EDIT_MODAL_MODE = {
  CREATE: 'create',
  EDIT: 'edit',
};

const INITIAL_EMPTY_VALUES = {
  customer_id: '',
  pricing_plan_id: '',
  start_date: null,
  end_date: null,
  trial_units: 0,
  has_discount: false,
  discount_code: null,
  discount_type: DISCOUNT_TYPE.PERCENTAGE,
  discount_value: null,
  previous_subscription_id: null,
};

export const SubscriptionEditModal = ({
  onClose,
  selectedSubscription = INITIAL_EMPTY_VALUES,
  mode = SUBSCRIPTION_EDIT_MODAL_MODE.CREATE,
  saveSubscription = true,
  onSubscriptionCreated,
}) => {
  const { orgId, currencyISOCode: orgCurrency } = useContext(AppContext);

  const {
    operations: { createUsageSubscription, createTransactionsForUsageSubscriptions, editUsageSubscription },
  } = useUsageSubscriptionsAPI({
    orgId,
    autoFetch: false,
  });

  const {
    operations: { createDiscount },
  } = useDiscountsAPI({
    orgId,
    autoFetch: false,
  });

  const handleSubmit = async (values) => {
    if (values.has_discount) {
      if (!values.discount_id) {
        const discount = await createDiscount.mutateAsync({
          data: {
            type: values.discount_type,
            value: values.discount_value,
          },
        });
        values.discount_id = discount?.id;
      }
    } else {
      values.discount_id = null;
    }

    const data = pick(values, [
      'start_date',
      'end_date',
      'customer_id',
      'pricing_plan_id',
      'trial_units',
      'discount_id',
      'previous_subscription_id',
      'overage_transaction_name',
      'overage_transaction_metadata',
      'prepaid_transaction_name',
      'prepaid_transaction_metadata',
      'is_prorated',
      'aggregate_usage_on_parent',
      'child_customer_ids',
      'prorate_by_amount',
    ]);

    if (mode === SUBSCRIPTION_EDIT_MODAL_MODE.CREATE) {
      if (saveSubscription) {
        const newSubscription = await createUsageSubscription.mutateAsync({ data });
        onSubscriptionCreated?.(newSubscription);
      } else {
        onSubscriptionCreated?.(values);
      }
    } else if (mode === SUBSCRIPTION_EDIT_MODAL_MODE.EDIT) {
      if (
        !selectedSubscription?.transactions?.filter((t) => t.metadata['Usage Transaction Type'] === 'overage').length
      ) {
        await createTransactionsForUsageSubscriptions.mutateAsync({ id: selectedSubscription.id });
      }

      await editUsageSubscription.mutateAsync({ id: selectedSubscription.id, data });
    }

    onClose();
  };

  return (
    <ModalContainer>
      <Modal overflow="visible" width="90%" data-cy="edit-subscription-modal" height="auto" maxHeight="94vh">
        <ModalCloseIcon onClose={onClose} />
        <Formik
          initialValues={{
            ...selectedSubscription,
            has_discount: !!selectedSubscription?.discount_id,
            discount_id: selectedSubscription?.discount?.id,
            discount_code: selectedSubscription?.discount?.code ?? null,
            discount_type: selectedSubscription?.discount?.type ?? DISCOUNT_TYPE.PERCENTAGE,
            discount_value: selectedSubscription?.discount?.value,
            currency: selectedSubscription?.pricing_plan?.currency ?? orgCurrency,
            overage_transaction_name: selectedSubscription?.transactions?.filter(
              (t) => t.metadata['Usage Transaction Type'] === 'overage',
            )?.[0]?.name,
            overage_transaction_metadata: selectedSubscription?.transactions?.filter(
              (t) => t.metadata['Usage Transaction Type'] === 'overage',
            )?.[0]?.metadata,
            prepaid_transaction_name: selectedSubscription?.transactions?.filter(
              (t) => t.metadata['Usage Transaction Type'] === 'prepaid',
            )?.[0]?.name,
            prepaid_transaction_metadata: selectedSubscription?.transactions?.filter(
              (t) => t.metadata['Usage Transaction Type'] === 'prepaid',
            )?.[0]?.metadata,
          }}
          validationSchema={subscriptionValidationSchema}
          onSubmit={handleSubmit}
          enableReinitialize
        >
          {({ values, setFieldValue, handleSubmit, getFieldMeta }) => (
            <SubscriptionForm
              values={values}
              setFieldValue={setFieldValue}
              handleSubmit={handleSubmit}
              getFieldMeta={getFieldMeta}
              createUsageSubscription={createUsageSubscription}
              editUsageSubscription={editUsageSubscription}
              mode={mode}
              onClose={onClose}
            />
          )}
        </Formik>
      </Modal>
    </ModalContainer>
  );
};

export const useSubscriptionEditModal = () => {
  const { trackEvent } = useAnalytics();
  const [openParams, setOpenParams] = useState({});
  const [showModal, setShowModal] = useState(false);

  const openModal = useCallback(
    (openParams = {}) => {
      trackEvent({
        name: EVENTS.OPEN_USAGE_SUBSCRIPTION_MODAL,
        properties: { mode: openParams?.mode ?? SUBSCRIPTION_EDIT_MODAL_MODE.EDIT },
      });
      setOpenParams(openParams);
      setShowModal(true);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setShowModal],
  );
  const closeModal = useCallback(() => {
    setShowModal(false);
  }, []);

  const Modal = useCallback(
    () => (showModal ? <SubscriptionEditModal onClose={closeModal} {...openParams} /> : <></>),
    [showModal, closeModal, openParams],
  );

  return {
    openModal,
    SubscriptionEditModal: Modal,
  };
};
