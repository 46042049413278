import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useHistory } from 'react-router';
import { useToasts } from 'components/Toasts';
import { useDelegatedMutationPolling } from 'api/jobHooks.helper';
import { CACHE_KEY as TRANSACTIONS_CACHE_KEY } from 'api/transactions/hooks';
import { CACHE_KEY as PRODUCTS_CACHE_KEY } from 'api/products/hooks';
import { CACHE_KEY as IMPORTS_CACHE_KEY } from 'api/imports/hooks';
import { CACHE_KEY } from '../hooks';
import {
  getInvoices,
  bulkSaveInvoicesToGl as _bulkSaveInvoicesToGl,
  bulkSendInvoices as _bulkSendInvoices,
  bulkUploadInvoices as _bulkUploadInvoices,
  generateChangesToOtherInvoices as _generateChangesToOtherInvoices,
  bulkUpdateInvoicesInSubscript as _bulkUpdateInvoicesInSubscript,
} from '../requests';

export const useInvoicesAPI = ({
  orgId,
  invoiceStatus,
  invoiceSyncStatus,
  startDate,
  endDate,
  autoFetch = true,
  enableToasts = true,
  includeReminders,
  includeInvoiceItems,
  includeCount,
  includeCountByStatus = false,
  includeExternalUrls = false,
  invoicingScheduleIds,
  invoiceIds,
  customerId,
  notVoided = false,
  searchQuery,
  page,
  limit,
  orderBy,
  metadataFilter,
  includeChildren,
  transactionIds,
  queryOptions,
}) => {
  const dataKey = [
    CACHE_KEY,
    orgId,
    'invoices',
    invoiceStatus,
    invoiceSyncStatus,
    startDate,
    endDate,
    invoicingScheduleIds,
    invoiceIds,
    customerId,
    notVoided,
    searchQuery,
    page,
    limit,
    orderBy,
    metadataFilter,
    includeChildren,
    transactionIds,
  ];
  const history = useHistory();

  const { pushToast: wrappedPushToast, pushError: wrappedPushError } = useToasts();
  const pushToast = (...args) => {
    if (enableToasts) wrappedPushToast(...args);
  };
  const pushError = (...args) => {
    if (enableToasts) wrappedPushError(...args);
  };
  const queryClient = useQueryClient();

  const params = {
    invoiceStatus,
    invoiceSyncStatus,
    startDate,
    endDate,
    notVoided,
    includeReminders,
    includeInvoiceItems,
    invoicingScheduleIds,
    invoiceIds,
    customerId,
    includeCount,
    includeCountByStatus,
    includeExternalUrls,
    searchQuery,
    page,
    limit,
    orderBy,
    metadataFilter,
    includeChildren,
    transactionIds,
  };
  const { data, error, isLoading, isFetching, refetch } = useQuery(
    dataKey,
    async () =>
      getInvoices({
        orgId,
        params,
      }),
    { enabled: autoFetch, ...queryOptions },
  );

  const bulkSend = useDelegatedMutationPolling({
    mutationFn: ({ invoiceIds, memo, emailSubject, emailBody }) =>
      _bulkSendInvoices({ orgId, body: { invoiceIds, memo, emailSubject, emailBody } }),
    mutationOptions: {
      onError: (err) => {
        pushError(err, 'Failed to send invoice(s). Please contact support.', -1);
      },

      onSuccess: () => {
        pushToast('Started processing Invoices...', 'success', -1);
      },

      onSettled: () => {
        queryClient.invalidateQueries(dataKey);
        queryClient.invalidateQueries(CACHE_KEY);
      },
    },
  });

  const bulkSaveInvoicesToGl = useDelegatedMutationPolling({
    mutationFn: ({ invoiceIds, filters, filtersCount }) =>
      _bulkSaveInvoicesToGl({ orgId, body: { invoiceIds, filters, filtersCount } }),
    mutationOptions: {
      onError: (err) => {
        pushError(err, 'Failed to save invoice(s) to GL. Please contact support.', -1);
      },

      onSuccess: () => {
        pushToast('Started processing Invoices...', 'success', -1);
      },

      onSettled: () => {
        queryClient.invalidateQueries(dataKey);
        queryClient.invalidateQueries(CACHE_KEY);
      },
    },
  });

  const bulkUpdateInvoicesInSubscript = useMutation(
    ({ invoices, filters, filtersCount, updatedFieldsWithNewValues }) =>
      _bulkUpdateInvoicesInSubscript({ orgId, body: { invoices, filters, filtersCount, updatedFieldsWithNewValues } }),
    {
      onError: (err) => {
        pushError(err, 'Failed to update invoice(s) in Subscript. Please contact support.', 10000);
      },

      onSuccess: () => {
        pushToast('Successfully updated invoice(s) in Subscript!', 'success');
      },

      onSettled: () => {
        queryClient.invalidateQueries(dataKey);
        queryClient.invalidateQueries(CACHE_KEY);
        queryClient.invalidateQueries(IMPORTS_CACHE_KEY);
        queryClient.invalidateQueries(PRODUCTS_CACHE_KEY);
        queryClient.invalidateQueries(TRANSACTIONS_CACHE_KEY);
      },
    },
  );
  const bulkUpload = useMutation(({ data }) => _bulkUploadInvoices({ orgId, invoices: data }), {
    onError: (err) => {
      pushError(err, 'Failed to upload invoice(s). Please contact support.');
    },

    onSuccess: () => {
      pushToast('Uploaded invoices!', 'success');

      setTimeout(() => {
        history.push('/billing/invoices');
      }, 1000);
    },

    onSettled: () => {
      queryClient.invalidateQueries(dataKey);
      queryClient.invalidateQueries(CACHE_KEY);
    },
  });

  const generateChangesToOtherInvoices = useMutation(({ body }) => _generateChangesToOtherInvoices({ orgId, body }), {
    onError: (err) => {
      pushError(err, 'Failed to get invoices with updated fields. Please contact support.');
    },
  });

  return {
    data: data || { data: [], metadata: {} },
    error,
    isLoading,
    isFetching,
    params,
    operations: {
      refetch,
      bulkSend,
      bulkSaveInvoicesToGl,
      bulkUpdateInvoicesInSubscript: bulkUpdateInvoicesInSubscript.mutateAsync,
      // refetches all invoices without being affected by table filters
      invalidateAllInvoices: () => queryClient.invalidateQueries([CACHE_KEY, orgId, 'invoices']),
      bulkUpload,
      generateChangesToOtherInvoices: generateChangesToOtherInvoices.mutateAsync,
    },
  };
};
