import { v4 as uuidv4 } from 'uuid';

const getFinalItems = ({ invoiceItem, orgId }) => {
  if (invoiceItem?.id && !invoiceItem?.transactions?.length && !invoiceItem?.type) {
    return [
      {
        id: invoiceItem?.id,
        amount: Number(invoiceItem?.amount),
        customer_id: invoiceItem?.customer_id,
        description: invoiceItem?.description,
        seats: invoiceItem?.seats,
        organization_id: orgId,
        product_id: invoiceItem?.product_id,
        metadata: invoiceItem?.metadata ? JSON.parse(invoiceItem?.metadata) : null,
      },
    ];
  } else if (invoiceItem?.type) {
    return [
      {
        id: invoiceItem?.id ?? uuidv4(),
        amount: Number(invoiceItem?.amount),
        description: invoiceItem?.description,
        type: invoiceItem?.type,
      },
    ];
  } else {
    return invoiceItem?.transactions?.map((transaction) => ({
      id: invoiceItem?.id ?? uuidv4(),
      amount: Number(transaction?.amount),
      customer_id: transaction?.customer_id,
      description: invoiceItem?.description,
      transaction_id: transaction?.transaction_id,
      seats: invoiceItem?.seats,
      organization_id: orgId,
      product_id: invoiceItem?.product_id,
      metadata: invoiceItem?.metadata ? JSON.parse(invoiceItem?.metadata) : null,
    }));
  }
};

export const getFormFinalInvoiceItems = ({ formInvoiceItems, invoices, orgId }) => {
  const invoicesWithItems = formInvoiceItems?.reduce((acc, invoiceItem) => {
    const isGroup = invoiceItem?.transactions?.length > 1 && !invoiceItem?.type;

    const finalInvoiceitems = getFinalItems({ invoiceItem, orgId });

    if (!acc?.[invoiceItem?.invoice_id]) {
      acc[invoiceItem?.invoice_id] = {
        id: invoiceItem?.invoice_id,
        invoice_items: finalInvoiceitems,
      };

      acc[invoiceItem?.invoice_id].grouping = [];
    } else {
      acc[invoiceItem?.invoice_id].invoice_items = [
        ...acc[invoiceItem?.invoice_id].invoice_items,
        ...finalInvoiceitems,
      ];
    }

    if (isGroup) {
      acc[invoiceItem?.invoice_id].grouping = [
        ...acc[invoiceItem?.invoice_id].grouping,
        {
          description: invoiceItem?.description,
          invoice_items_ids: finalInvoiceitems?.map(({ id }) => id),
          isMerged: true,
          name: invoiceItem?.name ?? invoiceItem?.description ?? 'No name',
          seats: invoiceItem?.seats ?? 1,
        },
      ];
    }

    return acc;
  }, {});

  const invoicesForUpload = Object.values(invoicesWithItems)?.map((invoice) => {
    const originalInvoice = invoices?.find((inv) => inv?.id === invoice?.id);
    const isUpdateMode = formInvoiceItems?.some((invoiceItem) => !!invoiceItem?.id);

    const updatedInvoiceItem = invoice?.invoice_items;

    const newInvoiceItems = isUpdateMode
      ? originalInvoice?.invoice_items?.map((item) => {
          const updatedItem = updatedInvoiceItem?.find((invItem) => invItem?.id === item?.id);

          const cleanUpdatedItem = Object.fromEntries(
            Object.entries(updatedItem ?? {}).filter(([_, value]) => value !== null && value !== undefined),
          );

          if (item?.id === updatedItem?.id) {
            return { ...item, ...cleanUpdatedItem };
          } else {
            return item;
          }
        })
      : [...(originalInvoice?.invoice_items ?? []), ...(updatedInvoiceItem ?? [])];

    return {
      ...invoice,
      grouping: [...(originalInvoice?.grouping ?? []), ...(invoice?.grouping ?? [])],
      invoice_items: newInvoiceItems,
    };
  });

  return invoicesForUpload;
};
