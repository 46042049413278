import dayjs from 'dayjs';
import { CustomDatePicker, FormikCustomInput } from 'components/Controls';
import { Spacer } from 'components/Core';
import { ISO_CODE_TO_SYMBOL } from 'consts/global';
import { ColumnMapperSelector } from 'shared/CsvUpload/CsvTable/CsvTable';
import { IdSourceSelectorWrapper, HeaderSelector, SelectorTitle } from 'shared/CsvUpload/styles';
import { CustomSingleOption, SelectDropdownWithTooltip } from '../TransactionsCsvUpload/columns';
import { INTERNAL_TRANSACTION_ID_SOURCE, SOURCE_TYPES } from '../TransactionsCsvUpload/consts';
import { TransactionsSelectorCell } from './cells/TransactionsSelectorCell';

export const getColumns = ({
  formRef,
  csvColumns,
  setTransactionsIdSourceAndType,
  transactionsIdSourceAndType,
  transactionsIdSources,
  csvColumnsMapper,
  setCsvColumnsMapper,
  currency,
  orgId,
  defaultMapper,
}) => {
  return [
    {
      Header: () => <></>,
      width: 30,
      accessor: 'status',
      Cell: () => <></>,
    },
    {
      Header: () => (
        <IdSourceSelectorWrapper>
          <SelectDropdownWithTooltip
            name="transactions-ids-source"
            selected={transactionsIdSourceAndType?.source}
            options={transactionsIdSources}
            onSelect={(source) => {
              setCsvColumnsMapper(defaultMapper);

              setTransactionsIdSourceAndType({
                type: source === INTERNAL_TRANSACTION_ID_SOURCE ? SOURCE_TYPES.INTERNAL : SOURCE_TYPES.EXTERNAL,
                source,
              });
            }}
          />
          <Spacer height="6px" />
          <ColumnMapperSelector
            id="csv-column-with-source"
            field="transaction_id"
            csvColumnsMapper={csvColumnsMapper}
            setCsvColumnsMapper={setCsvColumnsMapper}
            csvColumns={csvColumns}
          />
        </IdSourceSelectorWrapper>
      ),
      width: 180,
      accessor: 'transaction_id',
      Cell: ({ row, cell }) => (
        <TransactionsSelectorCell
          cell={cell}
          row={row}
          formRef={formRef}
          CustomSingleOption={CustomSingleOption}
          orgId={orgId}
          transactionsIdSourceAndType={transactionsIdSourceAndType}
        />
      ),
    },
    {
      Header: () => (
        <HeaderSelector>
          <SelectorTitle>Date</SelectorTitle>
          <ColumnMapperSelector
            field="date"
            csvColumnsMapper={csvColumnsMapper}
            setCsvColumnsMapper={setCsvColumnsMapper}
            csvColumns={csvColumns}
          />
        </HeaderSelector>
      ),
      accessor: 'date',
      width: 180,
      Cell: ({ row, cell: { value } }) => (
        <CustomDatePicker
          offsetContainer={false}
          errorWithoutTooltip
          formik
          height="32px"
          onChange={formRef?.current && formRef?.current?.setFieldValue}
          selected={value && dayjs(value).isValid() ? dayjs(value).toDate() : ''}
          meta={formRef?.current && formRef?.current?.getFieldMeta(`[${row.original.rowIndex}].date`)}
          width="100%"
          name={`[${row.original.rowIndex}].date`}
        />
      ),
    },
    {
      Header: () => (
        <HeaderSelector>
          <SelectorTitle>Amount</SelectorTitle>

          <ColumnMapperSelector
            field="amount"
            csvColumnsMapper={csvColumnsMapper}
            setCsvColumnsMapper={setCsvColumnsMapper}
            csvColumns={csvColumns}
          />
        </HeaderSelector>
      ),
      accessor: 'amount',
      width: 180,
      Cell: ({ row }) => (
        <FormikCustomInput
          suffix={ISO_CODE_TO_SYMBOL[currency] ?? '$'}
          errorWithoutTooltip
          style={{ height: 32 }}
          placeholder="Enter amount..."
          type="number"
          width="100%"
          name={`[${row.original.rowIndex}].amount`}
        />
      ),
    },
    {
      Header: () => (
        <HeaderSelector>
          <SelectorTitle>Seats (optional)</SelectorTitle>
          <ColumnMapperSelector
            field="seats"
            csvColumnsMapper={csvColumnsMapper}
            setCsvColumnsMapper={setCsvColumnsMapper}
            csvColumns={csvColumns}
          />
        </HeaderSelector>
      ),
      accessor: 'seats',
      width: 180,
      Cell: ({ row }) => (
        <FormikCustomInput
          style={{ height: 32 }}
          errorWithoutTooltip
          type="number"
          placeholder="Enter seats..."
          width="100%"
          name={`[${row.original.rowIndex}].seats`}
        />
      ),
    },
  ];
};
