import React, { useState, useContext, useRef } from 'react';
import { isNil } from 'lodash';
import { Divider, Spacer, TextBox } from 'components/Core';
import { Button } from 'components/Buttons';
import { COLORS } from 'consts/colors';
import {
  Modal,
  ModalBody,
  ModalButton,
  ModalCloseIcon,
  ModalContainer,
  ModalFooter,
  ModalHeader,
} from 'components/Modal';
import { AppContext } from 'AppContext';
import { TransactionPreviewTable } from './TransactionsPreviewTable';
import { SubscriptionsPreviewTable } from './SubscriptionsPreviewTable';
import { useContractsAPI } from 'api/contracts';
import { calculateTotalAmount } from 'models/transaction';
import { RECOGNITION_TYPES } from 'consts/global';
import styled from 'styled-components';
import { PricingPlansPreviewTable } from './PricingPlansPreviewTable';
import { ProductPreviewTable } from './ProductPreviewTable';
import { CustomerPreview } from './CustomerPreview';
import { SEARCH_CONFIDENCE } from 'utils/stringUtils';
import { ArrowNarrowLeftIcon } from 'components/Icons';
import { useProductsAPI } from 'api/products';
import { usePricingPlansAPI, useUsageSubscriptionsAPI } from 'api/usageBasedEngine';
import { useTransactionsAPI } from 'api/transactions';
import { CONTRACT_STATUS } from '../ContractsListView/consts';
import { useToasts } from 'components/Toasts';

const StepContainer = styled.div`
  padding: 4px 8px;
  border-radius: 100px;
  background-color: var(--dark10);
`;

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
`;

export const ContractAIPreviewModal = ({ data, onClose, onSubmit }) => {
  const [transactionsToBulkUpload, setTransactionsToBulkUpload] = useState([]);
  const [subscriptionsToBulkUpload, setSubscriptionsToBulkUpload] = useState([]);
  const [pricingPlansToBulkUpload, setPricingPlansToBulkUpload] = useState([]);
  const [productsToBulkUpload, setProductsToBulkUpload] = useState([]);
  const [currentCustomer, setCurrentCustomer] = useState(null);

  const [step, setStep] = useState(1);
  const [loading, setLoading] = useState(false);

  const { pushError } = useToasts();

  const resultsRef = useRef({
    transactions: [],
    subscriptions: [],
    pricingPlans: [],
    customer: {},
    products: [],
  });

  const { orgId } = useContext(AppContext);
  const organization_id = data.organization_id ?? orgId;

  const {
    operations: { editContract },
  } = useContractsAPI({
    orgId,
    autoFetch: false,
  });

  const {
    operations: { bulkCreate: bulkCreateProducts },
  } = useProductsAPI({ orgId, autoFetch: false });

  const {
    operations: { bulkUpload: bulkCreatePricingPlans },
  } = usePricingPlansAPI({
    orgId,
    autoFetch: false,
    redirectOnSuccess: false,
  });

  const {
    operations: { bulkUpload: bulkCreateTransactions },
  } = useTransactionsAPI({
    orgId,
    autoFetch: false,
    redirectOnSuccess: false,
  });

  const {
    operations: { bulkUpload: bulkCreateSubscriptions },
  } = useUsageSubscriptionsAPI({
    orgId,
    autoFetch: false,
    redirectOnSuccess: false,
  });

  const handleNext = async () => {
    // Create products (customer is already created)
    if (step === 1) {
      if (productsToBulkUpload.length > 0) {
        const notCreatedProducts = productsToBulkUpload.filter((product) => !product.isCreated);
        // Create products and imports for each one
        try {
          setLoading(true);
          const productResults = await bulkCreateProducts.mutateAsync({
            data: notCreatedProducts,
            params: { createWithImports: true },
          });
          resultsRef.current.products = productResults;

          // mark then as created
          setProducts((prevProducts) =>
            prevProducts.map((product, index) => {
              return {
                ...product,
                isCreated:
                  notCreatedProducts.some((selectedProduct) => selectedProduct.index === index) || product.isCreated,
              };
            }),
          );
        } finally {
          setLoading(false);
        }
      }

      syncToTransactions();
      syncToPricingPlans();
      syncSubscriptionsCustomer();
    }

    // Create transactions and pricing plans
    if (step === 2) {
      if (transactionsToBulkUpload.length > 0) {
        const notCreatedTransactions = transactionsToBulkUpload.filter((transaction) => !transaction.isCreated);
        const transactionsWithoutCustomerId = notCreatedTransactions.filter((transaction) => !transaction.customer_id);
        if (transactionsWithoutCustomerId.length > 0) {
          // show warning icon for transactions without customer
          setTransactions((prevTransactions) =>
            prevTransactions.map((transaction, index) => {
              return {
                ...transaction,
                missingCustomer: notCreatedTransactions.some(
                  (selectedTransaction) => selectedTransaction.index === index && !transaction.customer_id,
                ),
              };
            }),
          );
          pushError('Failed to create transactions: Some are missing a customer.');
          return;
        } else if (notCreatedTransactions?.length > 0) {
          try {
            setLoading(true);
            const transactionResults = await bulkCreateTransactions.mutateAsync({ data: notCreatedTransactions });
            resultsRef.current.transactions = transactionResults;

            // mark then as created
            setTransactions((prevTransactions) =>
              prevTransactions.map((transaction, index) => {
                return {
                  ...transaction,
                  isCreated:
                    notCreatedTransactions.some((selectedTransaction) => selectedTransaction.index === index) ||
                    transaction.isCreated,
                };
              }),
            );
          } finally {
            setLoading(false);
          }
        }
      }
      if (pricingPlansToBulkUpload.length > 0) {
        const notCreatedPricingPlans = pricingPlansToBulkUpload.filter((pricingPlan) => !pricingPlan.isCreated);
        try {
          setLoading(true);
          const pricingPlanResults = await bulkCreatePricingPlans({ data: notCreatedPricingPlans });
          resultsRef.current.pricingPlans = pricingPlanResults;
          // mark then as created
          setPricingPlans((prevPricingPlans) =>
            prevPricingPlans.map((pricingPlan, index) => {
              return {
                ...pricingPlan,
                isCreated:
                  notCreatedPricingPlans.some((selectedProduct) => selectedProduct.index === index) ||
                  pricingPlan.isCreated,
              };
            }),
          );
        } finally {
          setLoading(false);
        }
      }

      syncSubscriptionsPricingPlan();
    }

    // Create subscriptions
    if (step === 3) {
      if (subscriptionsToBulkUpload.length > 0) {
        const subscriptionsWithoutMissingFields = subscriptionsToBulkUpload.filter(
          (subscription) => !subscription.pricing_plan_id || !subscription.customer_id,
        );
        if (subscriptionsWithoutMissingFields?.length > 0) {
          setSubscriptions((prevSubscriptions) =>
            prevSubscriptions.map((subscription, index) => {
              return {
                ...subscription,
                missingPricingPlan: subscriptionsToBulkUpload.some(
                  (selectedSubscription) => selectedSubscription.index === index && !subscription.pricing_plan_id,
                ),
                missingCustomer: subscriptionsToBulkUpload.some(
                  (selectedSubscription) => selectedSubscription.index === index && !subscription.customer_id,
                ),
              };
            }),
          );
          pushError('Failed to create subscriptions: Some are missing a pricing plan.');
          return;
        } else {
          try {
            setLoading(true);
            const subscriptionResults = await bulkCreateSubscriptions({
              data: subscriptionsToBulkUpload,
              params: { returnScopes: ['customers', 'pricing_plans'] },
            });
            resultsRef.current.subscriptions = subscriptionResults;
          } finally {
            setLoading(false);
          }
        }
      }
    }

    if (step < 3) {
      setStep((step) => step + 1);
      return;
    }

    const customer = { ...currentCustomer };
    delete customer.label;
    delete customer.value;

    // mark contract as parsed
    setLoading(true);
    await editContract.mutateAsync({
      id: data.contract_id,
      data: { customer_id: customer.id, status: CONTRACT_STATUS.PARSED },
    });
    setLoading(false);
    onSubmit?.(resultsRef.current);
  };

  const handlePrev = () => {
    if (step === 1) {
      onClose();
      return;
    }

    setStep((step) => step - 1);
  };

  const getConfirmBtnDisabled = () => {
    if (step === 1) {
      return !currentCustomer;
    }

    return false;
  };

  const getProduct = (data) => {
    // if it's exact match, use the guessed values
    if (data.guessed_product_confidence !== SEARCH_CONFIDENCE.NO_MATCH) {
      return {
        id: +data.guessed_product_id,
        name: data.guessed_product_name,
      };
    }

    // if the product was created in previous steps, get that value
    const product = resultsRef.current.products.find((product) => product.name === data.product_name);
    if (product) {
      return {
        id: +product.id,
        name: product.name,
      };
    }
    return null;
  };

  const getCustomer = (data) => {
    // if it's exact match, use the guessed values
    if (data.guessed_customer_confidence !== SEARCH_CONFIDENCE.NO_MATCH) {
      return {
        id: +data.guessed_customer_id,
        name: data.guessed_customer_name,
      };
    }

    // if the customer was created in previous steps, get that value
    const sameCustomer = resultsRef.current.customer.name === data.customer_name;
    if (sameCustomer) {
      return {
        id: +resultsRef.current.customer.id,
        name: resultsRef.current.customer.name,
      };
    }
    return null;
  };

  const getPricingPlan = (data) => {
    // if it's exact match, use the guessed values
    if (data.guessed_pricing_plan_confidence === SEARCH_CONFIDENCE.EXACT) {
      return {
        id: +data.guessed_pricing_plan_id,
        name: data.guessed_pricing_plan_name,
      };
    }

    // if the pricingPlan was created in previous steps, get that value
    const pricingPlan = resultsRef.current.pricingPlans.find(
      (pricingPlan) => pricingPlan.name === data.pricing_plan_name,
    );
    if (pricingPlan) {
      return {
        id: +pricingPlan.id,
        name: pricingPlan.name,
      };
    }
    return null;
  };

  const convertInterpretedValuesToTransactionsTableData = ({ data }) => {
    return (
      data?.lineItems?.map((lineItem) => {
        let amount = lineItem.amount;
        const isLinearRecurring = lineItem.recognition
          ? lineItem.recognition === RECOGNITION_TYPES.linear
          : lineItem.recurring;
        if (
          isNil(amount) &&
          !isNil(lineItem.recurring_amount) &&
          isLinearRecurring &&
          lineItem.start_date &&
          lineItem.end_date
        ) {
          amount = calculateTotalAmount({
            startDate: lineItem.start_date,
            endDate: lineItem.end_date,
            recurringAmount: lineItem.recurring_amount,
            includeEndMonth: lineItem.include_end_month,
            useAutoIncrease: lineItem.use_auto_increase,
            autoIncreaseIncrements: lineItem.auto_increase_increments,
          });
        }

        const productForTransaction = getProduct(lineItem);
        const product_id = productForTransaction?.id;
        const product_name = productForTransaction?.name;

        const customerForTransaction = getCustomer(lineItem);
        const customer_id = customerForTransaction?.id;
        const customer_name = customerForTransaction?.name;

        const result = {
          originalLineItem: lineItem,
          organization_id,
          contract_id: data.contract_id,

          customer_id,
          customer_name,
          interpreter_customer_name: lineItem.customer_name,
          customer_confidence: lineItem.guessed_customer_confidence,

          product_id,
          product_name,
          interpreter_product_name: lineItem.product_name,
          product_confidence: lineItem.guessed_product_confidence,

          name: lineItem.name,
          amount,
          recurring_amount: lineItem.recurring_amount,
          seats: lineItem.seats,
          recognition: lineItem.recognition ?? (lineItem.recurring ? 'linear' : 'immediate'),

          metadata: { ...data?.metadata, ...lineItem?.metadata },
          confirmed: true,

          date: lineItem.date,
          start_date: lineItem.start_date,
          end_date: lineItem.end_date,
        };

        return result;
      }) ?? []
    );
  };

  const convertInterpretedValuesToSubscriptionsTableData = ({ data }) => {
    return (
      data?.subscriptions?.map((subscription, index) => {
        const customerForSubscription = getCustomer(subscription);
        const customer_id = customerForSubscription?.id;
        const customer_name = customerForSubscription?.name;

        const pricingPlanForSubscription = getPricingPlan(subscription);
        const pricing_plan_id = pricingPlanForSubscription?.id;
        const pricing_plan_name = pricingPlanForSubscription?.name;

        const result = {
          originalValue: subscription,
          organization_id,

          // used for checkbox data-cy
          name: index + 1,

          customer_id,
          customer_name,
          interpreter_customer_name: subscription.customer_name,
          customer_confidence: subscription.guessed_customer_confidence,

          pricing_plan_id,
          pricing_plan_name,

          start_date: subscription.start_date,
          end_date: subscription.end_date,

          trial_units: subscription.trial_units,

          discount_id: subscription.guessed_discount_id,
          discount: subscription.guessed_discount_id
            ? {
                id: subscription.guessed_discount_id,
                code: subscription.guessed_discount_code,
                type: subscription.guessed_discount_type,
                value: subscription.guessed_discount_value,
              }
            : null,
        };

        return result;
      }) ?? []
    );
  };

  const convertInterpretedValuesToPricingPlansTableData = ({ data }) => {
    return (
      data?.pricingPlans?.map((pricingPlan) => {
        const productForPricingPlan = getProduct(pricingPlan);

        const product_id = productForPricingPlan?.id;
        const product_name = productForPricingPlan?.name;

        return {
          originalValue: pricingPlan,
          organization_id,

          product_id,
          product_name,
          interpreter_product_name: pricingPlan.product_name,
          product_confidence: pricingPlan.guessed_product_confidence,

          name: pricingPlan.name,
          interpreterName: pricingPlan.name,
          description: pricingPlan.description,
          event_name: pricingPlan.event_name,
          currency: pricingPlan.currency,
          invoicing_frequency: pricingPlan.invoicing_frequency,
          use_single_rate_tier: pricingPlan.use_single_rate_tier,
          bill_in_advance: pricingPlan.bill_in_advance,
          tiers: pricingPlan.tiers,
        };
      }) ?? []
    );
  };

  /**
   * Return the first customer name that is not exact match
   */
  const findNewCustomer = ({ data }) => {
    const newCustomer = { name: '', organization_id };
    const items = (data?.lineItems ?? []).concat(data?.subscriptions ?? []);

    for (const item of items) {
      if (item.customer_name && item.guessed_customer_confidence !== SEARCH_CONFIDENCE.EXACT) {
        newCustomer.name = item.customer_name;
        break;
      }
    }

    return newCustomer;
  };

  const convertInterpretedValuesToNewProductsTableData = ({ data }) => {
    const newProducts = new Set();
    const items = (data?.lineItems ?? []).concat(data?.pricingPlans ?? []);

    items.forEach((item) => {
      if (item.product_name && item.guessed_product_confidence === SEARCH_CONFIDENCE.NO_MATCH) {
        newProducts.add(item.product_name);
      }
    });

    // Keep interpreterName, so if the product name is changed, we can still match it with transactions
    return [...newProducts].map((name) => ({ name, organization_id, interpreterName: name, recognition: 'linear' }));
  };

  const transactionsTableData = convertInterpretedValuesToTransactionsTableData({ data });
  const subscriptionsTableData = convertInterpretedValuesToSubscriptionsTableData({ data });
  const pricingPlansTableData = convertInterpretedValuesToPricingPlansTableData({ data });
  const newCustomer = findNewCustomer({ data });
  const newProducts = convertInterpretedValuesToNewProductsTableData({ data });

  const [products, setProducts] = useState(newProducts);
  const [pricingPlans, setPricingPlans] = useState(pricingPlansTableData);
  const [transactions, setTransactions] = useState(transactionsTableData);
  const [subscriptions, setSubscriptions] = useState(subscriptionsTableData);

  const syncToTransactions = () => {
    const clonedTransactions = [...transactions];
    transactions.forEach((transaction, index) => {
      if (!transaction.product_id && !transaction.product_name) {
        const product = getProduct(clonedTransactions[index].originalLineItem);
        if (product) {
          const product_id = product?.id;
          const product_name = product?.name;
          clonedTransactions[index] = {
            ...clonedTransactions[index],
            product_id,
            product_name,
          };
        }
      }
      if (!transaction.customer_id && !transaction.customer_name) {
        const customer = getCustomer(clonedTransactions[index].originalLineItem);
        if (customer) {
          const customer_id = customer?.id;
          const customer_name = customer?.name;
          clonedTransactions[index] = {
            ...clonedTransactions[index],
            customer_id,
            customer_name,
          };
        }
      }
    });
    setTransactions(clonedTransactions);
  };

  const syncSubscriptionsCustomer = () => {
    subscriptions.forEach((subscription, index) => {
      if (!subscription.customer_id && !subscription.customer_name) {
        const clonedSubscription = [...subscriptions];
        const customer = getCustomer(clonedSubscription[index].originalValue);
        if (customer) {
          const customer_id = customer?.id;
          const customer_name = customer?.name;
          clonedSubscription[index] = {
            ...clonedSubscription[index],
            customer_id,
            customer_name,
          };
          setSubscriptions(clonedSubscription);
        }
      }
    });
  };

  const syncSubscriptionsPricingPlan = () => {
    subscriptions.forEach((subscription, index) => {
      if (!subscription.pricing_plan_id && !subscription.pricing_plan_name) {
        const clonedSubscription = [...subscriptions];
        const pricingPlan = getPricingPlan(clonedSubscription[index].originalValue);
        if (pricingPlan) {
          const pricing_plan_id = pricingPlan?.id;
          const pricing_plan_name = pricingPlan?.name;
          clonedSubscription[index] = {
            ...clonedSubscription[index],
            pricing_plan_id,
            pricing_plan_name,
          };
          setSubscriptions(clonedSubscription);
        }
      }
    });
  };

  const syncToPricingPlans = () => {
    pricingPlans.forEach((pricingPlan, index) => {
      if (!pricingPlan.product_id && !pricingPlan.product_name) {
        const clonedPricingPlan = [...pricingPlans];
        const product = getProduct(clonedPricingPlan[index].originalValue);
        if (product) {
          const product_id = product?.id;
          const product_name = product?.name;
          clonedPricingPlan[index] = {
            ...clonedPricingPlan[index],
            product_id,
            product_name,
          };
          setPricingPlans(clonedPricingPlan);
        }
      }
    });
  };

  return (
    <ModalContainer data-cy="contract-files-modal">
      <Modal width="80vw" overflow="visible" padding="0px" background="var(--primaryGray)">
        <ModalHeader padding="20px 36px">
          <HeaderContainer>
            <StepContainer>
              <TextBox bold italic>{`Step ${step} `}</TextBox>
              <TextBox bold italic opacity="0.5">
                of 3
              </TextBox>
            </StepContainer>
            <TextBox italic color="var(--dark100)" opacity="0.5">
              Finish all steps to confirm the new data from your contract
            </TextBox>
          </HeaderContainer>
          <ModalCloseIcon onClose={onClose} />
        </ModalHeader>
        <Divider />

        <ModalBody centered gap={'28px'} paddingTop={'28px'} paddingBottom={'28px'}>
          {step === 1 && (
            <>
              <CustomerPreview
                newCustomer={newCustomer}
                customer={currentCustomer}
                onChange={(newCustomer) => {
                  setCurrentCustomer(newCustomer);
                  resultsRef.current.customer = newCustomer;
                }}
              />
              <ProductPreviewTable data={products} setData={setProducts} setDataHook={setProductsToBulkUpload} />
            </>
          )}
          {step === 2 && (
            <>
              <TransactionPreviewTable
                data={transactions}
                setData={setTransactions}
                setDataHook={setTransactionsToBulkUpload}
              />
              <Divider />
              <PricingPlansPreviewTable
                data={pricingPlans}
                setData={setPricingPlans}
                setDataHook={setPricingPlansToBulkUpload}
              />
            </>
          )}
          {step === 3 && (
            <SubscriptionsPreviewTable
              data={subscriptions}
              setData={setSubscriptions}
              setDataHook={setSubscriptionsToBulkUpload}
            />
          )}
        </ModalBody>

        <ModalFooter flexEnd padding="12px 36px" noFixedHeight>
          <Button
            border
            filled
            color={COLORS.GREY}
            onClick={handlePrev}
            fontWeight="700"
            padding="8px 12px 8px 8px"
            height="32px"
          >
            <ArrowNarrowLeftIcon size="16px" />
            <Spacer width="8px" />
            <>{step === 1 ? 'Edit Files' : 'Edit Imported Data'}</>
          </Button>
          <Spacer width="8px" />
          <ModalButton
            primary
            disabled={getConfirmBtnDisabled() || loading}
            onClick={handleNext}
            fontWeight="700"
            iconSize="16px"
            padding="8px 8px 8px 12px"
            height="32px"
          >
            {step === 3 ? 'Create & Complete Import' : 'Create & Go Next'}
          </ModalButton>
        </ModalFooter>
      </Modal>
    </ModalContainer>
  );
};
