import Checkbox from 'antd/lib/checkbox/Checkbox';
import { RowActionPopover, TableActionCell } from 'components/Table';
import { FadedText } from 'views/Billing/UsageBasedEngine/Subscriptions/styles';
import { columnGenerator as pricingPlansColumnGenerator } from 'views/Billing/UsageBasedEngine/PricingPlans/columns';
import { FlexerRow } from 'components/Core';
import { ReactComponent as _WarningIcon } from 'images/warning-icon.svg';
import { ReactComponent as CheckIcon } from 'images/transaction_check.svg';
import styled from 'styled-components';
import { displayInterpretedValue } from '../utils';

const WarningIcon = styled(_WarningIcon)`
  height: 12px;
  path {
    fill: var(--primaryRed);
  }
`;

export const columnGenerator = ({ entityName }) => ({
  header: () => ({
    Header: ({ getToggleAllRowsSelectedProps }) => (
      <Checkbox data-cy={`${entityName}-row-checkbox--select-all`} {...getToggleAllRowsSelectedProps()} />
    ),
    accessor: 'checkbox',
    width: 32,
    Cell: ({ row }) => (
      <>
        {row.original.isCreated ? (
          <CheckIcon style={{ position: 'relative', left: '5px' }}></CheckIcon>
        ) : (
          <Checkbox
            checked={row.isSelected}
            onClick={() => {
              row.toggleRowSelected();
            }}
            data-cy={`${entityName}-row-checkbox-${row.original.name}`}
          />
        )}
      </>
    ),
  }),

  customer: () => ({
    Header: 'Customer',
    accessor: 'customer_name',
    width: 265,
    Cell: ({ row }) => {
      return (
        <FlexerRow alignItems="center" gap="0 4px">
          {row.original.missingCustomer && <WarningIcon />}
          {displayInterpretedValue({
            entityName: 'customer',
            currentValue: row.original.customer_name,
            interpretedValue: row.original.interpreter_customer_name,
            title: row.original.customer_name || 'No Customer',
            confidence: row.original.customer_confidence,
          })}
        </FlexerRow>
      );
    },
  }),

  startDate: ({ columnName = 'Start Date', formatter = (_) => _ }) => ({
    Header: columnName,
    accessor: 'start_date',
    width: 90,
    Cell: ({ row }) => (
      <>{row.original.start_date ? formatter(row.original.start_date) : <FadedText>No start date</FadedText>}</>
    ),
  }),

  endDate: ({ columnName = 'End Date', formatter = (_) => _ }) => ({
    Header: columnName,
    accessor: 'end_date',
    width: 90,
    Cell: ({ row }) => (
      <>{row.original.end_date ? formatter(row.original.end_date) : <FadedText>No end date</FadedText>}</>
    ),
  }),

  product: () => ({
    Header: 'Product',
    accessor: 'product_name',
    width: 130,
    Cell: ({ row }) =>
      displayInterpretedValue({
        entityName: 'product',
        interpretedValue: row.original.interpreter_product_name,
        currentValue: row.original.product_name,
        title: row.original.product_name || 'No Product',
        confidence: row.original.product_confidence,
      }),
  }),

  billingScheme: pricingPlansColumnGenerator.billingScheme,

  actions: ({ onEditClick }) => ({
    accessor: 'actions',
    disableSortBy: true,
    width: 65,
    Cell: ({ row }) =>
      !row.original.isCreated && (
        <TableActionCell>
          <RowActionPopover onEditClick={() => onEditClick(row)} />
        </TableActionCell>
      ),
  }),
});
