import React, { useState, useCallback, useContext, useRef } from 'react';
import styled from 'styled-components';
import { components } from 'react-select';
import { Form, Formik } from 'formik';

import { AppContext } from 'AppContext';
import { searchResources } from 'api/billing/requests';
import { Flexer, Spacer } from 'components/Core';
import { FormikCustomInput, FormikCustomSelector } from 'components/Controls';
import { CircleLoader } from 'components/Loaders';
import { ReactComponent as AddIcon } from 'images/circle-plus.svg';
import {
  Modal,
  ModalBody,
  ModalButton,
  ModalCloseIcon,
  ModalContainer,
  ModalFooter,
  ModalHeader,
  ModalTitle,
} from 'components/Modal';

export const CreateCustomerOptionWrapper = styled(Flexer)`
  position: absolute;
  bottom: ${({ height }) => (height ? `-${height + 5}px` : '-52px')};
  padding: 12px;
  gap: 8px;
  background-color: white;
  width: 100%;
  border-radius: 12px;
  border: 1px solid var(--neutralGray);
  cursor: pointer;
  align-items: center;
`;

const CreateCustomerOptionLabel = styled.div`
  font-style: italic;
  font-size: 12px;
`;

const StyledAddIcon = styled(AddIcon)`
  width: 16px;
  height: 16px;
`;

const CreateCustomerInGlModal = ({
  onClose,
  invoicingServiceLabel,
  handleCreateCustomerInGl,
  customerName,
  isCreating,
}) => {
  return (
    <ModalContainer>
      <Modal compact maxWidth="fit-content" height="auto" maxHeight="auto" overflow="auto">
        <ModalHeader>
          <ModalCloseIcon onClose={onClose} />
          <ModalTitle compact padding="0px 60px 10px 20px">
            <b>{`Create a new customer in ${invoicingServiceLabel}`}</b>
          </ModalTitle>
        </ModalHeader>
        <Formik
          initialValues={{
            customerName,
          }}
          enableReinitialize
          onSubmit={async (values) => {
            await handleCreateCustomerInGl(values.customerName);
            onClose();
          }}
        >
          {({ handleSubmit }) => (
            <Form>
              <ModalBody paddingBottom="20px">
                <FormikCustomInput name="customerName" label="Customer Name" />
              </ModalBody>

              <ModalFooter flexEnd>
                <ModalButton default onClick={onClose}>
                  Cancel
                </ModalButton>
                <ModalButton
                  primary
                  data-cy="create-external-customer__button"
                  onClick={handleSubmit}
                  hideIcon={isCreating}
                >
                  Create
                  {isCreating && (
                    <>
                      <Spacer width="8px" />
                      <CircleLoader width="16px" height="16px" />
                    </>
                  )}
                </ModalButton>
              </ModalFooter>
            </Form>
          )}
        </Formik>
      </Modal>
    </ModalContainer>
  );
};

export const GLCustomerSelector = ({
  label,
  name,
  handleInputChange,
  initialOptions,
  integrationId,
  setGLCustomerOptionsMap,
  isDisabled,
  tooltipInputDisplay,
  handleUpsertExternalCustomer,
  customerName,
  setSearchQuery,
  invoicingServiceLabel,
  ...props
}) => {
  const { orgId } = useContext(AppContext);

  const menuPropsRef = useRef();

  const [isCreating, setIsCreating] = useState(false);

  const [createOptionWrapperHeight, setCreateOptionWrapperHeight] = useState();
  const [openCreateCustomerInGlModal, setOpenCreateCustomerInGlModal] = useState(false);

  const getCreateOptionWrapperHeight = useCallback((ref) => {
    if (ref) {
      setCreateOptionWrapperHeight(ref.getBoundingClientRect().height);
    }
  }, []);

  const getGLCustomersFromSearch = useCallback(
    async (searchQuery) => {
      setSearchQuery && setSearchQuery(searchQuery ?? null);
      const customers = await searchResources({
        params: { searchQuery, resource: 'customer', integrationId },
        orgId,
      });
      setGLCustomerOptionsMap &&
        setGLCustomerOptionsMap((customers ?? []).reduce((acc, customer) => ({ ...acc, [customer.id]: customer }), {}));
      return (customers ?? []).map((customer) => ({
        label: `${customer.name || '<No Name>'} / ${customer.id || ''}`,
        value: customer.id,
        customer,
      }));
    },
    [integrationId, orgId, setGLCustomerOptionsMap, setSearchQuery],
  );

  const handleCreateCustomerInGl = useCallback(
    async (name) => {
      if (!isCreating) {
        setIsCreating(true);
        try {
          await handleUpsertExternalCustomer({ selectorProps: menuPropsRef.current, customerName: name });
          setIsCreating(false);
        } catch {
          setIsCreating(false);
        }
      }
    },
    [handleUpsertExternalCustomer, isCreating],
  );

  return (
    <>
      <FormikCustomSelector
        boldValue
        label={label}
        placeholder="Type to search..."
        name={name}
        handleChange={handleInputChange}
        options={initialOptions}
        loadOptions={getGLCustomersFromSearch}
        isClearable
        isDisabled={isDisabled}
        tooltipInputDisplay={tooltipInputDisplay}
        components={{
          Menu: ({ children, ...props }) => {
            return (
              <components.Menu {...props}>
                {children}
                <CreateCustomerOptionWrapper
                  data-cy="custom-select__create-external-customer-option"
                  onClick={() => {
                    menuPropsRef.current = props;
                    setOpenCreateCustomerInGlModal(true);
                    props.selectProps?.onMenuClose();
                  }}
                  ref={getCreateOptionWrapperHeight}
                  height={createOptionWrapperHeight}
                >
                  {isCreating ? <CircleLoader width="16px" height="16px" /> : <StyledAddIcon />}
                  <CreateCustomerOptionLabel>Create a new customer</CreateCustomerOptionLabel>
                </CreateCustomerOptionWrapper>
              </components.Menu>
            );
          },
        }}
        containerWidth="100%"
        {...props}
      />

      {openCreateCustomerInGlModal && (
        <CreateCustomerInGlModal
          onClose={() => setOpenCreateCustomerInGlModal(false)}
          invoicingServiceLabel={invoicingServiceLabel}
          handleCreateCustomerInGl={handleCreateCustomerInGl}
          customerName={customerName}
          isCreating={isCreating}
        />
      )}
    </>
  );
};
