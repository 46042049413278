import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import { cssVar } from 'polished';
import { useHistory, useLocation } from 'react-router-dom';
import { AppContext } from 'AppContext';
import { exportInvoicesWithLineItems } from 'api/billing';
import { INTEGRATION_TYPES } from 'consts/integrations';
import { getServiceCategory } from 'models/integration';
import { COLORS } from 'consts/colors';
import { humanize } from 'utils/stringUtils';
import { downloadBlobFile } from 'utils/export';
import { ExportButton as CommonExportButton, IconButton } from 'components/Buttons';
import { Centerer, FlexerColumn, Spacer } from 'components/Core';
import { CirclePlusIcon, FilePlus } from 'components/Icons';
import { ActionsPopover } from 'shared/Common';
import { ReactComponent as CsvIcon } from 'images/actions-dropdown__csv.svg';
import { Banner, BannerText, BannerActions, BannerTitle, BannerButton } from 'components/Blocks/Banner';
import { TooltipContainer } from 'components/Tooltip';
import {
  Popover,
  PopoverItemsContainer,
  PopoverListItemFull,
  PopoverListItemIconFull,
  PopoverListItemTitleFull,
  PopoverWrapper,
  usePortal,
} from 'components/Portal';
import { useClickOutside } from 'utils/hooks';
import { ReactComponent as HowWorksIcon } from 'images/lifebuoy.svg';
import { ReactComponent as ArrowDown } from 'images/chevron-down.svg';
import { Header, HeaderTitle, HEADER_TITLES } from 'shared/Layout';
import { BillingPopover } from 'shared/Layout/Navbar';
import { useSyncedExport } from 'shared/SyncedExports';

import { BILLING_PAGES_ROUTES, SERVICE_WITH_INCOME_ACCOUNT_REF } from './consts';
import { SyncInvoiceStatusesButton } from './Common/SyncInvoiceStatusesButton';
import { TransactionsCountChip } from './Common/TransactionsCountChip';
import { DisconnectedIntegrationBanner } from './Common/DisconnectedIntegrationBanner';
import { BillingContext } from './BillingContext';
import { useHowItWorksModal } from './HowItWorksModal';

const HeaderWrapper = styled.div`
  padding: 0 40px 40px 40px;
`;

const CountChip = styled(Centerer)`
  position: absolute;
  padding: 2px 6px;
  right: 12px;
  top: 14px;
  background: var(--primaryYellow15);
  border: 2px solid #ffffff;
  border-radius: 100px;

  font-weight: 900;
  font-size: 9px;
  line-height: 12px;
  text-transform: uppercase;
  color: var(--secondaryYellow);
`;

const StyledBannerButton = styled(BannerButton)`
  padding: 8px 12px;
  font-weight: 900;
  font-size: 10px;
  line-height: 12px;
  border-radius: 100px;
  margin-top: 0;
`;

export const HeaderTab = styled.b`
  font-weight: 900;
  margin-left: 12px;

  i {
    color: var(--primaryBlack50);
  }
`;

export const StyledArrow = styled.div`
  width: 36px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  margin-left: 12px;
  box-shadow: 4px 4px 24px var(--primaryBlack4);
  border-radius: 100px;
  border: 1px solid var(--accentGraySecond);
  transition: all ease 0.4s;
  transform: ${(props) => (props.active ? 'rotate(180deg)' : 'rotate(0deg)')};
`;

const CreateInvoiceDropdown = () => {
  const history = useHistory();
  const { openInvoicingScheduleModal, transactionsThatNeedSchedules } = useContext(BillingContext);

  const [showDropdown, setShowDropdown] = useState(false);
  const toggleDropdown = () => setShowDropdown(!showDropdown);
  const dropdownRef = useClickOutside(() => setShowDropdown(false));

  return (
    <PopoverWrapper ref={dropdownRef}>
      <TooltipContainer
        toolTipContent="When sending invoices, Subscript will create them in your accounting system"
        width={200}
        hideArrow={true}
        isVisible={!showDropdown}
      >
        <IconButton
          icon={<FilePlus />}
          filled
          border
          color={COLORS.GREEN}
          colorOnHover={cssVar('--secondaryGreen')}
          iconFillColor="white"
          active={!showDropdown}
          onClick={transactionsThatNeedSchedules > 0 ? toggleDropdown : openInvoicingScheduleModal}
          data-cy="billing-dashboard__create-invoices-button"
        >
          Create Invoices
          <TransactionsCountChip />
        </IconButton>
      </TooltipContainer>
      {showDropdown && (
        <Popover width="220px" data-cy="billing-dashboard__create-invoices-popover">
          <PopoverItemsContainer>
            <PopoverListItemFull gap="8px" onClick={() => history.push('/billing/review-transactions')}>
              <PopoverListItemIconFull>
                <CirclePlusIcon />
              </PopoverListItemIconFull>
              <FlexerColumn gap="4px">
                <PopoverListItemTitleFull>From Recent Transactions</PopoverListItemTitleFull>
              </FlexerColumn>
              <CountChip>{transactionsThatNeedSchedules}</CountChip>
            </PopoverListItemFull>

            <PopoverListItemFull gap="8px" onClick={openInvoicingScheduleModal}>
              <PopoverListItemIconFull>
                <CirclePlusIcon />
              </PopoverListItemIconFull>
              <PopoverListItemTitleFull>By Customer</PopoverListItemTitleFull>
            </PopoverListItemFull>
          </PopoverItemsContainer>
        </Popover>
      )}
    </PopoverWrapper>
  );
};

const TITLE_BY_ROUTE = {
  [BILLING_PAGES_ROUTES.ROOT]: 'Dashboard',
  [BILLING_PAGES_ROUTES.DASHBOARD]: 'Dashboard',
  [BILLING_PAGES_ROUTES.INVOICES]: 'Invoices',
  [BILLING_PAGES_ROUTES.AR_AGING_REPORT]: 'A/R Aging Report',
  [BILLING_PAGES_ROUTES.SCHEDULES_EXTERNAL_UPDATES]: (
    <>
      Review invoices for <i>updated transactions</i>
    </>
  ),
};

export const BillingHeader = ({ exportScreenshotRef }) => {
  const { Modal: HowItWorksModal, openModal: openHowItWorksModal } = useHowItWorksModal();
  const { triggerRef, togglePortal, isPortalVisible, Portal, hidePortal } = usePortal();

  const { orgId, integrations, orgConfigs } = useContext(AppContext);
  const {
    invoiceStatuses,
    billingDataFilter,
    invoicesSearchQuery,
    forceRefetchInvoices,
    pushToast,
    metadataFilter,
    invoiceSyncStatusFilter,
  } = useContext(BillingContext);

  const { ExportButton } = useSyncedExport({ orgId, type: 'aging_report', exportScreenshotRef, externalSync: false });

  const location = useLocation();
  const route = location?.pathname;

  const history = useHistory();
  const { incomeAccountRefId } = orgConfigs;

  const invoicingService = getServiceCategory(integrations?.find((i) => i.type === INTEGRATION_TYPES.GL)?.service);

  const handleExport = async () => {
    pushToast('Download started...');
    const zipFile = await exportInvoicesWithLineItems({
      orgId,
      body: {
        filters: {
          invoiceStatus: invoiceStatuses,
          invoiceSyncStatus: invoiceSyncStatusFilter,
          startDate: billingDataFilter.startMonth,
          endDate: billingDataFilter.endMonth,
          notVoided: false,
          searchQuery: invoicesSearchQuery,
          orderBy: ['date', 'customer_name'],
          metadataFilter,
        },
      },
    });
    downloadBlobFile({
      file: zipFile,
      format: 'zip',
      filename: 'invoices_export',
    });
  };

  return (
    <HeaderWrapper style={{ paddingBottom: 0 }}>
      <Header
        headerLeft={
          <>
            <HeaderTitle ref={triggerRef} onClick={togglePortal} weight={300} cursor="pointer">
              {HEADER_TITLES.billing}: <HeaderTab>{TITLE_BY_ROUTE?.[route]}</HeaderTab>
              <StyledArrow active={isPortalVisible}>
                <ArrowDown />
              </StyledArrow>
              {isPortalVisible && (
                <Portal>
                  <BillingPopover onClose={hidePortal} />
                </Portal>
              )}
            </HeaderTitle>
            <SyncInvoiceStatusesButton refetchInvoices={forceRefetchInvoices} pushToast={pushToast} />
          </>
        }
        headerRight={
          route === BILLING_PAGES_ROUTES.SCHEDULES_EXTERNAL_UPDATES ? (
            <></>
          ) : route === BILLING_PAGES_ROUTES.AR_AGING_REPORT ? (
            <ExportButton />
          ) : (
            <>
              <IconButton
                icon={<HowWorksIcon />}
                iconFillColor={cssVar('--primaryGreen')}
                onClick={openHowItWorksModal}
                data-cy="billing__how-it-works"
              >
                How it works
              </IconButton>

              <Spacer width="24px" />
              <CommonExportButton whiteVer onClick={handleExport} />

              <Spacer width="8px" />
              <CreateInvoiceDropdown />
              {route === BILLING_PAGES_ROUTES.INVOICES ? (
                <>
                  <Spacer width="8px" />
                  <ActionsPopover
                    width="200px"
                    actions={{
                      'Upload Invoices CSV': {
                        icon: <CsvIcon />,
                        cb: () => history.push('/csv/invoices/v2'),
                      },
                      'Upload Invoice Items CSV': {
                        icon: <CsvIcon />,
                        cb: () => history.push('/csv/invoice-items'),
                      },
                    }}
                  />
                </>
              ) : null}
            </>
          )
        }
      />

      <DisconnectedIntegrationBanner />

      {SERVICE_WITH_INCOME_ACCOUNT_REF.includes(invoicingService) && !incomeAccountRefId && (
        <Banner
          onClick={() =>
            history.push(
              `/configure?tab=sources&integration=${integrations?.find((i) => i.type === INTEGRATION_TYPES.GL)?.id}`,
            )
          }
          redVersion
        >
          <BannerTitle redVersion>Missing {humanize(invoicingService ?? '')} income account</BannerTitle>
          <BannerText style={{ marginBottom: 16 }}>Pick the account that we should map the revenue to</BannerText>
          <BannerActions>
            <StyledBannerButton>Go to Billing Settings</StyledBannerButton>
          </BannerActions>
        </Banner>
      )}

      <HowItWorksModal />
    </HeaderWrapper>
  );
};
