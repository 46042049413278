import React, { useCallback, useContext, useMemo } from 'react';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { AppContext } from 'AppContext';
import { CsvUploadV2 } from 'shared/CsvUploadV2';
import { DEFAULT_TRANSACTIONS_TRANSFORMATIONS, INTERNAL_TRANSACTION_ID_SOURCE } from '../TransactionsCsvUpload/consts';
import { getOrgIdSources } from '../TransactionsCsvUpload/utils';
import { getColumns } from './columns';

dayjs.extend(utc);

export const SpreadsCsvUploadV2 = ({
  handleSubmit,
  entityName,
  defaultMapper,
  fuzzyErrors,
  additionalInfoText,
  transactionsIdSourceAndType,
  setTransactionsIdSourceAndType,
  submittingForm,
}) => {
  const {
    integrations,
    orgId,
    appSettings: { currencyISOCode: currency },
  } = useContext(AppContext);

  const transactionsIdSources = useMemo(
    () =>
      getOrgIdSources({
        integrations,
        internalSource: INTERNAL_TRANSACTION_ID_SOURCE,
        suffix: 'transaction ID',
      }),
    [integrations],
  );

  const getColumnsWithCsvUploadState = useCallback(
    ({ formRef, csvColumnsMapper, setCsvColumnsMapper, csvColumns, defaultMapper }) =>
      getColumns({
        formRef,
        orgId,
        csvColumns,
        setTransactionsIdSourceAndType,
        transactionsIdSourceAndType,
        transactionsIdSources,
        csvColumnsMapper,
        setCsvColumnsMapper,
        currency,
        defaultMapper,
      }),
    [currency, transactionsIdSourceAndType, orgId, setTransactionsIdSourceAndType, transactionsIdSources],
  );

  return (
    <CsvUploadV2
      backLink="/transactions"
      onlyCreateMode={true}
      fuzzyErrors={fuzzyErrors}
      additionalInfoText={additionalInfoText}
      entityName={entityName}
      isLoading={submittingForm}
      handleSubmit={handleSubmit}
      getColumnsWithCsvUploadState={getColumnsWithCsvUploadState}
      defaultMapperWithCreateOrUpdateMode={() => defaultMapper}
      csvColumnsTransformations={DEFAULT_TRANSACTIONS_TRANSFORMATIONS}
    />
  );
};
