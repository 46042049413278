import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { AppContext } from 'AppContext';
import { fillCellsFromFuzzyResults } from 'shared/CsvUploadV2/utils';
import { setNestedObjectValues } from 'formik';
import { useCSVImportsAPI } from 'api/csvImports';
import { DEFAULT_SPREADS_MAPPER } from './consts';
import { SpreadsCsvUploadV2 } from './SpreadsCsvUploadV2';
import { INTERNAL_TRANSACTION_ID_SOURCE, SOURCE_TYPES } from '../TransactionsCsvUpload/consts';

dayjs.extend(utc);

export const EventBasedSpreadsCsvUploadV2 = () => {
  const { orgId } = useContext(AppContext);

  const {
    operations: { uploadCSV },
  } = useCSVImportsAPI({
    orgId,
    autoFetch: false,
  });

  const history = useHistory();

  const [submittingForm, setSubmittingForm] = useState(false);
  const [fuzzyErrors, setFuzzyErrors] = useState();

  // `customerIdSourceAndType` (and `transactionsIdSourceAndType`) has object type
  // Example: { type: SOURCE_TYPES.INTERNAL, source: 'User ID' };
  const [transactionsIdSourceAndType, setTransactionsIdSourceAndType] = useState({
    type: SOURCE_TYPES.INTERNAL,
    source: INTERNAL_TRANSACTION_ID_SOURCE,
  });

  const handleSubmit = async ({ values, extraData, formRef }) => {
    setSubmittingForm(true);

    const spreadsForCreate = values?.map((spread) => {
      return {
        status: 'ACTIVE',
        amount: spread?.amount,
        date: dayjs(spread?.date).utc(true).format('YYYY-MM-DD'),
        seats: spread?.seats ?? 0,
        transaction_id: spread?.transaction_id,
        customer_id: spread?.customer_id,
      };
    });

    try {
      const result = await uploadCSV.mutateAsync({
        rawFile: extraData,
        data: spreadsForCreate,
        settings: {
          entity: extraData.entity,
          orgId,
          transactionsIdSourceAndType,
          useBackendValidation: true,
        },
      });

      if (result?.errors) {
        if (result?.errors?.fuzzyErrors) {
          setFuzzyErrors(result?.errors?.fuzzyErrors);
          fillCellsFromFuzzyResults({ fuzzyErrors: result?.errors?.fuzzyErrors, formRef });
        } else {
          setFuzzyErrors(null);
        }
        if (Object.keys(result?.errors).length > 0) {
          formRef?.current?.setErrors(result.errors);
          formRef?.current?.setTouched?.(setNestedObjectValues(result.errors, true));
        }
        setSubmittingForm(false);
      } else {
        setTimeout(() => {
          history.push('/spreads');
          setSubmittingForm(false);
        }, 1000);
      }
    } catch (error) {
      setSubmittingForm(false);
    }
  };

  return (
    <SpreadsCsvUploadV2
      transactionsIdSourceAndType={transactionsIdSourceAndType}
      setTransactionsIdSourceAndType={setTransactionsIdSourceAndType}
      handleSubmit={handleSubmit}
      submittingForm={submittingForm}
      fuzzyErrors={fuzzyErrors}
      entityName="spreads"
      defaultMapper={DEFAULT_SPREADS_MAPPER}
      eventRecognitionsOnly={true}
    />
  );
};
