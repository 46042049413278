import React, { useContext, useCallback, useMemo, useState, useEffect } from 'react';

import { AppContext } from 'AppContext';
import { Row, Spacer, TextBox } from 'components/Core';
import { TransactionSingleContent, TRANSACTION_MODAL_MODE } from 'shared/TransactionContent';
import { generateColumns } from './transactionColumns';
import { PreviewTable } from './PreviewTable';

const TransactionPreviewTable = ({ data = [], setData, setDataHook }) => {
  const [tableData, setTableData] = useState(data);
  const [modalTransactionIndex, setModalTransactionIndex] = useState(null);

  const updateTableData = useCallback(
    ({ data }) => {
      const updatedTableData = [...tableData];
      updatedTableData[modalTransactionIndex] = data;
      delete updatedTableData[modalTransactionIndex].product_confidence;
      delete updatedTableData[modalTransactionIndex].customer_confidence;
      setTableData(updatedTableData);
      setData(updatedTableData);
    },
    [tableData, modalTransactionIndex, setData],
  );

  // for updating missing customer warning and isCreated
  useEffect(() => {
    setTableData((prevTableData) =>
      prevTableData.map((value, index) => {
        return {
          ...value,
          missingCustomer: data[index].missingCustomer && !value.customer_id,
          isCreated: data[index].isCreated,
        };
      }),
    );
  }, [data]);

  const {
    organizations,
    appSettings: { isARR, currencyISOCode },
  } = useContext(AppContext);

  const columns = useMemo(
    () =>
      generateColumns({
        onTransactionClick: (row) => {
          setModalTransactionIndex(row.index);
        },
        onCustomerClick: () => {},
        isARR,
        currencyISOCode,
      }),
    [isARR, currencyISOCode],
  );

  const selectedRowData = useMemo(() => {
    return tableData[modalTransactionIndex];
  }, [tableData, modalTransactionIndex]);

  return (
    <PreviewTable
      entityName="transactions"
      columns={columns}
      tableData={tableData}
      setDataHook={setDataHook}
      title={
        <Row horizontal="flex-start">
          <TextBox bold fontSize={'18px'}>
            Review the
          </TextBox>
          <Spacer width="5px" />
          <TextBox bold italic opacity="0.5" fontSize={'18px'}>
            transactions
          </TextBox>
          <Spacer width="8px" />
          <TextBox bold fontSize={'18px'}>
            that we'll create
          </TextBox>
        </Row>
      }
    >
      {modalTransactionIndex !== null && (
        <TransactionSingleContent
          transaction={selectedRowData}
          mode={TRANSACTION_MODAL_MODE.CREATE}
          saveNewTransaction={false}
          organization={organizations[0]}
          closeModal={() => setModalTransactionIndex(null)}
          onTransactionCreated={({ transactionData }) => {
            updateTableData({ data: transactionData });
          }}
        />
      )}
    </PreviewTable>
  );
};

export { TransactionPreviewTable };
