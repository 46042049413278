import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { setNestedObjectValues } from 'formik';
import { fillCellsFromFuzzyResults } from 'shared/CsvUploadV2/utils';

import { AppContext } from 'AppContext';
import { useCSVImportsAPI } from 'api/csvImports';
import { ACCOUNTING_SPREAD_REASON } from 'views/Spreads/utils';
import { INTERNAL_TRANSACTION_ID_SOURCE, SOURCE_TYPES } from '../TransactionsCsvUpload/consts';
import { SpreadsCsvUploadV2 } from '../EventBasedSpreadsCsvUploadV2/SpreadsCsvUploadV2';
import { DEFAULT_ACCOUNTING_SPREADS_MAPPER } from './consts';

dayjs.extend(utc);

export const AccountingSpreadsCsvUploadV2 = () => {
  const { orgId } = useContext(AppContext);

  const {
    operations: { uploadCSV },
  } = useCSVImportsAPI({ orgId, autoFetch: false });

  const history = useHistory();

  const [submittingForm, setSubmittingForm] = useState(false);
  const [fuzzyErrors, setFuzzyErrors] = useState();

  // `customerIdSourceAndType` (and `transactionsIdSourceAndType`) has object type
  // Example: { type: SOURCE_TYPES.INTERNAL, source: 'User ID' };
  const [transactionsIdSourceAndType, setTransactionsIdSourceAndType] = useState({
    type: SOURCE_TYPES.INTERNAL,
    source: INTERNAL_TRANSACTION_ID_SOURCE,
  });

  const handleSubmit = async ({ values, extraData, formRef }) => {
    setSubmittingForm(true);

    const accountingSpreads = values?.map((row) => {
      return {
        transaction_id: row?.transaction_id,
        amount: row?.amount,
        date: dayjs.utc(row?.date).format('YYYY-MM-DD'),
        reason: ACCOUNTING_SPREAD_REASON.OVERRIDE_AMOUNT,
      };
    });

    try {
      const result = await uploadCSV.mutateAsync({
        rawFile: extraData,
        data: accountingSpreads,
        settings: {
          entity: extraData.entity,
          orgId,
          transactionsIdSourceAndType,
          useBackendValidation: true,
        },
      });

      if (result?.errors) {
        if (result?.errors?.fuzzyErrors) {
          setFuzzyErrors(result?.errors?.fuzzyErrors);
          fillCellsFromFuzzyResults({ fuzzyErrors: result?.errors?.fuzzyErrors, formRef });
        } else {
          setFuzzyErrors(null);
        }
        if (Object.keys(result?.errors).length > 0) {
          formRef?.current?.setErrors(result.errors);
          formRef?.current?.setTouched?.(setNestedObjectValues(result.errors, true));
        }
        setSubmittingForm(false);
      } else {
        setTimeout(() => {
          history.push('/transactions');
          setSubmittingForm(false);
        }, 1000);
      }
    } catch (error) {
      setSubmittingForm(false);
    }
  };

  return (
    <SpreadsCsvUploadV2
      transactionsIdSourceAndType={transactionsIdSourceAndType}
      setTransactionsIdSourceAndType={setTransactionsIdSourceAndType}
      fuzzyErrors={fuzzyErrors}
      additionalInfoText="Update Accounting Spreads for transactions. Existing accounting spreads for transactions will be deleted and replaced by uploaded accounting spreads."
      handleSubmit={handleSubmit}
      submittingForm={submittingForm}
      entityName="accounting-spreads"
      defaultMapper={DEFAULT_ACCOUNTING_SPREADS_MAPPER}
      eventRecognitionsOnly={false}
    />
  );
};
