import { useCallback, useContext, useMemo, useState } from 'react';
import styled from 'styled-components';
import dayjs from 'dayjs';

import { Flexer, FlexerColumn, Spacer } from 'components/Core';
import {
  Modal,
  ModalBody,
  ModalButton,
  ModalCloseIcon,
  ModalContainer,
  ModalFooter,
  ModalHeader,
  ModalTitle,
  ModalTitleText,
} from 'components/Modal';
import { INVOICE_MAGIC_METADATA } from 'views/Billing/consts';
import { SwitchWithLabel } from 'components/Controls';
import { numberFormatter } from 'utils/formatters';
import { NUMBER_FORMATS } from 'consts/global';
import { AppContext } from 'AppContext';
import { TooltipContainer } from 'components/Tooltip';
import { HelpCircleIcon } from 'components/Icons';
import { getAutoChargeJobRunsAt, getInvoicesTotalAmount, isPastInvoiceAutoCharge } from 'views/Billing/utils';

import { InvoicingScheduleContext } from '../InvoicingScheduleContext';

const ChargeSection = styled(FlexerColumn)`
  border-radius: 8px;
  border: 1px solid var(--neutralGray);
  background: var(--Light-100, #fff);
  box-shadow: 8px 8px 60px 0px var(--primaryBlack4);
  font-size: 12px;
  max-height: 350px;
  overflow: auto;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
`;

const ChargeRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid var(--neutralGray);
  padding: 12px;
  width: 100%;

  &:last-child {
    border-bottom: none;
  }
`;

export const AutoChargeModal = ({ onClose, setFieldValue, onSettingsClose }) => {
  const {
    appSettings: { currencyISOCode: defaultOrgCurrency },
  } = useContext(AppContext);
  const {
    invoicingScheduleFormValues,
    invoiceFormRef,
    invoiceFormValues,
    scheduleFormRef,
    isScheduleDraft,
    customerDetails,
  } = useContext(InvoicingScheduleContext);

  const autoChargeJobRunsAt = getAutoChargeJobRunsAt();

  const invoicingSchedule = invoicingScheduleFormValues;

  const [autoChargePastInvoicesIds, setAutoChargePastInvoicesIds] = useState(
    invoicingScheduleFormValues?.invoices
      ?.filter((invoice) => isPastInvoiceAutoCharge({ invoice }))
      ?.map((invoice) => invoice?.id ?? invoice?.unsavedId),
  );

  const handleAutoCharge = async () => {
    try {
      invoiceFormRef?.current?.setFieldValue('metadata', {
        ...invoiceFormValues?.metadata,
        [INVOICE_MAGIC_METADATA.DISABLE_AUTO_CHARGE]: autoChargePastInvoicesIds?.includes(
          invoiceFormValues?.id ?? invoiceFormValues?.unsavedId,
        )
          ? false
          : undefined,
      });

      const invoicesToBulkEdit = invoicingScheduleFormValues?.invoices?.map((invoice) => ({
        ...invoice,
        metadata: {
          ...invoice.metadata,
          [INVOICE_MAGIC_METADATA.DISABLE_AUTO_CHARGE]: autoChargePastInvoicesIds?.includes(
            invoice?.id ?? invoice?.unsavedId,
          )
            ? false
            : undefined,
        },
      }));

      if (setFieldValue) {
        setFieldValue('auto_charge', true);
        setFieldValue('auto_send', false);
        setFieldValue('invoices', invoicesToBulkEdit);

        if (!isScheduleDraft) {
          scheduleFormRef?.current?.submitForm();
        }

        !!onSettingsClose && onSettingsClose();
      }
      onClose();
    } catch (e) {
      console.error({ message: e.message, component: 'useAutoChargeModal.js', stack: e });
    }
  };

  const pastInvoices = useMemo(
    () =>
      invoicingScheduleFormValues?.invoices?.filter(
        (invoice) =>
          !invoice.paid_at &&
          !invoice.voided_at &&
          (!customerDetails?.has_active_payment_method || invoice?.metadata?.payment_status !== 'processing') &&
          dayjs.utc(invoice?.date).isBefore(dayjs.utc().startOf('day')),
      ) ?? [],
    [customerDetails?.has_active_payment_method, invoicingScheduleFormValues?.invoices],
  );

  return (
    <ModalContainer>
      <Modal maxWidth="540px" height="auto">
        <ModalHeader>
          <ModalTitle>
            <ModalTitleText>
              <b>Charge for past unpaid invoices</b>
            </ModalTitleText>
          </ModalTitle>
          <ModalCloseIcon onClose={onClose} />
        </ModalHeader>

        <Spacer height="20px" />

        <ModalBody>
          <div>Confirm that you want to charge the customer's credit card now for the past unpaid invoices below.</div>

          <div>
            Subscript will re-save all invoices, and your customer's credit card will be auto-charged on the following
            day at {autoChargeJobRunsAt.format('ha (z)')}
          </div>

          <Spacer height="5px" />

          <ChargeSection>
            <ChargeRow>
              <div>{customerDetails?.name}</div>
              <Flexer gap="4px" alignItems="center">
                <TooltipContainer
                  toolTipContent={`past invoices will be charged on the following day at ${autoChargeJobRunsAt.format(
                    'ha (z)',
                  )}`}
                  tooltipWrapperStyles={{
                    display: 'flex',
                  }}
                  fontSize="12px"
                  width={200}
                >
                  <HelpCircleIcon />
                </TooltipContainer>
              </Flexer>
            </ChargeRow>

            <Flexer gap="4px" wrapRow>
              {pastInvoices.map((invoice) => (
                <ChargeRow>
                  <SwitchWithLabel
                    checked={autoChargePastInvoicesIds.includes(invoice?.id ?? invoice?.unsavedId)}
                    onChange={() => {
                      if (autoChargePastInvoicesIds.includes(invoice?.id ?? invoice?.unsavedId)) {
                        setAutoChargePastInvoicesIds(
                          autoChargePastInvoicesIds.filter((id) => id !== (invoice?.id ?? invoice?.unsavedId)),
                        );
                      } else {
                        setAutoChargePastInvoicesIds([...autoChargePastInvoicesIds, invoice?.id ?? invoice?.unsavedId]);
                      }
                    }}
                    labelSize="12px"
                    name={`billing__invoice-schedule-modal__auto-charge-modal--charge-past-invoice-${dayjs
                      .utc(invoice?.date)
                      .format('MMM DD, YYYY')}`}
                    bolded
                    label={`For invoice on ${dayjs.utc(invoice.date).format('MMM DD, YYYY')}`}
                  />

                  {numberFormatter({
                    type: NUMBER_FORMATS.CURRENCY,
                    currency: invoicingSchedule?.currency ?? defaultOrgCurrency,
                    rawValue: invoice.amount ?? 0,
                    decimalPlaces: 2,
                  })}
                </ChargeRow>
              ))}

              <ChargeRow>
                <div></div>

                <div>
                  <span style={{ opacity: 0.5, marginRight: 10 }}>Total amount:</span>

                  {numberFormatter({
                    type: NUMBER_FORMATS.CURRENCY,
                    currency: invoicingSchedule?.currency ?? defaultOrgCurrency,
                    rawValue: getInvoicesTotalAmount({ invoices: pastInvoices }) ?? 0,
                    decimalPlaces: 2,
                  })}
                </div>
              </ChargeRow>
            </Flexer>
          </ChargeSection>
        </ModalBody>

        <Spacer height="20px" />

        <ModalFooter flexEnd>
          <ModalButton data-cy="auto-charge-modal__cancel-button" onClick={onClose} fontWeight={700}>
            Cancel
          </ModalButton>
          <ModalButton data-cy="auto-charge-modal__confirm-button" primary onClick={handleAutoCharge} fontWeight={700}>
            Update auto-charge
          </ModalButton>
        </ModalFooter>
      </Modal>
    </ModalContainer>
  );
};

export const useAutoChargeModal = (props) => {
  const [showModal, setShowModal] = useState(false);

  const openModal = useCallback(() => {
    setShowModal(true);
  }, []);

  const closeModal = useCallback(() => {
    setShowModal(false);
  }, []);

  const Modal = useCallback(() => (showModal ? <AutoChargeModal {...props} onClose={closeModal} /> : null), [
    closeModal,
    props,
    showModal,
  ]);

  return {
    openModal,
    Modal,
    isVisible: showModal,
  };
};
