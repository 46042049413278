import dayjs from 'dayjs';
import React from 'react';
import { PresetOption, PresetsWrapper } from './styles';

// customPresets has the following format:
// [
//   {
//     date: '2024-01-01',
//     title: 'Custom preset 1',
//   },
// ]
export const Presets = ({ selected, presetStartDate, customPresets, onOptionClick }) => {
  const formattedPresetStart = dayjs(presetStartDate).format('MMM D, YYYY');

  const options = [
    {
      value: 1,
      unit: 'month',
    },
    {
      value: 3,
      unit: 'month',
    },
    {
      value: 6,
      unit: 'month',
    },
    {
      value: 1,
      unit: 'year',
    },
    {
      value: 2,
      unit: 'year',
    },
  ];

  return (
    <PresetsWrapper>
      {customPresets
        ? customPresets.map(({ date, title }) => {
            return (
              <PresetOption
                key={`${date}-${title}`}
                selected={dayjs(selected).isSame(dayjs(date), 'day')}
                onClick={() => onOptionClick(dayjs(date).toDate())}
              >
                {title}
                <span>{dayjs(date).format('MMM D, YYYY')}</span>
              </PresetOption>
            );
          })
        : options.map(({ value, unit }) => {
            const optionDate = dayjs(presetStartDate).add(value, unit).subtract(1, 'day');
            return (
              <PresetOption
                key={`${value}${unit}`}
                selected={dayjs(selected).isSame(optionDate)}
                onClick={() => onOptionClick(optionDate.toDate())}
                data-cy={`custom-date-picker--presets__option-${unit}-${value}`}
              >
                {`+${value} ${unit}${value > 1 ? 's' : ''}`}
                <span>{`${formattedPresetStart} – ${optionDate.format('MMM D, YYYY')}`}</span>
              </PresetOption>
            );
          })}
    </PresetsWrapper>
  );
};
