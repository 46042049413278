import React from 'react';
import styled from 'styled-components';
import { ReactComponent as GreyDot } from 'images/medium-grey-dot.svg';
import { useDebouncedSearchBar } from 'components/Blocks';
import { CentererVertical, Flexer, FlexerColumn } from 'components/Core';
import { CircleLoader } from 'components/Loaders';
import { SearchContainer } from 'shared/TransactionTable/styles';
import { RowsCount } from 'components/Table';
import { PaginationButton } from 'components/Buttons';

const Wrapper = styled(FlexerColumn)`
  width: ${({ width }) => width};
`;

const TableWrapper = styled.div`
  background-color: var(--secondaryGray);
  padding: 12px 40px 40px 40px;
`;

const LoadingSpinnerWrapper = styled(Flexer)`
  position: relative;
  margin-right: 45px;
  height: 24px;
`;

const TableHeaderActionsWrapper = styled(Flexer)`
  margin-top: 0px;
  padding: 8px 40px;
  justify-content: space-between;
  align-items: center;
  box-shadow: 2px 20px 48px rgba(0, 21, 46, 0.028);
  border-top: 1px solid var(--primaryBlack3);
  border-bottom: 1px solid var(--primaryBlack3);
`;

export const TableContainerLayout = ({
  setSearchQuery,
  isFetching,
  columnsSettingsComponent,
  rowsCount,
  children,
  width,
  currentPageIndex,
  setCurrentPageIndex,
  pageCount,
  placeholder,
  initialValue,
}) => {
  const { DebouncedSearchBar } = useDebouncedSearchBar({
    fontSize: '12px',
    onSearchQueryChange: setSearchQuery,
    initialValue: initialValue ?? '',
    placeholder: placeholder ?? 'Search by customer name',
    width: '450px',
  });

  return (
    <Wrapper width={width}>
      <TableHeaderActionsWrapper>
        <SearchContainer>
          <DebouncedSearchBar data-cy="search-input" />
        </SearchContainer>

        <CentererVertical>
          {isFetching && (
            <LoadingSpinnerWrapper>
              <CircleLoader isAbsolute isInline width="24px" height="24px" thickness="4px" name="invoices-table" />
            </LoadingSpinnerWrapper>
          )}

          <RowsCount>
            <span data-cy="invoices-table__row-count">{rowsCount} rows found</span>
          </RowsCount>

          <GreyDot style={{ marginLeft: 20, marginRight: 20, minWidth: 4, minHeight: 4 }} />

          {setCurrentPageIndex && (
            <>
              <PaginationButton
                pageIndex={currentPageIndex}
                canPreviousPage={currentPageIndex > 1}
                canNextPage={currentPageIndex < pageCount}
                pageCount={pageCount}
                nextPage={() => setCurrentPageIndex(currentPageIndex + 1)}
                previousPage={() => setCurrentPageIndex(currentPageIndex - 1)}
              />
              <GreyDot style={{ marginLeft: 20, marginRight: 20, minWidth: 4, minHeight: 4 }} />
            </>
          )}

          {columnsSettingsComponent}
        </CentererVertical>
      </TableHeaderActionsWrapper>
      <TableWrapper>{children}</TableWrapper>
    </Wrapper>
  );
};
