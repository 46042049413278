import { TextBox } from 'components/Core';
import { TooltipContainer } from 'components/Tooltip';
import styled from 'styled-components';
import { capitalize, SEARCH_CONFIDENCE } from 'utils/stringUtils';

export const getUniqueAttachments = ({ contracts }) => {
  const allAttachments = contracts?.flatMap((contract) => contract.attachments ?? []) ?? [];
  const uniqueAttachments = Array.from(
    new Map(allAttachments.map((attachment) => [attachment['name'], attachment])).values(),
  );
  return uniqueAttachments.sort((a, b) => new Date(a.uploaded_at) - new Date(b.uploaded_at));
};

const FuzzyName = styled.span`
  color: ${({ textColor }) => textColor ?? 'black'};
`;

export const displayInterpretedValue = ({ entityName, currentValue, interpretedValue, title, confidence, onClick }) => {
  if (!currentValue) {
    return <TextBox color="var(--primaryBlack50)">{title}</TextBox>;
  }

  let textColor;
  let toolTipContent;
  switch (confidence) {
    case SEARCH_CONFIDENCE.EXACT:
      textColor = 'var(--primaryGreen)';
      toolTipContent = `Found a ${entityName} with the exact name "${interpretedValue}" (AI interpreted from contract)`;
      break;
    case SEARCH_CONFIDENCE.FUZZY:
      textColor = 'var(--primaryYellow)';
      toolTipContent = `Found a ${entityName} with a similar name to "${interpretedValue}" (AI interpreted from contract)`;
      break;
    case SEARCH_CONFIDENCE.NO_MATCH:
      textColor = 'var(--primaryRed)';
      toolTipContent = `Could not find a ${entityName} with a similar name to "${interpretedValue}" (AI interpreted from contract). ${capitalize(
        entityName,
      )} is required`;
      break;
    default:
      textColor = 'black';
      toolTipContent = undefined;
  }
  return (
    <TooltipContainer toolTipContent={toolTipContent} width={300} isVisible={!!toolTipContent}>
      <FuzzyName textColor={textColor} data-cy={`${entityName}-table__row__customer-cell`} onClick={onClick}>
        {title}
      </FuzzyName>
    </TooltipContainer>
  );
};
