import { useContext, useState } from 'react';
import { useFormikContext } from 'formik';
import { AppContext } from 'AppContext';
import { FormikCustomSelector } from 'components/Controls';
import { ProductActionsModal } from 'shared/ProductActionsModal';
import { TransactionContext } from '../TransactionContext';
import { TRANSACTION_MODAL_MODE } from '../consts';
import { getOriginalField, SYMBOL_getOriginalField } from '../utils';

export const TransactionProductField = () => {
  const { addProduct } = useContext(AppContext);
  const { organization, transactionFormValues, currentTransaction, mode, changedDataFormatted } = useContext(
    TransactionContext,
  );
  const { setFieldValue } = useFormikContext();
  const [draftCreateProductName, setDraftCreateProductName] = useState('');
  const [showProductActionsModal, setShowProductActionsModal] = useState(false);

  const productDropdownOptions = () => {
    if (organization?.products) {
      return organization.products.map((productObject) => ({
        label: productObject.name,
        value: productObject.id,
        recognition: productObject.recognition,
      }));
    } else {
      return [{ label: currentTransaction.product_name, value: currentTransaction.product_id }];
    }
  };

  const createAndSetNewProduct = (productName) => {
    setDraftCreateProductName(productName);
    setShowProductActionsModal(true);
  };

  const isChanged = (key) =>
    mode === TRANSACTION_MODAL_MODE.EXTERNAL_UPDATE
      ? getOriginalField({ key, data: changedDataFormatted }) !== SYMBOL_getOriginalField
      : undefined;

  const getTooltipLabel = () => {
    return mode === TRANSACTION_MODAL_MODE.EXTERNAL_UPDATE && isChanged('product_id') ? (
      <>
        <div>Old value:</div>
        <div>{changedDataFormatted['product_name']?.original || 'No previous value'}</div>
      </>
    ) : undefined;
  };

  return (
    <>
      <FormikCustomSelector
        value={{
          label: transactionFormValues?.product_name,
          value: {
            name: transactionFormValues?.product_name,
            id: transactionFormValues?.product_id,
          },
        }}
        boldValue
        creatable
        isClearable={true}
        label="Product"
        placeholder="Select product"
        name="product_id"
        handleChange={(option) => {
          if (option) {
            setFieldValue('product_id', option.value);
            setFieldValue('product_name', option.label);
            if (option.recognition && !transactionFormValues?.recognition) {
              setFieldValue('recognition', option.recognition);
            }
          } else {
            setFieldValue('product_id', null);
            setFieldValue('product_name', null);
          }
        }}
        options={productDropdownOptions()}
        onCreateOption={createAndSetNewProduct}
        isChanged={isChanged('product_id')}
        tooltipInputDisplay={getTooltipLabel()}
      />
      {showProductActionsModal && (
        <ProductActionsModal
          action="create"
          product={{ name: draftCreateProductName }}
          onClose={(createdProduct) => {
            if (createdProduct) {
              setFieldValue('product_id', createdProduct.id);
              setFieldValue('product_name', createdProduct.name);
              addProduct({ product: createdProduct });
            }
            setShowProductActionsModal(false);
          }}
        />
      )}
    </>
  );
};
