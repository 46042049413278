import { ReactComponent as ExternalLink } from 'images/external-link-billing.svg';
import { Flexer, Row } from 'components/Core';
import styled, { css } from 'styled-components';
import { Input } from 'antd';

export const TransactionName = styled.div`
  font-weight: 700;
`;

export const Wrapper = styled(Flexer)`
  font-size: 12px;
  flex-direction: column;
  line-height: 16px;
  width: 100%;
`;

export const TransactionAmount = styled.div`
  font-weight: 400;
  font-size: 12px;
  width: 100px;
  text-align: end;
  line-height: 16px;
`;

export const AllocationAmount = styled.div`
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  color: var(--primaryBlack75);

  span {
    margin-left: 30px;
  }
`;

export const TransactionTitleRow = styled(Row)`
  justify-content: space-between;
`;

export const StyledExternalLink = styled(ExternalLink)`
  width: 20px;
  height: 20px;
  cursor: pointer;
`;

export const TransactionDates = styled.div`
  color: var(--primaryBlack50);
  width: 150px;
  white-space: nowrap;
`;

export const AllocationRow = styled(Row)`
  justify-content: space-between;
  align-items: center;
  margin-top: 8px;
  padding: 4px;
  padding-right: 8px;
  border-radius: 4px;
  background: var(--primaryBlack2);
`;

export const AllocationInputWrapper = styled.div`
  position: relative;
  width: 125px;

  &::after {
    content: '%';
    position: absolute;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 16px;
    color: var(--primaryBlue);
    right: 8px;
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none;
  }

  &::before {
    content: 'Allocate';
    position: absolute;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 16px;
    color: var(--primaryBlue);
    left: 8px;
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none;
  }
`;

export const AllocationInput = styled(Input)`
  width: 100%;
  text-align: right;
  border-radius: 4px;
  border: none;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
  color: var(--primaryBlue);
  background-color: var(--primaryBlue10);
  padding-right: 8px;
`;

export const AllocationSubtitle = styled.div`
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  color: var(--primaryBlack75);
  margin-left: 4px;
`;

export const CustomSelector = styled.div`
  padding: 8px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  border: 1px solid var(--primaryBlack10);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  cursor: pointer;
  line-height: 16px;
  min-width: 200px;

  ${({ disabled }) =>
    disabled &&
    css`
      pointer-events: none;
      opacity: 0.5;
    `}

  &:hover {
    border-color: var(--primaryBlack20);
  }
`;

export const SelectedItem = styled.div`
  display: flex;
  align-items: center;
`;

export const Counter = styled.div`
  font-size: 9px;
  font-style: normal;
  font-weight: 900;
  line-height: 12px; /* 133.333% */
  text-transform: uppercase;
  width: 16px;
  min-width: 16px;
  height: 16px;
  border-radius: 100px;
  border: 1px solid var(--primaryBlack10);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 4px;
`;

export const RedPlaceholder = styled.div`
  color: ${({ black, notTransactionItemRow }) =>
    black ? 'var(--primaryBlack)' : notTransactionItemRow ? 'var(--primaryBlack)' : 'var(--primaryRed)'};
`;
