export const convertToDecimals = (allocations) => {
  return Object.entries(allocations ?? {}).reduce((acc, [transactionId, allocation]) => {
    const value = isNaN(allocation) ? 0 : allocation;
    acc[transactionId] = value / 100;
    return acc;
  }, {});
};

export const convertToPercentage = (allocations) => {
  return Object.entries(allocations ?? {}).reduce((acc, [transactionId, allocation]) => {
    const value = isNaN(allocation) ? 0 : allocation;
    acc[transactionId] = value * 100;
    return acc;
  }, {});
};
