import { SERVICE_WITHOUT_EMAIL_CC, SERVICE_WITHOUT_EMAIL_BCC } from 'views/Billing/consts';
import { FormikEmailSelector } from 'components/Controls';

import { humanize } from 'utils/stringUtils';

export const FormikEmailInput = ({ name, label, value, onChange, sendEmailFromSubscript, invoicingServices }) => {
  const isCC = name.includes('.email_CC');
  const serviceWithoutEmail = isCC ? SERVICE_WITHOUT_EMAIL_CC : SERVICE_WITHOUT_EMAIL_BCC;

  const unsupportedServices = serviceWithoutEmail.filter((service) => invoicingServices.includes(service));
  const unsupportedServicesCount = unsupportedServices.length;

  const unsupportedLabel =
    unsupportedServicesCount > 0
      ? ` (not supported by ${unsupportedServices
          .map((service) => humanize(service))
          .join(
            unsupportedServicesCount === 1 ? '' : unsupportedServicesCount === 2 ? ' and ' : ', ',
          )} on initial invoicing email, only for reminders)`
      : '';

  return (
    <FormikEmailSelector
      name={name}
      label={`${label}${!sendEmailFromSubscript ? unsupportedLabel : ''}`}
      value={value}
      onChange={onChange}
      smallVersion
    />
  );
};
