import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { cssVar, transparentize } from 'polished';

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: ${(props) => props.vertical || 'center'};
  justify-content: ${(props) => props.horizontal || 'center'};
  flex-wrap: ${(props) => props.wrap || 'nowrap'};
  gap: ${(props) => props.gap};
`;

export const Column = styled.div`
  display: flex;
  gap: ${(props) => props.gap};
  height: 100%;
  width: 100%;
  flex-direction: column;
  align-items: ${(props) => props.horizontal || 'center'};
  justify-content: ${(props) => props.vertical || 'center'};
`;

export const BlueText = styled.span`
  color: var(--primaryBlue);
  font-weight: ${({ bold }) => (bold ? '900' : '400')};
`;

export const AdditionalPurpleText = styled.span`
  color: var(--additionalPurple);
`;

export const InlineLink = styled(Link)`
  cursor: pointer;
  font-weight: 700;
  font-size: 14px;
  color: var(--primaryBlue);
`;

export const NumberDot = styled.div`
  width: 24px;
  height: 24px;
  text-align: center;
  line-height: 24px;
  font-weight: 900;
  font-size: ${({ fontSize }) => (fontSize ? fontSize : '12px')};
  background: ${({ backgroundColor }) => (backgroundColor ? backgroundColor : 'var(--primaryBlack)')};
  border-radius: 100px;
  margin-right: ${({ lessMargin }) => (lessMargin ? '5px' : '12px')};
  color: ${({ color }) => (color ? color : 'white')};
`;

const BREAKPOINTS = {
  mobile: 576,
  tablet: 768,
  giantHeight: 930,
  desktop: 992,
  giant: 1440,
  xl: 1600,
};

export const ModalListItem = styled.span`
  font-size: ${(props) => (props.transactionType ? '14px' : '16px')};
  line-height: ${(props) => (props.transactionType ? '18px' : '20px')};
  text-align: ${(props) => (props.transactionType ? 'center' : 'left')};
  cursor: pointer;
  font-weight: bold;
  padding: ${(props) => (props.transactionType ? '10px 20px' : '6px 8px')};
  background-color: ${(props) => (props.isActive ? 'var(--accentGray)' : 'initial')};
  color: ${(props) => (props.isActive ? ' black' : 'var(--primaryBlue)')};
  pointer-events: ${(props) => props.isActive && 'none'};
  border-radius: 8px;
  margin-bottom: 10px;

  &:last-child {
    margin-bottom: 0;
  }

  &:hover {
    background-color: ${(props) => (props.transactionType ? 'var(--accentGray)' : 'var(--primaryBlue10)')};
    color: ${(props) => (props.transactionType ? 'var(--primaryBlack)' : 'var(--primaryBlue)')};
    border-radius: 8px;
  }
`;

export const HeaderSubtext = styled.div`
  display: flex;
  align-items: center;

  padding: 8px 14px;
  background: #ffffff;
  color: ${(props) => (props.active ? 'var(--primaryBlack)' : 'var(--primaryGreen)')};
  border: 1px solid ${(props) => (props.active ? 'var(--accentGrayThird)' : 'var(--accentGraySecond)')};
  box-sizing: border-box;
  box-shadow: 4px 4px 28px var(--primaryBlack5);
  border-radius: 100px;

  font-weight: bold;
  font-size: 14px;
  line-height: 18px;

  cursor: pointer;
  transition: all ease 0.4s;

  p {
    font-weight: 900;
    font-size: 10px;
    line-height: 14px;
    text-transform: uppercase;
    margin-top: 2px;
    margin-bottom: 0;
  }

  &:hover {
    color: var(--primaryBlack);
    border: 1px solid var(--accentGrayThird);
  }
`;

export const HeaderLeftContent = styled.div`
  padding-right: ${(props) => !props.soloView && '10px'};
  margin-right: ${(props) => !props.soloView && '10px'};
  border-right: ${(props) => !props.soloView && '1px solid var(--primaryBlack10)'};
  font-size: 16px;
  line-height: 20px;
`;

export const SmallItemsContainer = styled.div`
  display: flex;
  flex-direction: column;

  background: rgba(0, 21, 46, 0.02);
  padding: 10px;
  border-radius: 10px;
`;

export const BigItemsContainer = styled.div`
  display: flex;
  flex-direction: column;
  white-space: nowrap;
  max-height: 310px;
  overflow: auto;
  padding-right: ${(props) => !props.soloView && '20px'};
  margin-right: ${(props) => !props.soloView && '20px'};
  border-right: ${(props) => !props.soloView && '1px solid var(--primaryBlack10)'};
`;

export const SmallModalListItem = styled(ModalListItem)`
  font-size: 14px;
  line-height: 18px;
  font-weight: bold;
`;

export const ModalItemsSubtitle = styled.span`
  color: var(--primaryBlack);
  font-weight: 900;
  font-size: 10px;
  line-height: 14px;
  opacity: 0.3;
  margin-bottom: 20px;
  text-transform: uppercase;
`;

export const TableHeading = styled.h1`
  font-weight: 900;
  margin-bottom: 0;
  font-size: 24px;
  display: flex;
  align-items: center;
  position: relative;
  line-height: 38px;
`;

export const TableBtn = styled.button`
  display: flex;
  align-items: center;
  white-space: nowrap;
  color: #fff;
  font-family: 'Nunito Sans';
  cursor: pointer;
  background: var(--primaryGreen);
  box-shadow: 0px 4px 20px rgba(82, 181, 120, 0.2);
  border-radius: 20px;
  height: 40px;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  padding: 10px 16px;
  border: none;
  pointer-events: ${(props) => (props.isDisabled ? 'none' : '')};
  opacity: ${(props) => (props.isDisabled ? '0.3' : '1')};

  img {
    margin-right: 7px;
  }
`;

// iterate through the sizes and create a media template
export const mediaQuery = (queryType) =>
  Object.keys(BREAKPOINTS).reduce((accumulator, label) => {
    const size = BREAKPOINTS[label];
    accumulator[label] = (...args) => css`
      @media (${queryType}: ${size}px) {
        ${css(...args)};
      }
    `;
    return accumulator;
  }, {});

export const LabelText = styled.div`
  color: ${transparentize(0.4, cssVar('--primaryDark', '#00152e'))};
  font-size: 12px;
  line-height: 16px;
  margin-bottom: 8px;
`;
