import React, { useState } from 'react';
import styled from 'styled-components';
import { Formik } from 'formik';
import { Checkbox } from 'antd';
import { isNil } from 'lodash';
import { useContractAPI } from 'api/contracts/hooks';
import { ReactComponent as QuestionIcon } from 'images/related-help.svg';
import { FlexEndContainer, Spacer, FlexerColumn, CentererVertical, FlexerRow } from 'components/Core';
import { FormikCustomInput } from 'components/Controls';
import {
  Modal,
  ModalBody,
  ModalButton,
  ModalCloseIcon,
  ModalContainer,
  ModalFooter,
  ModalHeader,
  ModalTitle,
  ModalTitleText,
} from 'components/Modal';
import { SeeWhyModal } from './SeeWhyModal';
import { convertToPercentage } from './utils';

const ResetAllocationsButton = styled.span`
  width: fit-content;
  padding: 4px 8px;
  border-radius: 4px;
  background: var(--primaryBlue10);
  color: var(--primaryBlue);
  font-size: 12px;
  font-weight: 700;
  cursor: pointer;
`;

const SeeWhyText = styled(CentererVertical)`
  font-size: 12px;
  font-style: italic;
  color: var(--primaryBlack50);
  cursor: pointer;
`;

const ContractGroupingsTable = styled.table`
  width: 100%;
  td {
    padding: 8px;
  }
`;

const HeaderLabel = styled.span`
  font-size: 12px;
  font-weight: 700;
  color: var(--primaryBlack50);
`;

const LabelText = styled.span`
  font-size: 14px;
  font-weight: 500;
`;

const ProductName = styled.span`
  font-size: 12px;
  font-weight: 300;
  color: var(--primaryBlack70);
`;

const formatDataForForm = ({ contractData, transactions }) => {
  const allocationsFromContract = convertToPercentage(contractData.revrec_allocations); // convert to percentages

  const result = {};
  for (const transaction of transactions) {
    result[transaction.id] = allocationsFromContract[transaction.id] ?? null;
  }

  return result;
};

export const ContractGroupingsModal = ({ orgId, contractData, transactions, onClose }) => {
  const [showWhyModal, setShowWhyModal] = useState(false);

  const {
    operations: { updateContractAllocationsAndTransactionAccountingRecognitions, getDefaultContractAllocations },
  } = useContractAPI({ orgId, contractId: contractData.id, enabled: false });

  const handleFormSubmit = async ({ values }) => {
    // convert to decimals
    const decimalValues = Object.entries(values ?? {}).reduce((acc, [transactionId, allocation]) => {
      if (isNil(allocation)) {
        acc[transactionId] = null;
        return acc;
      }

      acc[transactionId] = allocation / 100;
      return acc;
    }, {});

    await updateContractAllocationsAndTransactionAccountingRecognitions({ body: { allocations: decimalValues } });
  };

  const initialValues = formatDataForForm({ contractData, transactions });

  return (
    <ModalContainer>
      <Formik
        initialValues={initialValues}
        enableReinitialize={true}
        onSubmit={(values) => handleFormSubmit({ values })}
      >
        {({ submitForm, setValues, setFieldValue, values }) => {
          const selectedTransactionIds = Object.keys(values).filter((key) => !isNil(values[key]));

          const onResetClick = async () => {
            const { defaultAllocations } = await getDefaultContractAllocations({
              transactionIds: selectedTransactionIds,
            });
            const defaultPercentages = convertToPercentage(defaultAllocations); // convert to percentages

            const result = {};
            for (const transaction of transactions) {
              result[transaction.id] = defaultPercentages[transaction.id] ?? null;
            }

            setValues(result); // set formik values to defaults
          };

          return (
            <>
              <Modal data-cy="update-contract-allocations-modal" width="700px" height="auto">
                <ModalCloseIcon onClose={onClose} data-cy="update-contract-allocations__close-button" />
                <ModalHeader>
                  <ModalTitle padding="12px 36px">
                    <ModalTitleText>Update Contract Groupings and Allocations</ModalTitleText>
                  </ModalTitle>
                </ModalHeader>
                <ModalBody paddingLeft="36px" paddingRight="36px">
                  <FlexerColumn gap="8px" marginBottom="16px" marginTop="16px">
                    <ContractGroupingsTable>
                      <thead>
                        <tr>
                          <td>
                            <HeaderLabel>Grouped on Contract</HeaderLabel>
                          </td>
                          <td>
                            <HeaderLabel>Transaction</HeaderLabel>
                          </td>
                          <td>
                            <HeaderLabel>Allocation</HeaderLabel>
                          </td>
                        </tr>
                      </thead>
                      <tbody>
                        {transactions.map((transaction) => (
                          <tr key={transaction.id}>
                            <td>
                              <Checkbox
                                checked={!isNil(values[transaction.id])}
                                onClick={(event) => {
                                  const isChecked = event.target.checked;
                                  if (isChecked) {
                                    setFieldValue(transaction.id, 0);
                                  } else {
                                    setFieldValue(transaction.id, null);
                                  }
                                }}
                              />
                            </td>
                            <td>
                              <FlexerColumn gap="4px">
                                <LabelText>{transaction.name}</LabelText>
                                <ProductName>({transaction.productName})</ProductName>
                              </FlexerColumn>
                            </td>
                            <td>
                              <FormikCustomInput
                                name={transaction.id}
                                placeholder="0"
                                suffix="%"
                                type="number"
                                precision={2}
                                data-cy={`update-contract-allocations__input-field--${transaction.id}`}
                                isDisabled={isNil(values[transaction.id])}
                              />
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </ContractGroupingsTable>
                    <Spacer height="8px" />
                    <FlexerRow gap="8px">
                      <ResetAllocationsButton onClick={onResetClick}>Set to Defaults</ResetAllocationsButton>
                      <SeeWhyText onClick={() => setShowWhyModal(true)}>
                        <span>Why are these the defaults?</span>
                        <Spacer width="8px" />
                        <QuestionIcon />
                      </SeeWhyText>
                    </FlexerRow>
                  </FlexerColumn>
                </ModalBody>
                <ModalFooter padding="12px 36px" noFixedHeight>
                  <FlexEndContainer>
                    <ModalButton default onClick={onClose}>
                      Cancel
                    </ModalButton>
                    <Spacer width="14px" />
                    <ModalButton primary onClick={submitForm} data-cy="update-contract-allocations__submit-button">
                      Save
                    </ModalButton>
                  </FlexEndContainer>
                </ModalFooter>
              </Modal>
              {showWhyModal && (
                <SeeWhyModal
                  transactions={transactions}
                  onClose={() => setShowWhyModal(false)}
                  getDefaultContractAllocations={getDefaultContractAllocations}
                  selectedTransactionIds={selectedTransactionIds}
                />
              )}
            </>
          );
        }}
      </Formik>
    </ModalContainer>
  );
};
