import styled from 'styled-components';

import { Button } from 'components/Buttons';
import { ModalFooter } from 'components/Modal';
import { Centerer, Flexer, FlexerColumn, FlexBetweenContainer } from 'components/Core';
import { ReactComponent as CheckIcon } from 'images/transaction_check.svg';

export const Wrapper = styled.div`
  padding: 0 36px;
  background-color: ${({ bgcolor }) => bgcolor ?? 'var(--primaryGray)'};
`;

export const Header = styled.div`
  font-size: 24px;
  line-height: 38px;
  margin-bottom: 20px;
  font-weight: 900;
`;

export const ActionItem = styled(FlexBetweenContainer)`
  margin-bottom: 20px;
  gap: 12px;
`;

export const FlexGrow = styled.div`
  flex-grow: 1;
`;

export const FormFooter = styled(ModalFooter)`
  padding: 20px 36px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const FormButtonsRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

export const CancelButton = styled(Centerer)`
  background: var(--primaryBlack5);
  border: 1px solid var(--primaryBlack5);
  border-radius: 100px;
  margin-right: 14px;
  padding: 11px 14px;
  cursor: pointer;
  font-weight: 700;
`;

export const SaveButton = styled.div`
  background: var(--primaryGreen);
  display: flex;
  align-items: center;
  border: 1px solid var(--primaryBlack5);
  border-radius: 100px;
  color: #ffffff;
  padding: 8px 14px;
  padding-right: 8px;
  cursor: pointer;
  pointer-events: ${(props) => props.disabled && 'none'};
  opacity: ${(props) => props.disabled && '0.3'};
  font-weight: 700;
`;

export const StyledCheckIcon = styled(CheckIcon)`
  path {
    fill: white;
  }

  rect {
    fill: transparent;
  }
`;

export const CheckIconWrapper = styled(Centerer)`
  border-radius: 50%;
  border: 1px solid white;
  width: 20px;
  height: 20px;
`;

export const SubscriptionsContainer = styled(FlexerColumn)`
  align-items: flex-start;
  padding: 20px;
  gap: 12px;
  font-size: 12px;

  width: 220px;
  max-height: 336px;
  margin-left: 20px;

  /* Dark/50 */
  background: var(--primaryBlack50);
  color: white;
  backdrop-filter: blur(20px);
  border-radius: 12px;

  animation-duration: 0.3s;
  animation-name: modalIn;
  animation-timing-function: ease;

  @keyframes modalIn {
    from {
      position: relative;
      opacity: 0.2;
      bottom: -30px;
    }

    to {
      opacity: 1;
      bottom: 0;
    }
  }
`;

export const SubscriptionsTitle = styled.span`
  font-style: italic;
  font-weight: 900;
  line-height: 16px;
`;

export const SubscriptionsTooltipContentText = styled(Flexer)`
  gap: 8px;
  color: white;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  text-align: left;

  svg {
    width: 10px;
    height: 10px;
    margin-top: 3px;
    fill: none;
    circle {
      stroke: white;
      opacity: 0.23;
    }
  }
`;

export const SubscriptionsButton = styled(Button)`
  background: var(--white8);
  color: white;
  width: 100%;
  border-radius: 8px;
  font-weight: 700;
`;

export const SubscriptionsListItem = styled(FlexerColumn)`
  b {
    width: 150px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  span {
    opacity: 0.5;
  }
`;

export const SubscriptionsAdditionalInfo = styled.span`
  opacity: 0.5;
  font-style: italic;
`;
