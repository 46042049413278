import { FlexerRow, TextBox } from 'components/Core';
import { columnGenerator } from './columnGenerator';
import { FadedText } from 'views/Billing/UsageBasedEngine/Subscriptions/styles';
import { ReactComponent as _WarningIcon } from 'images/warning-icon.svg';
import styled from 'styled-components';

const WarningIcon = styled(_WarningIcon)`
  height: 12px;
  path {
    fill: var(--primaryRed);
  }
`;

const column = columnGenerator({ entityName: 'subscriptions' });

export const generateColumns = ({ onEditClick, formatDateWithLocale }) => {
  const columns = [
    column.header(),
    column.customer({ onCustomerClick: null }),
    {
      Header: 'Pricing Plan',
      accessor: 'pricing_plan_name',
      width: 130,
      Cell: ({ row }) => (
        <FlexerRow alignItems="center" gap="0 4px">
          {row.original.missingPricingPlan && <WarningIcon />}
          <TextBox>{row.original.pricing_plan_name || <FadedText>Not specified</FadedText>}</TextBox>
        </FlexerRow>
      ),
    },
    column.startDate({ formatter: formatDateWithLocale }),
    column.endDate({ formatter: formatDateWithLocale }),
    {
      Header: 'Trial Units',
      accessor: 'trial_units',
      width: 80,
      Cell: ({ row }) => <>{row.original.trial_units}</>,
    },
    column.actions({ onEditClick: onEditClick }),
  ];

  return columns;
};
