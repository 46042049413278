import React, { useState } from 'react';
import styled from 'styled-components';
import { uniq } from 'lodash';
import { TooltipContainer } from 'components/Tooltip';
import { FlexerColumn, Centerer, FlexBetweenContainer } from 'components/Core';
import { TrashIcon } from 'components/Icons';
import { RecipientsList, RecipientEmail, AddEmailsButton, StyledAddInput } from './styles';

export const InputLabel = styled.label`
  margin-bottom: 8px;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: var(--primaryBlack40);
  cursor: pointer;
`;

export const EmailSelector = ({
  name,
  label,
  padding,
  value,
  onChange,
  fixedValues,
  isDisabled = false,
  smallVersion,
  labelAction,
  isChanged,
  toolTipWidth,
  tooltipInputDisplay,
}) => {
  const [addingNewEmailsMode, setAddingNewEmailsMode] = useState(false);
  const deDupedValue = [...new Set(value)];

  const addInputOnBlur = ({ target }) => {
    onChange(
      uniq([
        ...(deDupedValue ?? []),
        ...target?.value
          ?.split(',')
          .filter(Boolean)
          .map((e) => e.trim()),
      ]),
    );
    setAddingNewEmailsMode(false);
  };

  const onRemoveEmail = (email) => onChange(deDupedValue.filter((prevEmail) => prevEmail !== email));

  const ListComponent = (
    <RecipientsList
      isChanged={isChanged}
      data-cy={`${name}__email-list`}
      padding={padding}
      disabled={isDisabled}
      smallVersion={smallVersion}
    >
      {fixedValues
        ?.filter((e) => typeof e === 'string')
        .map((email) => (
          <RecipientEmail key={email}>{email}</RecipientEmail>
        ))}

      {deDupedValue
        ?.filter((e) => typeof e === 'string')
        .map((email) => (
          <RecipientEmail
            key={email}
            blue
            smallVersion={smallVersion}
            data-cy={`${name}__email-${email}`}
            disabled={isDisabled}
            onClick={() => !isDisabled && onRemoveEmail(email)}
          >
            {email}
            {!isDisabled && (
              <Centerer data-cy={`${name}__email-${email}__remove-button`}>
                <TrashIcon />
              </Centerer>
            )}
          </RecipientEmail>
        ))}

      {addingNewEmailsMode ? (
        <StyledAddInput
          onBlur={addInputOnBlur}
          onKeyPress={(e) => {
            e.stopPropagation();
            e.key === 'Enter' && e.target.blur();
          }}
          onKeyDown={(e) => e.stopPropagation()}
          data-cy={`${name}__add-emails--input`}
          placeholder="+ Email, comma separated"
          smallVersion={smallVersion}
        />
      ) : (
        !isDisabled && (
          <AddEmailsButton
            data-cy={`${name}__add-emails--button`}
            onClick={() => setAddingNewEmailsMode(true)}
            smallVersion={smallVersion}
          >
            + Email, comma separated
          </AddEmailsButton>
        )
      )}
    </RecipientsList>
  );
  return (
    <FlexerColumn>
      <FlexBetweenContainer>
        {label && <InputLabel htmlFor={name}>{label}</InputLabel>} {labelAction}
      </FlexBetweenContainer>

      {tooltipInputDisplay ? (
        <TooltipContainer toolTipContent={tooltipInputDisplay} width={toolTipWidth}>
          {ListComponent}
        </TooltipContainer>
      ) : (
        ListComponent
      )}
    </FlexerColumn>
  );
};
